import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Code,
  Flex,
  HStack,
  Spacer,
  Tooltip,
  useToast
} from "@chakra-ui/react";
import { Form } from "@saas-ui/forms/yup";
import {
  FormLayout,
  LoadingOverlay,
  LoadingSpinner,
  LoadingText,
  SubmitButton,
} from "@saas-ui/react";
import { useRef, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useCognito } from "../providers/CognitoContext";
import { useConnectedCloud } from "../providers/ConnectedCloudContext";
import { StartMachine, useMachines } from "../providers/MachinesContext";
import { CreateMachinePayload } from "../types/types";

const CommonGPUs = [
  "A10",
  "A10G",
  "A100",
  "A100-80GB",
  "H100",
  "K80",
  "L4",
  "M60",
  "P100",
  "T4",
  "V100",
  "V100-32GB",
];

const MachineConfigSchema = yup.object({
  name: yup.string().required("Name is required").label("Name"),
  docker_image: yup.string().label("Docker Image"),
  resources: yup.object().shape(
    {
      cloud: yup.array().of(yup.string()).label("Cloud"),
      cpus: yup.string().label("CPUs"),
      memory: yup.string().label("Memory"),
      accelerator_type: yup
        .string()
        .label("Accelerator Type")
        .when("accelerator_count", {
          is: (value: number) => value !== undefined && value !== null,
          then: (schema: any) =>
            schema.required("GPU is required when Count is specified"),
        }),
      accelerator_count: yup
        .number()
        .min(1)
        .max(8)
        .label("Accelerator Count")
        .transform((value, originalValue) =>
          originalValue === "" ? undefined : value
        )
        .when("accelerator_type", {
          is: (value: string) => value && value.length > 0,
          then: (schema) =>
            schema.required("Count is required when GPU is selected"),
        }),
      disk_size: yup.string().label("Disk Size"),
    },
    [["accelerator_type", "accelerator_count"]]
  ),
  start_notebook: yup.boolean().default(true).label("Start Jupyter Notebook"),
});

const CreateMachine: React.FC = () => {
  const formRef: React.RefObject<UseFormReturn> = useRef(null);
  const { user } = useCognito();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const toast = useToast();
  const navigate = useNavigate();
  const { fetchMachines } = useMachines();
  const { connectedClouds } = useConnectedCloud();

  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submittedSuccess, setSubmittedSuccess] = useState(false);
  const [submittedError, setSubmittedError] = useState(false);

  const onSubmit = async (params: any) => {
    setSubmitting(true);

    try {
      const payload: Partial<CreateMachinePayload> = {
        name: params.name,
        notebook: params.start_notebook,
        resources: params.resources,
        docker_image: params.docker_image,
      };

      payload.resources = {};
      if (params.resources) {
        payload.resources.any_of = [];
        if (params.resources.cloud) {
          if (params.resources.cloud.length === 1) {
            payload.resources.cloud = params.resources.cloud[0];
          } else {
            params.resources.cloud.map((cloud: string) => {
              payload.resources?.any_of?.push({
                cloud: cloud,
              });
            });
          }
        } else {
          connectedClouds.forEach((value, key) => {
            if (key !== "runpod") {
              payload.resources?.any_of?.push({
                cloud: key,
              });
            }
          });
        }
        if (params.resources.cpus) {
          payload.resources.cpus = !params.resources.cpus.endsWith("+")
            ? `${params.resources.cpus}+`
            : params.resources.cpus;
        }
        if (params.resources.memory) {
          payload.resources.memory = !params.resources.memory.endsWith("+")
            ? `${params.resources.memory}+`
            : params.resources.memory;
        }
        if (
          params.resources.accelerator_type &&
          params.resources.accelerator_count
        ) {
          payload.resources.accelerators = `${params.resources.accelerator_type}:${params.resources.accelerator_count}`;
        }
        if (params.resources.disk_size) {
          payload.resources.disk_size = params.resources.disk_size;
        }
      }

      const response = await StartMachine(user, payload);
      const machineName = response.data.name;
      setSubmittedSuccess(true);
      toast({
        title: `Machine ${machineName} launched.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error: any) {
      console.error("Error starting machine", error);
      setSubmittedError(true);
      toast({
        title: "Error starting machine.",
        description: error.response.data,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setSubmitted(true);
    setSubmitting(false);
    fetchMachines();
    navigate("/machines");
  };
  return (
    <Flex
      justifyContent="center"
      alignItems="start"
      width="100%" // Take full width of the viewport
      height="100%" // Take full height of the viewport
      overflow={"auto"}
      overflowX={"scroll"}
    >
      <Box
        borderRadius="xl"
        shadow="sm" // Optional: Adds shadow for depth
        borderWidth={"1px"}
        // mx={4} // Margin around the box for spacing
        my={10} // Margin around the box for spacing
        px={4} // Padding inside the box
        py={4} // Padding inside the box
        alignContent={"start"} // Align items vertically in the center
        justifyContent={"center"} // Align items horizontally in the center
        width={"80%"}
        height={"90%"}
        overflow={"auto"}
        textAlign={"left"}
      >
        {submitting ? (
          <>
            <LoadingOverlay>
              <LoadingSpinner />
              <LoadingText>
                We are launching your machine, just a moment...
              </LoadingText>
            </LoadingOverlay>
          </>
        ) : (
          <Form schema={MachineConfigSchema} onSubmit={onSubmit}>
            {({ Field }) => (
              <FormLayout columns={1}>
                <Field
                  name="name"
                  // isRequired
                  label="Name"
                  help="Select the cloud provider(s) where you would like to run your
              machine."
                  rules={{ required: "Name is required" }}
                />
                <Field
                  type="select"
                  name="resources.cloud"
                  label="Cloud"
                  isRequired={false}
                  options={Array.from(connectedClouds.keys()).map((key) => ({
                    value: key,
                  }))}
                  multiple
                  isLazy
                  help="If you don't select a cloud, we'll pick the best option from your connected clouds."
                  placeholder="Any connected cloud"
                />
                <HStack alignItems={"center"}>
                  <Field
                    name="resources.accelerator_type"
                    label="GPU"
                    type="select"
                    isRequired={false}
                    options={CommonGPUs.map((gpu) => ({
                      value: gpu,
                    }))}
                    isLazy
                    placeholder="No GPU"
                    help="optional"
                  />
                  <Box>
                    <Code>x</Code>
                  </Box>
                  <Field
                    name="resources.accelerator_count"
                    label="Count"
                    type="number"
                    isRequired={false}
                    placeholder="e.g. 1"
                    help="optional, max: 8"
                    rules={{ max: 8 }}
                  ></Field>
                </HStack>
                <Field
                  name="start_notebook"
                  label="Start Jupyter Notebook"
                  type="checkbox"
                  defaultChecked={true}
                />
                <Spacer />
                <Accordion allowToggle={true}>
                  <AccordionItem>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        Advanced Configuration
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      <Field
                        name="resources.cpus"
                        label="CPU"
                        type="text"
                        isRequired={false}
                        placeholder="e.g. 4+"
                      />
                      <Field
                        name="resources.memory"
                        label="Memory (GB)"
                        type="text"
                        isRequired={false}
                        placeholder="e.g. 64+"
                      />
                      <Field
                        name="resources.disk_size"
                        label="Disk Size (GB)"
                        type="text"
                        isRequired={false}
                        placeholder="e.g. 100"
                      />
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
                {connectedClouds.size === 0 ? (
                  <Tooltip
                    label="You need to connect to at least one cloud provider to launch a machine."
                    aria-label="A tooltip"
                  >
                    <SubmitButton marginTop={"15px"} isDisabled={true}>
                      Launch
                    </SubmitButton>
                  </Tooltip>
                ) : (
                  <SubmitButton marginTop={"15px"} maxW={"200px"} isDisabled={!user!.access_approved}>Launch</SubmitButton>
                )}
              </FormLayout>
            )}
          </Form>
        )}
      </Box>
    </Flex>
  );
};

export default CreateMachine;
