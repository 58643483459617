import { Badge, Skeleton } from "@chakra-ui/react";
import { ColumnDef } from "@saas-ui-pro/react";
import React, { useState, useEffect } from "react";
import { useJobs } from "../providers/JobsContext";
import {
  DisplayJob,
  Job,
  KomodoStatusColorScheme,
  KomodoStatusToString,
} from "../types/types";
import DataGridPage from "./DataGridPage";
import { StyledPageWrapper } from "./common";
import { useCognito } from "../providers/CognitoContext";
import AccountNotApprovedBanner from "./AccountNotApprovedBanner";

const displayColumns: ColumnDef<DisplayJob, any>[] = [
  {
    id: "name",
    header: "Name",
    accessorKey: "name",
  },
  {
    id: "status",
    header: "Status",
    accessorKey: "status",
  },
  { id: "created", header: "Created", accessorKey: "created" },
  { id: "cloud", header: "Cloud", accessorKey: "cloud" },
  { id: "region", header: "Region", accessorKey: "region" },
  // { id: "duration", header: "Duration", accessorKey: "duration" },
];

const Jobs: React.FC = () => {
  const { jobs, fetchJobs, fetchingJobs } = useJobs();
  const { user } = useCognito();
  const [initialLoad, setInitialLoad] = useState(true);
  // const [selections, setSelections] = React.useState<string[]>([]);

  // convert jobs to display jobs
  const displayJobs: DisplayJob[] = jobs.map((job: Job) => {
    return {
      id: job.id,
      name: job.name,
      status: (
        <Badge colorScheme={KomodoStatusColorScheme[job.status]}>
          {KomodoStatusToString[job.status]}
        </Badge>
      ),
      cloud: job.cloud ? job.cloud : "n/a",
      region: job.region ? job.region : "n/a",
      // duration: job.duration,
      created: new Date(job.createdTimestamp * 1000).toLocaleString(),
    };
  });

  useEffect(() => {
    if (!fetchingJobs && initialLoad) {
      setInitialLoad(false);
    }
  }, [fetchingJobs]);

  const tableData = React.useMemo(
    () => (fetchingJobs || initialLoad ? Array(15).fill({}) : displayJobs),
    [fetchingJobs, initialLoad, displayJobs]
  );
  const tableColumns = React.useMemo(
    () =>
      fetchingJobs || initialLoad
        ? [
            {
              id: "Loading...",
              cell: () => (
                <Skeleton height="20px" width="100%" borderRadius={"md"} />
              ),
            },
          ]
        : displayColumns,
    [fetchingJobs, initialLoad]
  );

  return (
    <StyledPageWrapper>
      <DataGridPage
        title="Jobs"
        description="Run and schedule your AI workloads efficiently."
        columns={tableColumns}
        data={tableData}
        fetching={fetchingJobs || initialLoad}
        fetchData={fetchJobs}
        emptyStateTitle="No jobs yet"
        emptyStateDescription="You haven't created any jobs yet."
        createLink="/jobs/new"
        createButtonText="Launch job"
        detailsBasePath="jobs"
      />
    </StyledPageWrapper>
  );
};

export default Jobs;
