import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  VStack,
  HStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { WarningIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Team } from "../types/types";

interface PaymentValidationFailedBannerProps {
  team: Team;
}

export default function PaymentValidationFailedBanner({ team }: PaymentValidationFailedBannerProps) {
  const bgColor = useColorModeValue("red.100", "red.900");
  const linkColor = useColorModeValue("red.600", "red.300");
  const navigate = useNavigate();

  // Effect to handle team changes
  useEffect(() => {
    // This will run whenever the team object changes
    console.log("Team object changed:", team);
  }, [team]);

  return (
    <Box bg={bgColor} p={4} borderRadius="md" boxShadow="md">
      <Flex alignItems="center">
        <Icon as={WarningIcon} w={6} h={6} color="red.400" />
        <Box ml={3}>
          <Text fontWeight="bold" fontSize="lg">
            Payment Validation Failed
          </Text>
          <HStack>
            <Text mt={1}>
              Your payment method could not be validated. Please ensure that your payment method is valid and try again.
            </Text>
          </HStack>
          <HStack mt={2}>
            <Button variant="link" onClick={() => navigate("/billing")}>
              Manage Payment Methods
            </Button>
          </HStack>
        </Box>
      </Flex>
    </Box>
  );
}
