import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  Link,
  VStack,
  HStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { WarningIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

export default function AccountNotApprovedBanner() {
  const bgColor = useColorModeValue("red.100", "red.900");
  const linkColor = useColorModeValue("red.600", "red.300");

  return (
    <Box bg={bgColor} p={4} borderRadius="md" boxShadow="md">
      <Flex alignItems="center">
        <Icon as={WarningIcon} w={6} h={6} color="red.400" />
        <Box ml={3}>
          <Text fontWeight="bold" fontSize="lg">
            Your account has been disabled.
          </Text>
          <HStack>
            <Text mt={1}>
              If you believe this is an error, please <Link href="https://discord.gg/baJGK6RKZC" color={linkColor} fontWeight="bold">join our Discord</Link> and let us know, or email us at{" "}
              <Link
                href={`mailto:hello@komodo.io`}
                color={linkColor}
                fontWeight="bold"
              >
                hello@komodo.io
              </Link>
              . Our team will review your request and get back to you as soon as possible.
            </Text>
          </HStack>
          <Text mt={2}>
            We're sorry for any inconvenience this may cause.
          </Text>
        </Box>
      </Flex>
    </Box>
  );
}
