import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text
} from "@chakra-ui/react";
import { BackButton, Page, PageHeader } from "@saas-ui-pro/react";
import { useNavigate } from "react-router-dom";
import CreateMachine from "./CreateMachine";
import CreateMachineV2 from "./CreateMachineV2";

const CreateMachineContainer: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Page overflow="scroll">
      <PageHeader
        title="Launch a new machine"
        description="Start developing in minutes."
        nav={<BackButton onClick={() => navigate("/machines")} />}
      />
      <Tabs
        variant="enclosed"
        defaultIndex={0}
        align="center"
        marginTop={"1em"}
        colorScheme="primary"
      >
        <TabList mb="1em">
          <Tab paddingX={10} minHeight={"40px"}>
            <Text fontWeight={750}>Komodo Cloud</Text>
          </Tab>
          <Tab paddingX={10} minHeight={"40px"}>
            <Text fontWeight={750}>
              Your Clouds
            </Text>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <CreateMachineV2 />
          </TabPanel>
          <TabPanel>
            <CreateMachine />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Page>
  );
};

export default CreateMachineContainer;
