import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Skeleton,
  SkeletonText,
  Spacer,
  Stack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import {
  Field,
  Form,
  FormLayout,
  Steps,
  StepsItem,
  SubmitButton,
  useModals,
} from "@saas-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { BsAmd, BsNvidia } from "react-icons/bs";
import { FiRefreshCw } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useBilling } from "../providers/BillingContext";
import { useCognito } from "../providers/CognitoContext";
import { StartMachine, useMachines } from "../providers/MachinesContext";
import { useTeam } from "../providers/TeamContext";
import { CreateMachinePayload } from "../types/types";
import { axios } from "../utils";
import { AddPaymentMethodModal } from "./Billing";

export enum GPUArchitecture {
  NVIDIA = "NVIDIA",
  AMD = "AMD",
}

export enum StorageType {
  SSD = "SSD",
  HDD = "HDD",
}

interface Region {
  name: string;
  description: string;
  available: boolean;
  cents_per_hour: number;
}

export interface Instance {
  name: string;
  regions: Region[];
  gpu_type: string;
  gpu_architecture: GPUArchitecture;
  gpu_memory_gb: number;
  num_gpus: number;
  num_cpus: number;
  ram_gb: number;
  storage_size_gb: number;
  storage_type: StorageType;
}

interface ConfigurationSectionProps {
  title: string;
  items: Instance[];
  onCardClick: (instance: Instance) => void;
  selectedInstance: Instance | null;
  ownStep: number;
  nextStep: () => void;
}

interface ConfigurationCardProps {
  instance: Instance;
  selectedRegion: Region | null;
  onClick?: (instance: Instance) => void;
  isSelected: boolean;
  ownStep: number;
  nextStep: () => void;
}

const Section: React.FC<ConfigurationSectionProps> = ({
  title,
  items,
  onCardClick,
  selectedInstance,
  ownStep,
  nextStep,
}) => {
  return (
    <Box mt={6}>
      <HStack align="center" mb={4}>
        <Heading as="h4" size="md">
          {title}
        </Heading>
        {title === "NVIDIA" ? (
          <Box as={BsNvidia} size="24px" />
        ) : title === "AMD" ? (
          <Box as={BsAmd} size="18px" />
        ) : (
          <></>
        )}
        <Divider />
      </HStack>
      {items === null || items.length === 0 ? (
        <Text>No instances are available at the moment</Text>
      ) : (
        <Grid templateColumns="repeat(auto-fill, 210px)" gap={2}>
          {items.map((item, index) => (
            <GridItem key={index}>
              <ConfigurationCard
                instance={item}
                selectedRegion={null}
                onClick={onCardClick}
                isSelected={selectedInstance?.name === item.name}
                ownStep={ownStep}
                nextStep={nextStep}
              />
            </GridItem>
          ))}
        </Grid>
      )}
    </Box>
  );
};

const ConfigurationCard: React.FC<ConfigurationCardProps> = ({
  instance,
  selectedRegion,
  onClick,
  isSelected,
  ownStep,
  nextStep,
}) => {
  const { subscription, paymentMethods } = useBilling();
  const navigate = useNavigate();
  const {
    name,
    regions,
    gpu_type,
    gpu_architecture,
    gpu_memory_gb,
    num_gpus,
    num_cpus,
    ram_gb,
    storage_size_gb,
    storage_type,
  } = instance;
  const availableRegions = regions.filter((region) => region.available);
  var cheapestRegion = null;
  var isDisabledNoAvailableRegions = availableRegions.length === 0;
  var price = "";
  cheapestRegion = regions.reduce((prev, curr) => {
    return prev.cents_per_hour < curr.cents_per_hour ? prev : curr;
  });
  price = "$" + (cheapestRegion.cents_per_hour / 100).toFixed(2) + "/hr";

  if (selectedRegion !== null) {
    price = "$" + (selectedRegion.cents_per_hour / 100).toFixed(2) + "/hr";
  }

  var displayName = "";
  var display_gpu_type = "";
  var display_network_interface = "";
  if (num_gpus && gpu_type) {
    var split_gpu_type = gpu_type.split("_");
    if (split_gpu_type.length === 2) {
      display_gpu_type = split_gpu_type[0];
    }
    if (split_gpu_type.length === 3) {
      display_gpu_type = split_gpu_type[0];
      display_network_interface = split_gpu_type[2];
    }
    displayName = `${num_gpus}x${display_gpu_type}`;
  } else {
    displayName = `${num_cpus}xCPU`;
  }

  return (
    <Button
      variant="unstyled"
      onClick={() => {
        onClick && onClick(instance);
        nextStep();
      }}
      _hover={{ transform: "scale(1.02)", transition: "transform 0.2s ease" }}
      _focus={{ boxShadow: "outline" }}
      h="full"
      minW={"210px"}
      maxW={"210px"}
      minH={"100px"}
      maxH={"170px"}
      display="block"
      isDisabled={isDisabledNoAvailableRegions}
    >
      <Box
        borderWidth="1px"
        borderRadius="md"
        overflow="hidden"
        p={3}
        minW={"210px"}
        maxW={"210px"}
        maxH={"170px"}
        minH={"100px"}
        shadow="md"
        boxShadow={isSelected ? "outline" : "none"}
      >
        <Flex justify="space-between" align="center" mb={2}>
          <Text fontWeight={500} fontSize="md" isTruncated>
            {displayName}
          </Text>
          <Text fontSize="sm" color="green.500" fontWeight={400}>
            {selectedRegion ? `${price}` : `from ${price}`}
          </Text>
        </Flex>
        {isDisabledNoAvailableRegions ? (
          <Text fontWeight={300}>Out of capacity</Text>
        ) : (
          <>
            <Text fontSize="sm" fontWeight={300}>
              {gpu_memory_gb || "0"} GB VRAM{" "}
              {display_network_interface !== ""
                ? `(${display_network_interface})`
                : ""}
            </Text>

            <Text fontSize="sm" fontWeight={200}>
              {ram_gb} GB RAM • {num_cpus} vCPU
            </Text>
          </>
        )}
      </Box>
    </Button>
  );
};

interface RegionCardProps {
  region: Region;
  isSelected: boolean;
  setSelectedRegion: (region: Region | null) => void;
  ownStep: number;
  setStep: (step: number) => void;
}

const RegionCard: React.FC<RegionCardProps> = ({
  region,
  isSelected,
  setSelectedRegion,
  ownStep,
  setStep,
}) => {
  const [internalSelected, setInternalSelected] = useState(isSelected);
  const price = "$" + (region.cents_per_hour / 100).toFixed(2) + "/hr";
  useEffect(() => {
    setInternalSelected(isSelected);
  }, [isSelected]);
  return (
    <Button
      variant="unstyled"
      onClick={() => {
        if (internalSelected) {
          setSelectedRegion(null);
          setInternalSelected(false);
          return;
        } else {
          setSelectedRegion(region);
          setStep(ownStep + 1);
        }
      }}
      _hover={{ transform: "scale(1.02)", transition: "transform 0.2s ease" }}
      _focus={{ boxShadow: "outline" }}
      h="full"
      display="block"
    >
      <Box
        borderWidth="1px"
        borderRadius="md"
        p={2}
        shadow="sm"
        maxW="185px"
        minW="185px"
        boxShadow={internalSelected ? "outline" : "none"}
      >
        <VStack align="start" spacing={1}>
          <HStack justify={"space-between"} maxW={"100%"}>
            <Text fontSize="xs" fontWeight="bold">
              {region.name}
            </Text>
            <Text fontSize="sm" color="green.500" fontWeight={400}>
              {price}
            </Text>
          </HStack>
          <Text fontSize="sm" fontWeight="medium">
            {region.description}
          </Text>
        </VStack>
      </Box>
    </Button>
  );
};

export const fetchKomodoCloudInstances = async (token: string) => {
  const response = await axios.get<Instance[]>(
    `${process.env.REACT_APP_API_CORE_URL}/api/v1/komodo-cloud/instances`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      cache: false,
    }
  );
  return response.data;
};

const CreateMachineV2: React.FC = () => {
  const { subscription, paymentMethods, fetchPaymentMethods } = useBilling();
  const { team } = useTeam();
  const { user } = useCognito();
  const toast = useToast();
  const navigate = useNavigate();
  const { machines, fetchMachines } = useMachines();

  const [komodoCloudNvidiaInstances, setKomodoCloudNvidiaInstances] = useState<
    Instance[] | null
  >(null);
  const [komodoCloudAmdInstances, setKomodoCloudAmdInstances] = useState<
    Instance[] | null
  >(null);
  const [selectedInstance, setSelectedInstance] = useState<Instance | null>(
    null
  );
  const [selectedRegion, setSelectedRegion] = useState<Region | null>(null);
  const [step, setStep] = React.useState(0);
  const [isFetchingCloudCapacity, setIsFetchingCloudCapacity] = useState(false);
  const [isDeploying, setIsDeploying] = useState(false);

  const modals = useModals();

  var OnCreditsWithoutPaymentMethod = false;
  if (subscription?.balance && paymentMethods?.data.length === 0) {
    OnCreditsWithoutPaymentMethod = true;
  }

  const back = () => {
    setStep(step - 1);
  };

  const next = () => {
    if (step + 1 <= steps.length - 1) {
      setStep(step + 1);
    }
  };

  const openPaymentModal = useCallback(
    (params: any) => {
      modals.open({
        title: `Add a payment method to continue`,
        body: (
          <AddPaymentMethodModal
            user={user!}
            team={team}
            fetchPaymentMethods={fetchPaymentMethods}
            onSuccess={async () => {
              console.log("onSuccess in CreateMachineV2");
              onSubmit(params, true); // Call onSubmit again after adding payment method, passing the original params
            }}
          />
        ),
      });
    },
    [modals, user, team, fetchPaymentMethods]
  );

  const fetchKomodoCloudCapacity = async () => {
    setKomodoCloudAmdInstances(null);
    setKomodoCloudNvidiaInstances(null);
    setIsFetchingCloudCapacity(true);
    try {
      const instances = await fetchKomodoCloudInstances(user!.komo_jwt_token);
      const nvidiaInstances = instances.filter(
        (instance: Instance) => instance.gpu_architecture === GPUArchitecture.NVIDIA
      );
      const amdInstances = instances.filter(
        (instance: Instance) => instance.gpu_architecture === GPUArchitecture.AMD
      );
      if (nvidiaInstances.length > 0) {
        setKomodoCloudNvidiaInstances(nvidiaInstances);
      }
      if (amdInstances.length > 0) {
        setKomodoCloudAmdInstances(amdInstances);
      }
    } catch (error) {
      console.error("Error fetching Komodo cloud capacity", error);
    } finally {
      setIsFetchingCloudCapacity(false); // Stop loading
    }
  };

  useEffect(() => {
    fetchKomodoCloudCapacity();
  }, []);

  const handleCardClick = (instance: Instance) => {
    setSelectedInstance(instance);
  };

  const onSubmit = async (
    params: any,
    callingAfterPaymentMethodAdded: boolean = false
  ) => {
    console.log("onSubmit in CreateMachineV2");
    console.log(params);
    console.log(selectedInstance);
    console.log(selectedRegion);

    // TODO: if selectedregion is null, find the cheapest region for the instance
    var targetRegion = selectedRegion;
    if (!targetRegion) {
      const availableRegions = selectedInstance!.regions.filter((region) => region.available);
      const sortedRegions = availableRegions.sort((a, b) => a.cents_per_hour - b.cents_per_hour);
      targetRegion = sortedRegions[0];
    }

    var insufficientCredits = false;
    if (
      subscription &&
      targetRegion &&
      subscription.balance * -1 < targetRegion.cents_per_hour
    ) {
      insufficientCredits = true;
    }
    console.log("insufficientCredits", insufficientCredits);

    const hasPaymentMethod = paymentMethods && paymentMethods.data.length > 0;
    console.log("hasPaymentMethod", hasPaymentMethod);
    if (callingAfterPaymentMethodAdded) {
      console.log("onSubmit in CreateMachineV2 after payment method added");
      setIsDeploying(true);
      let paymentMethods = await fetchPaymentMethods();
      do {
        paymentMethods = await fetchPaymentMethods();
      } while (!paymentMethods || paymentMethods.data.length === 0);
    } else {
      if (insufficientCredits && !hasPaymentMethod) {
        openPaymentModal(params);
        return;
      }
    }

    setIsDeploying(true);

    try {
      const payload: Partial<CreateMachinePayload> = {
        name: params.name,
        notebook: true,
        resources: {
          cloud: "komodo",
          instance_type: selectedInstance?.name,
          region: targetRegion?.name,
        },
      };
      if (targetRegion) {
        payload.resources!.region = targetRegion.name;
      }

      const response = await StartMachine(user, payload);
      const machineName = response.data.name;
      toast({
        title: `Machine ${machineName} launched.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error: any) {
      console.error("Error starting machine", error);
      toast({
        title: "Error starting machine.",
        description: error.response.data,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsDeploying(false);
    }
    axios.storage.remove("get-machines");
    fetchMachines();
    navigate("/machines");
  };

  const validateName = async (value: string) => {
    // Check if the name matches the required format
    const nameRegex = /^[a-zA-Z0-9]([a-zA-Z0-9._-]*[a-zA-Z0-9])?$/;
    if (!nameRegex.test(value)) {
      return "Invalid name format. Use ASCII characters, lowercase and uppercase letters, digits, underscores, periods, and dashes. Must start and end with alphanumeric characters.";
    }

    // Check for triple dashes or underscores
    if (value.includes("---") || value.includes("___")) {
      return "Name cannot contain triple dashes or underscores";
    }
    // Check if the name already exists
    await fetchMachines();
    const machine = machines.find((machine) => machine.name === value);
    if (machine) {
      return "Machine with this name already exists";
    }

    return true;
  };

  const isRegionSelected = (region: Region) =>
    selectedRegion ? selectedRegion.name === region.name : false;

  const steps = [
    {
      name: "select_instance",
      title: selectedInstance
        ? `Instance: ${selectedInstance.name}`
        : "Pick an instance",
      children: (
        <>
          <Box
            borderRadius="xl"
            shadow="sm" // Optional: Adds shadow for depth
            borderWidth={"1px"}
            my={5} // Margin around the box for spacing
            px={4} // Padding inside the box
            py={4} // Padding inside the box
            alignContent={"start"} // Align items vertically in the center
            justifyContent={"center"} // Align items horizontally in the center
            width={"80%"}
            height={"90%"}
            overflow={"auto"}
          >
            <Box p={1} textAlign={"left"}>
              <HStack alignItems={"flex-start"}>
                <Heading as="h4" size="md" fontWeight={"100"} lineHeight="1.5">
                  Pick an instance
                </Heading>
                <Spacer />
                <Button
                  leftIcon={<FiRefreshCw />}
                  onClick={fetchKomodoCloudCapacity}
                  aria-label="Refresh data"
                  font-size={"md"}
                  sx={{ "background-color": "transparent" }}
                  alignSelf="flex-start"
                  isLoading={isFetchingCloudCapacity}
                  // top="-1px"
                  lineHeight="1.5"
                  fontWeight={"100"}
                >
                  Refresh Capacity
                </Button>
              </HStack>
              {OnCreditsWithoutPaymentMethod && (
                <Text>
                  Note: Until you add a payment method, your machines will be
                  terminated if your credits drop below an hour of running
                  costs.
                </Text>
              )}
              {isFetchingCloudCapacity && (
                <Box mt={6}>
                  <SkeletonText noOfLines={1} skeletonHeight={10} mb={4} />
                  <Grid templateColumns="repeat(auto-fill, 210px)" gap={2}>
                    {Array.from({ length: 10 }).map((_, index) => (
                      <GridItem key={index}>
                        <Skeleton
                          height="100px"
                          width="210px"
                          borderRadius={"md"}
                        />
                      </GridItem>
                    ))}
                  </Grid>
                </Box>
              )}
              {komodoCloudNvidiaInstances && (
                <Section
                  title="NVIDIA"
                  items={komodoCloudNvidiaInstances!}
                  onCardClick={handleCardClick}
                  selectedInstance={selectedInstance}
                  ownStep={step}
                  nextStep={next}
                />
              )}
              {komodoCloudAmdInstances && (
                <Section
                  title="AMD"
                  items={komodoCloudAmdInstances!}
                  onCardClick={handleCardClick}
                  selectedInstance={selectedInstance}
                  ownStep={step}
                  nextStep={next}
                />
              )}
            </Box>
          </Box>
        </>
      ),
    },
    {
      name: "select_region",
      title: selectedRegion ? (
        `Region: ${selectedRegion.description}`
      ) : step === 2 ? (
        "Region: Any"
      ) : (
        <HStack alignItems={"end"} spacing={1}>
          <Text>Select Region</Text>
        </HStack>
      ),
      children: (
        <>
          <Box mt={6}>
            <Grid templateColumns="repeat(auto-fill, 185px)" gap={2}>
              {selectedInstance?.regions.map(
                (region, index) =>
                  region.available && (
                    <GridItem key={index}>
                      <RegionCard
                        region={region}
                        isSelected={isRegionSelected(region)}
                        setSelectedRegion={setSelectedRegion}
                        ownStep={step}
                        setStep={setStep}
                      />
                    </GridItem>
                  )
              )}
            </Grid>
          </Box>
          <ButtonGroup width="100%" alignItems={"start"} mt={6}>
            <Button onClick={back} variant="ghost">
              Back
            </Button>
            <Button
              onClick={next}
              colorScheme="primary"
              variant={"outline"}
              minWidth={"100px"}
            >
              Skip region selection
            </Button>
          </ButtonGroup>
        </>
      ),
    },
    {
      name: "select_name",
      title: "Start",
      children: (
        <>
          <Box
            borderRadius="xl"
            shadow="sm" // Optional: Adds shadow for depth
            borderWidth={"1px"}
            my={5} // Margin around the box for spacing
            px={4} // Padding inside the box
            py={4} // Padding inside the box
            alignContent={"start"} // Align items vertically in the center
            justifyContent={"center"} // Align items horizontally in the center
            width={"80%"}
            height={"90%"}
            overflow={"auto"}
          >
            {selectedInstance && (
              <Stack
                alignItems={"start"}
                paddingY={10}
                paddingX={10}
                spacing={50}
                direction={["column", "row"]}
              >
                <Box flex="0 0 40%">
                  <VStack>
                    <ConfigurationCard
                      instance={selectedInstance}
                      isSelected={true}
                      selectedRegion={selectedRegion}
                      ownStep={step}
                      nextStep={() => {}}
                      onClick={() => {}}
                    />
                    <Box textAlign={"left"}>
                      <Text>
                        Region:{" "}
                        {selectedRegion
                          ? `${selectedRegion.description} (${selectedRegion?.name})`
                          : "Any"}
                      </Text>
                    </Box>
                  </VStack>
                </Box>
                <Box flex="1">
                  <Form
                    onSubmit={(params) => onSubmit(params, false)}
                    maxWidth={"100%"}
                  >
                    <FormLayout>
                      <Field
                        name="name"
                        label="Name"
                        placeholder="machine-0"
                        rules={{ required: true, validate: validateName }}
                      />
                      <SubmitButton
                        maxW={"200px"}
                        isDisabled={!user!.access_approved}
                        isLoading={isDeploying}
                      >
                        Launch Machine
                      </SubmitButton>
                    </FormLayout>
                  </Form>
                </Box>
              </Stack>
            )}
          </Box>
          <ButtonGroup width="100%" alignItems={"start"} marginBottom={"10"}>
            <Button onClick={back} variant="ghost">
              Back
            </Button>
          </ButtonGroup>
        </>
      ),
    },
  ];

  return (
    <>
      <Steps
        step={step}
        mb="2"
        variant={"subtle"}
        // orientation={useBreakpointValue({ base: "vertical", md: "horizontal" })}
        orientation="vertical"
      >
        {steps.map((args, i) => (
          <StepsItem key={i} {...args} />
        ))}
      </Steps>
    </>
  );
};

export default CreateMachineV2;
