import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Badge, HStack, Text, Tooltip } from "@chakra-ui/react";
import { CpuChipIcon } from "@heroicons/react/24/outline";
import {
  NavGroup,
  NavItem,
  Sidebar,
  SidebarOverlay,
  SidebarSection,
  SidebarToggleButton,
} from "@saas-ui/react";
import "posthog-js/dist/recorder";
import React, { useEffect } from "react";
import { FiClipboard, FiUploadCloud, FiUsers } from "react-icons/fi";
import { MdConnectWithoutContact } from "react-icons/md";

import { HiOutlineDocumentReport } from "react-icons/hi";
import { RiSpeedUpLine } from "react-icons/ri";
import {
  Link as ReactRouterLink,
  NavLink as ReactRouterNavLink,
  useLocation,
} from "react-router-dom";
import { useCognito } from "../providers/CognitoContext";
import { useTeam } from "../providers/TeamContext";
import { ImMagicWand } from "react-icons/im";

const AppSidebar: React.FC = () => {
  const { user, isFetchingUserFromDb } = useCognito();
  const {
    team,
    isFetchingTeam,
    teamInvitation,
    isFetchingTeamInvitation,
    fetchTeam,
    fetchPendingInvitation,
  } = useTeam();
  const location = useLocation();
  const activeSidebarItem = location.pathname.split("/")[1];
  var isStudioActive,
    isJobsActive,
    isMachinesActive,
    isServicesActive,
    isSettingsActive,
    isTeamActive,
    isBillingActive,
    isUsageActive;
  isStudioActive =
    isJobsActive =
    isMachinesActive =
    isServicesActive =
    isSettingsActive =
    isTeamActive =
    isBillingActive =
    isUsageActive =
      false;
  switch (activeSidebarItem) {
    case "studio":
      isStudioActive = true;
      break;
    case "jobs":
      isJobsActive = true;
      break;
    case "machines":
      isMachinesActive = true;
      break;
    case "services":
      isServicesActive = true;
      break;
    case "settings":
      isSettingsActive = true;
      break;
    case "team":
      isTeamActive = true;
      break;
    case "billing":
      isBillingActive = true;
      break;
    case "usage":
      isUsageActive = true;
      break;
    default:
      break;
  }

  useEffect(() => {
    fetchTeam();
    fetchPendingInvitation();
  }, [user, isFetchingUserFromDb]);
  return (
    <Sidebar width="18%" toggleBreakpoint="md">
      <SidebarToggleButton />
      <SidebarSection aria-label="Main">
        <ReactRouterNavLink to="/studio">
          <NavItem icon={<ImMagicWand />} size="lg" isActive={isStudioActive}>
            Studio
          </NavItem>
        </ReactRouterNavLink>
        <ReactRouterLink to="/machines">
          <NavItem icon={<CpuChipIcon />} size="lg" isActive={isMachinesActive}>
            Machines
          </NavItem>
        </ReactRouterLink>
        <ReactRouterNavLink to="/jobs">
          <NavItem
            icon={<HiOutlineDocumentReport />}
            size="lg"
            isActive={isJobsActive}
          >
            Jobs
          </NavItem>
        </ReactRouterNavLink>

        <ReactRouterLink to="/services">
          <NavItem
            icon={<FiUploadCloud />}
            size="lg"
            isActive={isServicesActive}
          >
            Services
          </NavItem>
        </ReactRouterLink>
        {/* <ReactRouterLink to="/settings">
                <NavItem
                  icon={<FiSettings />}
                  size="lg"
                  isActive={isSettingsActive}
                >
                  Settings
                </NavItem>
              </ReactRouterLink> */}
        <ReactRouterLink to="/team">
          <NavItem icon={<FiUsers />} size="lg" isActive={isTeamActive}>
            <HStack>
              <Text>Team</Text>
              {teamInvitation && (
                <Tooltip
                  label="You have pending team invitation"
                  aria-label="A tooltip"
                >
                  <Badge colorScheme="purple">1</Badge>
                </Tooltip>
              )}
            </HStack>
          </NavItem>
        </ReactRouterLink>
        {/* <ReactRouterLink to="/usage">
          <NavItem icon={<RiSpeedUpLine />} size="lg" isActive={isUsageActive}>
            <HStack>
              <Text>Usage</Text>
              <Badge colorScheme="primary">Experimental</Badge>
            </HStack>
          </NavItem>
        </ReactRouterLink> */}
        {/* <ReactRouterLink to="/billing">
                <NavItem
                  icon={<IoReceiptOutline />}
                  size="lg"
                  isActive={isBillingActive}
                >
                  Billing
                </NavItem>
              </ReactRouterLink> */}
      </SidebarSection>
      <SidebarSection>
        <NavGroup title="Support" isCollapsible={false}>
          <NavItem
            icon={<FiClipboard />}
            href="https://docs.komodo.io"
            target="_blank"
            size={"lg"}
          >
            Docs
            <ExternalLinkIcon mx="4px" />
          </NavItem>
          {/* <NavItem
            icon={<MdConnectWithoutContact />}
            href="https://docs.komodo.io"
            target="_blank"
            size={"lg"}
          >
            Contact Us
          </NavItem> */}
        </NavGroup>
      </SidebarSection>
      <SidebarOverlay zIndex="0" />
    </Sidebar>
  );
};

export default AppSidebar;
