import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useCognito, User } from "./CognitoContext";
import { PlanType, Team, TeamInvitation, PaymentValidationStatus } from "../types/types";
import { axios } from "../utils";

type TeamContextType = {
  team: Team;
  teamInvitation: TeamInvitation | null;
  fetchTeam: () => Promise<void>;
  fetchPendingInvitation: () => Promise<void>;
  isFetchingTeam: boolean;
  isFetchingTeamInvitation: boolean;
};

const TeamContext = createContext<TeamContextType | undefined>(undefined);

export const useTeam = () => {
  const context = useContext(TeamContext);
  if (context === undefined) {
    throw new Error("useTeam must be used within a TeamProvider");
  }
  return context;
};

export const TeamProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [team, setTeam] = React.useState<Team>({
    team_id: "",
    team_name: "",
    members: [],
    stripe_customer_id: "",
    stripe_subscription_id: "",
    payment_validation_status: PaymentValidationStatus.PENDING_VALIDATION,
  });
  const [isFetchingTeam, setIsFetchingTeam] = React.useState<boolean>(true);
  const [teamInvitation, setTeamInvitation] =
    React.useState<TeamInvitation | null>(null);
  const [isFetchingTeamInvitation, setIsFetchingTeamInvitation] =
    React.useState<boolean>(true);
  const { cognitoLoading, user, isFetchingUserFromDb } = useCognito();

  const fetchTeam = async () => {
    if (!user) {
      return;
    }
    setIsFetchingTeam(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_CORE_URL}/api/v1/team`,
        {
          headers: {
            Authorization: `Bearer ${user?.komo_jwt_token}`,
          },
          id: "get-team",
        }
      );
      setTeam({
        team_id: response.data.team_id,
        team_name: response.data.team_name,
        members: response.data.members,
        stripe_customer_id: response.data.stripe_customer_id,
        stripe_subscription_id: response.data.stripe_subscription_id,
        payment_validation_status: response.data.payment_validation_status,
      });
    } catch (error: any) {
      console.error("Error fetching team members", error);
    } finally {
      setIsFetchingTeam(false);
    }
  };

  const fetchPendingInvitation = async () => {
    if (!user) {
      return;
    }
    setIsFetchingTeamInvitation(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_CORE_URL}/api/v1/team/invitations`,
        {
          headers: {
            Authorization: `Bearer ${user.komo_jwt_token}`,
          },
          id: "get-team-invitations",
        }
      );
      setTeamInvitation(response.data);
    } catch (error: any) {
      console.error("Error fetching team invitations", error);
      setTeamInvitation(null);
    } finally {
      setIsFetchingTeamInvitation(false);
    }
  };

  useEffect(() => {
    if (!cognitoLoading && !isFetchingUserFromDb) {
      fetchTeam();
      fetchPendingInvitation();
    }
  }, [cognitoLoading, isFetchingUserFromDb]);

  return (
    <TeamContext.Provider
      value={{
        team,
        teamInvitation,
        fetchTeam,
        fetchPendingInvitation,
        isFetchingTeam,
        isFetchingTeamInvitation,
      }}
    >
      {children}
    </TeamContext.Provider>
  );
};

export const InviteUserToTeam = async (
  user: User,
  email: string,
  role: string
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_CORE_URL}/api/v1/team/${
        user!.team_id
      }/invite`,
      {
        email: email,
        role: role,
        invited_by_given_name: user!.given_name,
      },
      {
        headers: {
          Authorization: `Bearer ${user!.komo_jwt_token}`,
        },
        cache: {
          update: {
            "get-team": "delete",
          }
        },
      }
    );
    return response;
  } catch (error) {
    console.log(`Error inviting ${email} to team`, error);
    throw error;
  }
};

export const EditTeamName = async (user: User, teamName: string) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_API_CORE_URL}/api/v1/team/${
        user!.team_id
      }/${teamName}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${user!.komo_jwt_token}`,
        },
        cache: {
          update: {
            "get-team": "delete",
          }
        },
      }
    );
    return response;
  } catch (error) {
    console.log(`Error editing team name`, error);
    throw error;
  }
};

export const AcceptTeamInvite = async (user: User, invitationId: string) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_CORE_URL}/api/v1/team/invitation/${invitationId}/accept`,
      {
        email: user!.email,
      },
      {
        headers: {
          Authorization: `Bearer ${user!.komo_jwt_token}`,
        },
        cache: {
          update: {
            "get-team": "delete",
            "get-team-invitations": "delete",
          }
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error accepting team invite", error);
    throw error;
  }
};

export const DeclineTeamInvite = async (user: User, invitationId: string) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_CORE_URL}/api/v1/team/invitation/${invitationId}/decline`,
      null,
      {
        headers: {
          Authorization: `Bearer ${user!.komo_jwt_token}`,
        },
        cache: {
          update: {
            "get-team-invitations": "delete",
          }
        },
      }
    );
    return response;
  } catch (error: any) {
    console.error("Error declining team invite", error);
  }
};

export const RemoveTeamMember = async (
  user: User,
  memberId: string,
  memberEmail: string
) => {
  try {
    const params = {
      user_to_remove_id: memberId,
      user_to_remove_email: memberEmail,
    };
    const response = await axios.patch(
      `${process.env.REACT_APP_API_CORE_URL}/api/v1/team/${user?.team_id}/members`,
      params,
      {
        headers: {
          Authorization: `Bearer ${user!.komo_jwt_token}`,
        },
        cache: {
          update: {
            "get-team": "delete",
          }
        },
      }
    );
    return response;
  } catch (error: any) {
    console.error("Error removing team member", error);
    throw error;
  }
};
