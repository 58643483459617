import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import React from "react";
import { v4 as uuidv4 } from "uuid";

const CognitoContext = React.createContext<CognitoContextInterface | undefined>(
  undefined
);

interface CognitoState {
  cognitoLoading: boolean;
  signedIn: boolean;
}

const defaultState = {
  signedIn: false,
  cognitoLoading: true,
};

export interface User {
  id: string;
  access_token: string;
  email: string;
  picture?: string;
  given_name?: string;
  family_name?: string;
  komo_jwt_token: string;
  komo_api_key?: string;
  komo_user_id?: string;
  access_approved?: boolean;
  team_id: string;
  role: string;
}

interface CognitoContextInterface {
  cognitoLoading: boolean;
  fetchAuthenticatedUser: () => void;
  signedIn: boolean;
  setCognitoState: (state: CognitoState) => void;
  // signInApple: () => Promise<CognitoUser>;
  signInGoogle: () => Promise<CognitoUser>;
  signOut: () => Promise<any>;
  user: User | null;
  isFetchingUserFromDb: boolean;
  signingUp: boolean;
  setSigningUp: (value: boolean) => void;
  signUpCognitoEmailPassword: (
    email: string,
    password: string,
    given_name: string,
    family_name: string
  ) => Promise<CognitoUser>;
  signInCognitoEmailPassword: (
    email: string,
    password: string
  ) => Promise<CognitoUser | null>;
  verifyOtp: (id: string, code: string) => Promise<boolean>;
}

const defaultContext: CognitoContextInterface = {
  cognitoLoading: true,
  fetchAuthenticatedUser: () => new Promise(() => {}),
  signedIn: false,
  user: null,
  setCognitoState: () => defaultState,
  signInGoogle: () => new Promise(() => {}),
  signOut: () => new Promise(() => {}),
  isFetchingUserFromDb: true,
  signingUp: false,
  setSigningUp: (value: boolean) => {},
  signUpCognitoEmailPassword: (
    email: string,
    password: string,
    given_name: string,
    family_name: string
  ) => new Promise(() => {}),
  signInCognitoEmailPassword: (email: string, password: string) =>
    new Promise(() => null),
  verifyOtp: (id: string, code: string) => new Promise(() => false),
};

export function useCognito() {
  const context = React.useContext(CognitoContext);

  if (context === undefined) {
    throw new Error("useCognito must be used within a CognitoProvider.");
  }

  return context;
}

export async function getToken() {
  try {
    if (Auth) {
      const session = await Auth.currentSession();

      return session?.getAccessToken()?.getJwtToken();
    }

    return false;
  } catch (error: any) {
    return error.message;
  }
}

const isJwtTokenExpired = (JwtToken: string | null) => {
  if (JwtToken === null) {
    return true;
  }
  var decodedJwtToken = jwtDecode(JwtToken);
  const now = new Date().getTime();
  if (decodedJwtToken.exp! * 1000 < now) {
    return true;
  }
  return false;
};

export function CognitoProvider(props: any) {
  const [state, setState] = React.useState<CognitoState>(defaultState);
  const [user, setUser] = React.useState<User | null>(null);
  const [isFetchingUserFromDb, setIsFetchingUserFromDb] =
    React.useState<boolean>(true);
  const [signingUp, setSigningUp] = React.useState<boolean>(false);

  const hostname = process.env.REACT_APP_APP_URL?.replace(
    /https?:\/\//,
    ""
  ).replace(/:\d+/, "");

  const cognito = React.useMemo(() => {
    Auth.configure({
      Auth: {
        region: process.env.REACT_APP_AWS_REGION,
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
        oauth: {
          domain: process.env.REACT_APP_COGNITO_DOMAIN,
          redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN,
          redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_OUT,
          scope: ["email", "openid", "profile"],
          clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
          responseType: "code",
        },
      },
      Analytics: {
        disabled: true,
      },
      cookieStorage: {
        domain:
          document.location.hostname === hostname ? hostname : ".komodo.io",
        secure: document.location.hostname !== hostname,
        path: "/",
        expires: 365,
      },
    });

    return Auth;
  }, [hostname]);

  const getOrCreateKomoUser = async (
    idTokenJwtToken: string,
    password: string | null
  ): Promise<any> => {
    try {
      var requestUrl = `${process.env.REACT_APP_API_CORE_URL}/api/v1/auth/jwt/get-or-create-user/${idTokenJwtToken}`;
      if (password) {
        requestUrl += `?password=${password}`;
      }
      const getOrCreateUserResponse = await axios.post(
        `${process.env.REACT_APP_API_CORE_URL}/api/v1/auth/jwt/get-or-create-user/${idTokenJwtToken}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const userFromAPI = getOrCreateUserResponse.data.user;
      sessionStorage.setItem("komo_user_api_key", userFromAPI.api_key);
      return userFromAPI;
    } catch (error) {
      setState({ cognitoLoading: false, signedIn: false });
      console.log("Error fetching user from API", error);
      return null;
    }
  };

  const getKomoUserId = async (
    komoUserApiKey: string
  ): Promise<string | null> => {
    try {
      const getKomoUserIdResponse = await axios.get(
        `${process.env.REACT_APP_API_CORE_URL}/api/v1/user-id`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${komoUserApiKey}`,
          },
        }
      );
      return getKomoUserIdResponse.data["user-id"];
    } catch (error) {
      setState({ cognitoLoading: false, signedIn: false });
      console.log("Error fetching user from API", error);
      return null;
    }
  };

  const getKomoJwtToken = async (
    komoUserApiKey: string
  ): Promise<string | null> => {
    try {
      try {
        const jwtLoginResponse = await axios.post(
          `${process.env.REACT_APP_API_CORE_URL}/api/v1/auth/jwt/api_key/login?api_key=${komoUserApiKey}`
        );
        const loginData = jwtLoginResponse.data;
        sessionStorage.setItem("komo_jwt_token", loginData.token);

        return loginData.token;
      } catch (error) {
        setState({ cognitoLoading: false, signedIn: false });
        console.log("Error logging in user for JWT token", error);
        return null;
      }
    } catch (error) {
      setState({ cognitoLoading: false, signedIn: false });
      console.log("Error fetching user from API", error);
      return null;
    }
  };

  const fetchAuthenticatedUser = React.useCallback(async () => {
    setIsFetchingUserFromDb(true);
    try {
      const user = await cognito.currentAuthenticatedUser();
      const idTokenPayload = user.signInUserSession.idToken.payload;
      const komoUser = await getOrCreateKomoUser(
        user.signInUserSession.idToken.jwtToken,
        null
      );

      if (komoUser === null) {
        setState({ cognitoLoading: false, signedIn: false });
        return;
      }

      var komoUserApiKey = komoUser.api_key;
      var komoJwtToken = sessionStorage.getItem("komo_jwt_token");
      var KomoUserId = localStorage.getItem("komo_user_id");

      if (komoJwtToken === null || isJwtTokenExpired(komoJwtToken)) {
        komoJwtToken = await getKomoJwtToken(komoUserApiKey!);
        if (komoJwtToken === null) {
          setState({ cognitoLoading: false, signedIn: false });
          console.log("Error fetching user jwt token from API");
          return;
        }
      }

      if (KomoUserId === null) {
        KomoUserId = await getKomoUserId(komoJwtToken!);
        if (KomoUserId === null) {
          setState({ cognitoLoading: false, signedIn: false });
          console.log("Error fetching user id from API");
          return;
        }
        localStorage.setItem("komo_user_id", KomoUserId!);
      }

      setUser({
        id: idTokenPayload.sub,
        access_token: user.signInUserSession.accessToken.jwtToken,
        email: idTokenPayload.email,
        picture: idTokenPayload?.picture,
        given_name: idTokenPayload?.given_name,
        family_name: idTokenPayload?.family_name,
        komo_jwt_token: komoJwtToken!,
        komo_api_key: komoUserApiKey!,
        komo_user_id: KomoUserId!,
        access_approved: komoUser.access_approved,
        team_id: komoUser.team_id,
        role: komoUser.role,
      });

      setState({
        cognitoLoading: false,
        signedIn: true,
      });
    } catch (error) {
      setState({ cognitoLoading: false, signedIn: false });
    } finally {
      // We need this code because, when user is registering with google/apple or
      // the user is created in Cognito but we don't have that user in the database.
      // (this happens because the DEV and LOCAL environments are using the same user pool).
      // Basically when user was not found in database and we need to
      // redirect them to UserName component on "/on-boarding/name" route.
      // Because we need to handle this cases, "/on-boarding/name" always appears after login.
      // because the real loading state is not enough. I added 1 second to the real loading time.
      // With this, users will not be interrupted by this unnecessary screen on every login.
      // If users need to see this screen, they will see it after loading time + 1 second.
      setTimeout(() => {
        setIsFetchingUserFromDb(false);
      }, 1);
    }
  }, [cognito]);

  React.useEffect(() => {
    fetchAuthenticatedUser();
  }, [fetchAuthenticatedUser]);

  const signInGoogle = () =>
    cognito.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    });

  const signUpCognitoEmailPassword = async (
    email: string,
    password: string,
    given_name: string,
    family_name: string
  ) => {
    try {
      const username = uuidv4().toString();
      // TODO: if user exists in Cognito, we should not create a new user
      // and we should just return the user.
      // if not verified, we should resend the verification code.
      const user = await cognito.signUp({
        username: username,
        password: password,
        attributes: {
          email: email,
          given_name: given_name,
          family_name: family_name,
        },
      });

      setUser({
        id: username,
        access_token: "",
        email: email,
        picture: "",
        given_name: given_name,
        family_name: family_name,
        komo_jwt_token: "",
        komo_api_key: "", // komoUser.api_key,
        team_id: "",
        role: "",
      });
      setState({ cognitoLoading: false, signedIn: false });
      localStorage.setItem("just_signed_up_user_email", email);
      localStorage.setItem("just_signed_up_user_password", btoa(password));
      return user;
    } catch (error) {
      console.error("Error signing up user", error);
      return null;
    }
  };

  const signInCognitoEmailPassword = async (
    email: string,
    password: string
  ): Promise<CognitoUser | null> => {
    try {
      const user = await Auth.signIn(email, password);
      const idTokenPayload = user.signInUserSession.idToken.payload;
      const komoUser = await getOrCreateKomoUser(
        user.signInUserSession.idToken.jwtToken,
        null
      );

      if (komoUser === null) {
        setState({ cognitoLoading: false, signedIn: false });
        return null;
      }

      var komoUserApiKey = komoUser.api_key;
      var komoJwtToken = sessionStorage.getItem("komo_jwt_token");
      var KomoUserId = localStorage.getItem("komo_user_id");

      if (komoJwtToken === null || isJwtTokenExpired(komoJwtToken)) {
        komoJwtToken = await getKomoJwtToken(komoUserApiKey!);
        if (komoJwtToken === null) {
          setState({ cognitoLoading: false, signedIn: false });
          console.log("Error fetching user jwt token from API");
          return null;
        }
      }

      if (KomoUserId === null) {
        KomoUserId = await getKomoUserId(komoJwtToken!);
        if (KomoUserId === null) {
          setState({ cognitoLoading: false, signedIn: false });
          console.log("Error fetching user id from API");
          return null;
        }
        localStorage.setItem("komo_user_id", KomoUserId!);
      }

      setUser({
        id: idTokenPayload.sub,
        access_token: user.signInUserSession.accessToken.jwtToken,
        email: idTokenPayload.email,
        picture: idTokenPayload?.picture,
        given_name: idTokenPayload?.given_name,
        family_name: idTokenPayload?.family_name,
        komo_jwt_token: komoJwtToken!,
        komo_api_key: komoUserApiKey!,
        komo_user_id: KomoUserId!,
        access_approved: komoUser.access_approved,
        team_id: komoUser.team_id,
        role: komoUser.role,
      });
      setState({
        cognitoLoading: false,
        signedIn: true,
      });
      return user;
    } catch (error) {
      console.error("Error signing in user", error);
      return null;
    }
  };

  const verifyOtp = async (id: string, code: string): Promise<boolean> => {
    try {
      await Auth.confirmSignUp(id, code);
      setState({ cognitoLoading: false, signedIn: false });
      return true;
    } catch (error) {
      console.log("error confirming sign up", error);
      return false;
    }
  };

  const signOut = async (): Promise<any> => {
    localStorage.clear();
    sessionStorage.clear();
    await cognito.signOut();
    setUser(null);
    setState({ cognitoLoading: false, signedIn: false });
  };

  return (
    <CognitoContext.Provider
      value={{
        fetchAuthenticatedUser,
        setCognitoState: setState,
        setSigningUp: setSigningUp,
        signInGoogle,
        signOut,
        user,
        isFetchingUserFromDb,
        signingUp,
        signUpCognitoEmailPassword,
        signInCognitoEmailPassword,
        verifyOtp,
        ...state,
      }}
      {...props}
    />
  );
}
