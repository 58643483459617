import axios from "axios";
import { User } from "./CognitoContext";



export interface UsageItem {
  name: string;
  num_nodes: number;
  duration: number;
  instance_type: string;
  cloud: string;
  cost: number;
}

export interface GetUsageResponse {
  data: UsageItem[];
}

export const ConnectAws = async (iamRoleArn: string, user: any) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_CORE_URL}/api/v1/aws/connect?iam_role_arn=${iamRoleArn}`,
      null,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.komo_jwt_token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.log("Error connecting to AWS", error);
  }
};

export const ConnectLambda = async (apiKey: string, user: any) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_CORE_URL}/api/v1/lambda_labs/connect?api_key=${apiKey}`,
      null,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.komo_jwt_token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.log("Error connecting to Lambda", error);
    throw error;
  }
};

export const ConnectRunPod = async (apiKey: string, user: any) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_CORE_URL}/api/v1/runpod/connect?api_key=${apiKey}`,
      null,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.komo_jwt_token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.log("Error connecting to RunPod", error);
    throw error;
  }
};

export const ConnectGcp = async (
  user: User,
  sa_credentials_json_file: File
) => {
  const formData = new FormData();
  formData.append("sa_credentials_json_file", sa_credentials_json_file);

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_CORE_URL}/api/v1/gcp/connect`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.komo_jwt_token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.log("Error connecting to GCP", error);
    throw error;
  }
};

export const ConnectAzure = async (
  user: User,
  sp_credentials_json_file: File
) => {
  const formData = new FormData();
  formData.append("sp_credentials_json_file", sp_credentials_json_file);

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_CORE_URL}/api/v1/azure/connect`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.komo_jwt_token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.log("Error connecting to Azure", error);
    throw error;
  }
};

export const ConnectKubernetes = async (
  user: any,
  cluster_host: string,
  komodo_sa_token: string,
  cluster_ca_data: string
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_CORE_URL}/api/v1/kubernetes/connect?host=${cluster_host}&token=${komodo_sa_token}&certificate_authority_data=${cluster_ca_data}`,
      null,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.komo_jwt_token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.log("Error connecting to Kubernetes", error);
    throw error;
  }
};
