import { Box, Code, VStack, HStack, Text, Tooltip } from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import React from "react";
import { Job, Machine, Port, SSHInfo } from "../types/types";
import YAML from 'yaml';

interface StyledPageWrapperProps extends React.ComponentPropsWithRef<"div"> {
  children: React.ReactNode;
}

const StyledPageWrapper = React.forwardRef<
  HTMLDivElement,
  StyledPageWrapperProps
>(({ children, ...rest }, ref) => {
  return (
    <Box
      id="page-wrapper"
      borderRadius="xl" // Rounded corners
      shadow="sm" // Optional: Adds shadow for depth
      borderWidth={"1px"}
      m={4} // Margin around the box for spacing
      p={4} // Padding inside the box
      flex="1"
      alignItems="center" // Align items vertically in the center
      justifyContent="center" // Align items horizontally in the center
      overflowX="scroll"
      ref={ref}
      {...rest}
    >
      {children}
    </Box>
  );
});

interface ScriptModalBodyProps {
  script: string;
}

const ScriptModalBody: React.FC<ScriptModalBodyProps> = ({ script }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      overflowX="auto"
      overflowY="auto"
      p={4}
      width="100%"
      minWidth={"150px"}
    >
      <Box display="inline-block" maxWidth="100%" overflowX="auto">
        <Code whiteSpace="pre" display="inline-block">
          {script ? script : "No script provided"}
        </Code>
      </Box>
    </Box>
  );
};

const ScriptModalContentProps = {
  width: "auto", // allows the modal width to adjust based on content
  maxWidth: ["100vw", "60vw", "60vw"], // responsive max width
};

export { StyledPageWrapper, ScriptModalBody, ScriptModalContentProps };

const RequestedResources: React.FC<{
  requestedResources: Record<string, any>;
}> = ({ requestedResources }) => {
  const renderValue = (value: any, ignoreObjectValues: boolean) => {
    if (Array.isArray(value)) {
      if (value.length === 0) {
        return <Code>{"n/a"}</Code>;
      } else if (typeof value[0] === "object" && value[0] !== null) {
        return <Code minW={"100%"} whiteSpace={"preserve"}>{YAML.stringify(value)}</Code>
      } else {
        return <Code>{value.join(",")}</Code>;
      }
    } else if (typeof value === "object" && value !== null) {
      if (ignoreObjectValues) {
        return <Code>{JSON.stringify(Object.keys(value), null, 2)}</Code>;
      } else {
        return <Code>{JSON.stringify(value, null, 2)}</Code>;
      }
    } else {
      return <Code>{value.toString()}</Code>;
    }
  };
  return (
    <VStack align="start" width={"full"}>
      {Object.entries(requestedResources).map(([key, value]) => (
        <HStack key={key}>
          <Text minWidth={"82px"}>{key}:</Text>
          {renderValue(value, key === "accelerators")}
        </HStack>
      ))}
    </VStack>
  );
};

export { RequestedResources };

const isList = (obj: any): obj is any[] => {
  return Array.isArray(obj);
};

const Ports: React.FC<{ ports: Port[] | null; sshInfoPublicIp: string, indentPorts: boolean }> = ({
  ports,
  sshInfoPublicIp,
  indentPorts = false
}) => {
  if (ports === null) {
    ports = [];
  }

  const tooltipLabel =
    "The requested ports were mapped to available public IP ports as follows: <requested port>:<public port>.";
  var portsAreMapped = ports.some(
    (port) => port.port !== parseInt(port.endpoint.split(":")[1])
  );

  const portEndpoints = ports.map((port) => {
    const endpointSplit = port.endpoint.split(":");
    const endpointAddress = endpointSplit[0];
    const endpointPort = endpointSplit[1];

    if (port.port === parseInt(endpointPort)) {
      return port.port;
    } else {
      if (endpointAddress !== sshInfoPublicIp) {
        return `${port.port}:${endpointAddress}:${endpointPort}`;
      } else {
        return `${port.port}:${endpointPort}`;
      }
    }
  });

  return (
    <HStack justify="start" w="100%">
      <Text minWidth={indentPorts ? "65px" : "40px"}>Ports:</Text>
      <VStack align="start" width={"full"}>
        <Code>{portEndpoints.length === 0 ? "n/a" : portEndpoints.join(",")}</Code>
      </VStack>
      {portsAreMapped && (
        <Tooltip label={tooltipLabel} aria-label="A tooltip">
          <InfoOutlineIcon color="gray.500" />
        </Tooltip>
      )}
    </HStack>
  );
};

const HostInfo: React.FC<{ sshInfo: SSHInfo[] | SSHInfo | null }> = ({ sshInfo }) => {
  if (sshInfo === null) {
    return (
      <HStack justify="start" w="100%">
        <Text minW="65px">Public IP:</Text>{" "}
        <Code>{"n/a"}</Code>
      </HStack>
    );
  }
  if (isList(sshInfo)) {
    if (sshInfo.length === 0) {
      return (
        <HStack justify="start" w="100%">
          <Text minW="65px">Public IP:</Text>{" "}
          <Code>{"n/a"}</Code>
        </HStack>
      );
    }
    if (sshInfo.length === 1) {
      return (
        <HStack justify="start" w="100%">
          <Text minW="65px">Public IP:</Text>{" "}
          <Code>{sshInfo[0].ip_address}</Code>
        </HStack>
      );
    }
    return (
      <VStack align="start" spacing={1}>
        <Text minW="65px">Public IP(s):</Text>{" "}
        {sshInfo.map((sshInfoItem, index) => (
          <HStack key={index} justify="start" w="100%" marginLeft={"20px"}>
            <Box minW={sshInfo.length > 1 ? "70px" : "30px"}>
              <Code>{sshInfoItem.role}</Code>
            </Box>
            <Box minW={"120px"}>
              <Code>{sshInfoItem.ip_address}</Code>
            </Box>
          </HStack>
        ))}
      </VStack>
    )
  } else {
    return (
      <HStack justify="start" w="100%">
        <Text minW="65px">Public IP:</Text>{" "}
        <Code>{sshInfo.ip_address}</Code>
      </HStack>
    )
  }
}

const Networking: React.FC<{ komodoResource: Machine | Job }> = ({
  komodoResource
}) => {
  var headNodePublicIp = "";
  var indentPorts = true;
  if (isList(komodoResource.sshInfo)) {
    komodoResource.sshInfo.forEach((sshInfo) => {
      if (sshInfo.role === "head") {
        headNodePublicIp = sshInfo.ip_address;
      }
    });
    if (komodoResource.sshInfo.length > 1) {
      indentPorts = false;
    }
  } else {
    if (komodoResource.sshInfo) {
      headNodePublicIp = komodoResource.sshInfo.ip_address;
    }
  }
  return (
    <Box>
      <Text fontWeight={"bold"}>Networking</Text>
      <VStack align="start" spacing={1}>
        <HostInfo sshInfo={komodoResource.sshInfo} />
        <Ports
          ports={komodoResource.ports}
          sshInfoPublicIp={headNodePublicIp}
          indentPorts={indentPorts}
        />
      </VStack>
    </Box>
  );
};

export { Networking };
