import {
  Box,
  Button,
  Divider,
  HStack,
  Heading,
  Icon,
  Link,
  Skeleton,
  SkeletonText,
  StackDivider,
  Text,
  VStack,
  Badge,
  IconButton,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";

import { Page, PageBody, PageHeader } from "@saas-ui-pro/react";
import {
  LoadingOverlay,
  LoadingSpinner,
  LoadingText,
  useModals,
} from "@saas-ui/react";
import {
  Elements,
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState, useRef } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import { FaCreditCard, FaTrash } from "react-icons/fa";
import { PiChartBarFill } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import {
  CancelSubscription,
  GetPaymentMethodsResponse,
  GetSubscriptionResponse,
  UpdateSubscription,
  useBilling,
} from "../providers/BillingContext";
import { User, useCognito } from "../providers/CognitoContext";
import { useTeam } from "../providers/TeamContext";
import { Team } from "../types/types";
import { axios } from "../utils";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK!);

interface PlanCardProps {
  title: string;
  description: string;
  cta: string;
  currentPlan: string;
  subscription?: GetSubscriptionResponse | null;
  isFetchingSubscription?: boolean;
  onUpgrade: () => void;
  onContact: () => void;
}

const Plans: {
  [key: string]: { title: string; description: string; cta: string };
} = {
  free: {
    title: "Free Plan",
    description: "Your plan includes a fixed amount of free usage each month.",
    cta: "To unlock additional usage and add team members, upgrade your plan to Pro.",
  },
  pro: {
    title: "Pro Plan",
    description:
      "You're getting more usage, additional features, priority support, and team members in your account.",
    cta: "",
  },
  poc: {
    title: "Proof of Concept",
    description:
      "You're currently on a proof of concept plan. Enjoy the Pro features and let's chat soon!",
    cta: "",
  },
};

const PlanCard: React.FC<PlanCardProps> = ({
  title,
  description,
  cta,
  onUpgrade,
  onContact,
  currentPlan,
  subscription,
  isFetchingSubscription,
}) => {
  const { fetchSubscription } = useBilling();
  const { team } = useTeam();
  const modals = useModals();
  const navigate = useNavigate();

  if (isFetchingSubscription) {
    return (
      <Box
        borderWidth="1px"
        borderRadius="lg"
        p={6}
        shadow="md"
        maxW="95%"
        mx="auto"
        width={"100%"}
      >
        <VStack align="flex-start" spacing={4}>
          <SkeletonText noOfLines={1} width="100%" skeletonHeight={10} />
          <Skeleton height="200px" width="100%" />
        </VStack>
      </Box>
    );
  }

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      p={6}
      shadow="md"
      maxW="95%"
      mx="auto"
      width={"100%"}
    >
      <HStack justify="space-between" mb={4} width={"100%"}>
        <Heading fontSize={"2xl"}>{title}</Heading>
        {subscription && (
          <VStack align="flex-start" spacing={0}>
            <HStack>
              <Text fontSize={"md"} color="green.500" as="b">
                {subscription.subscription_status.toUpperCase()}
              </Text>
              {subscription &&
                subscription.plan &&
                subscription.plan.toLowerCase() === "pro" && (
                  <Button
                    colorScheme="black"
                    variant="outline"
                    fontSize={"md"}
                    onClick={() => {
                      modals.open({
                        title: "Manage Subscription",
                        body: (
                          <ManageSubscription
                            subscription={subscription}
                            refreshSubscription={fetchSubscription}
                            navigate={navigate}
                            team={team}
                          />
                        ),
                        size: "md",
                        scrollBehavior: "inside",
                      });
                    }}
                  >
                    Manage Subscription
                  </Button>
                )}
            </HStack>

            {subscription.current_period_end && (
              <Text>
                Renews{" "}
                {new Date(
                  subscription.current_period_end * 1000
                ).toLocaleDateString()}
              </Text>
            )}
          </VStack>
        )}
      </HStack>
      <Divider />
      <VStack mt={4} mb={4} textAlign="center">
        <Text mb={4} fontSize="lg">
          {currentPlan === "free" ? (
            <HStack spacing={1}>
              <PiChartBarFill />
              <Text>{description}</Text>
            </HStack>
          ) : (
            <Text>{description}</Text>
          )}
        </Text>
        <Text fontSize="md">{cta}</Text>
        {currentPlan === "free" && (
          <Link
            color="blue.500"
            href="https://www.komodo.io/pricing"
            isExternal
          >
            <Text fontSize={"lg"}>Pricing and Plans</Text>
          </Link>
        )}
      </VStack>
      {subscription && (
        <Box mb={4} alignContent={"flex-start"}>
          <Text fontSize={"md"}>Seats: {subscription.seats}</Text>
        </Box>
      )}
      <Divider />
      <HStack justify="space-between" mt={6} width={"100%"}>
        {currentPlan === "free" ? (
          <Button colorScheme="primary" onClick={onUpgrade} fontSize={"lg"}>
            Upgrade to Pro
          </Button>
        ) : (
          <Text fontSize={"lg"}>You're rocking on Pro 🤘</Text>
        )}

        <HStack align="start" alignItems={"center"}>
          <Text fontSize={"lg"}>Custom needs?</Text>
          <Button
            colorScheme="black"
            variant="outline"
            onClick={onContact}
            fontSize={"md"}
          >
            Contact Sales
          </Button>
        </HStack>
      </HStack>
    </Box>
  );
};

interface PaymentMethodProps {
  team: Team;
  isFetchingPaymentMethods: boolean;
}

interface ManageSubscriptionProps {
  subscription: GetSubscriptionResponse;
  refreshSubscription: () => Promise<void>;
  navigate: any;
  team: Team;
}

const ManageSubscription: React.FC<ManageSubscriptionProps> = ({
  subscription,
  refreshSubscription,
  navigate,
  team,
}) => {
  const { user } = useCognito();
  const modals = useModals();
  const toast = useToast();
  const [moreTeamMembersThanSeats, setMoreTeamMembersThanSeats] =
    useState<boolean>(false);
  const [newSeats, setNewSeats] = useState(subscription.seats);
  const [changedSeats, setChangedSeats] = useState(false);
  const [updatingSubscription, setUpdatingSubscription] = useState(false);

  const onCancelConfirm = async () => {
    setUpdatingSubscription(true);
    try {
      const _ = await CancelSubscription(user!);
      await refreshSubscription();
      setUpdatingSubscription(false);
      modals.closeAll();
      navigate("/billing");
    } catch (error: any) {
      console.error("Error cancelling subscription", error);
      setUpdatingSubscription(false);
      modals.closeAll();
      toast({
        title: "Error cancelling subscription",
        description: `Please try again. Error: ${
          error.response ? error.response.data.detail : error.message
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const onUpdateSeats = async (params: any) => {
    setUpdatingSubscription(true);
    try {
      const _ = await UpdateSubscription(user!, params.seats);
      await refreshSubscription();
      setUpdatingSubscription(false);
      modals.closeAll();
      navigate("/billing");
    } catch (error: any) {
      console.error("Error updating subscription", error);
      setUpdatingSubscription(false);
      modals.closeAll();
      toast({
        title: "Error updating subscription",
        description: `Please try again. Error: ${
          error.response ? error.response.data.detail : error.message
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  if (updatingSubscription) {
    return (
      <VStack align="center" spacing={4}>
        <LoadingOverlay>
          <LoadingSpinner size="lg" color="primary.500" />
          <LoadingText>Updating subscription</LoadingText>
        </LoadingOverlay>
      </VStack>
    );
  }
  return (
    <VStack align="flex-start" spacing={4}>
      <VStack align="flex-start" spacing={4}>
        <HStack spacing={1}>
          <Text>How many seats would you like?</Text>
        </HStack>
        <HStack>
          <Button
            onClick={() => {
              const updated = newSeats - 1;
              if (updated < 0) {
                setMoreTeamMembersThanSeats(true);
                return;
              } else if (updated < team.members.length) {
                setMoreTeamMembersThanSeats(true);
                return;
              } else if (updated === 0) {
                setMoreTeamMembersThanSeats(false);
              } else {
                setMoreTeamMembersThanSeats(false);
                setNewSeats(updated);
                setChangedSeats(true);
              }
            }}
          >
            -
          </Button>
          <Text fontSize="xl" fontWeight="bold">
            {newSeats}
          </Text>
          <Button
            onClick={() => {
              const updated = newSeats + 1;
              if (updated >= team.members.length) {
                setMoreTeamMembersThanSeats(false);
              }
              setNewSeats(updated);
              setChangedSeats(true);
            }}
          >
            +
          </Button>
        </HStack>
      </VStack>
      <HStack justify={"space-between"} width={"100%"}>
        <Button
          colorScheme="primary"
          onClick={() => {
            onUpdateSeats({ seats: newSeats });
          }}
          isDisabled={!changedSeats || newSeats === subscription.seats}
        >
          {!changedSeats
            ? "Purchase"
            : newSeats < subscription.seats
            ? `Remove ${subscription.seats - newSeats} ${
                subscription.seats - newSeats > 1 ? "seats" : "seat"
              }`
            : `Purchase ${newSeats - subscription.seats} ${
                newSeats - subscription.seats > 1 ? "seats" : "seat"
              }`}
        </Button>
        <Button colorScheme="gray" variant="outline" onClick={onCancelConfirm}>
          Downgrade Plan
        </Button>
      </HStack>
      {moreTeamMembersThanSeats && (
        <Text color="red.500" fontSize={"md"} as="b">
          You cannot have fewer seats than team members. Please remove some team
          members first.
        </Text>
      )}
    </VStack>
  );
};

interface SetupFormProps {
  user: User;
  team: Team;
  fetchPaymentMethods: () => Promise<GetPaymentMethodsResponse | undefined>;
  onSuccess: () => void;
}

const SetupForm: React.FC<SetupFormProps> = ({
  user,
  team,
  fetchPaymentMethods,
  onSuccess,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const modals = useModals();
  const toast = useToast();

  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);

  const handleError = (error: any) => {
    setLoading(false);
    setErrorMessage(error.message);
  };

  const handleServerResponse = async (response: any) => {
    if (response.error) {
      toast({
        title: "Error",
        description: response.error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setLoading(false); // Ensure loading is set to false
    } else if (response.status === "requires_action") {
      // Use Stripe.js to handle the required next action
      if (!stripe) {
        setLoading(false);
        toast({
          title: "Error",
          description: "Stripe is not initialized",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      try {
        const { error, setupIntent } = await stripe.handleNextAction({
          clientSecret: response.client_secret,
        });

        if (error) {
          // Show error from Stripe.js in payment setup form
          toast({
            title: "Error",
            description: error.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else if (setupIntent && setupIntent.status === "succeeded") {
          // Actions handled successfully
          toast({
            title: "Success",
            description: "Payment method added successfully",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          modals.closeAll();
          await axios.storage.remove("get-payment-methods");
          fetchPaymentMethods();
          onSuccess();
        } else {
          // Handle unexpected state
          toast({
            title: "Warning",
            description:
              "Unexpected state after action. Please check your payment methods.",
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error("Error handling next action:", error);
        toast({
          title: "Error",
          description:
            "An unexpected error occurred while handling the next action. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    } else {
      // No actions needed, show success message
      setLoading(false);
      toast({
        title: "Success",
        description: "Payment method added successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      modals.closeAll();
      await axios.storage.remove("get-payment-methods");
      let paymentMethods = await fetchPaymentMethods(); // Ensure it returns an array
      do {
        await axios.storage.remove("get-payment-methods");
        paymentMethods = await fetchPaymentMethods(); // Ensure it returns an array
      } while (!paymentMethods || paymentMethods.data.length === 0); // Check length directly
      onSuccess();
    }
  };

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    // Trigger form validation and wallet collection
    if (!elements) {
      return;
    }
    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }

    // Create the ConfirmationToken using the details collected by the Payment Element
    // and additional shipping information
    const { error, confirmationToken } = await stripe.createConfirmationToken({
      elements,
    });

    if (error) {
      // This point is only reached if there's an immediate error when
      // creating the ConfirmationToken. Show the error to your customer (for example, payment details incomplete)
      handleError(error);
      return;
    }

    // Create the SetupIntent
    const res = await axios.post(
      `${process.env.REACT_APP_API_CORE_URL}/api/v1/payments/create-confirm-intent`,
      {
        confirmationTokenId: confirmationToken.id,
        stripeCustomerId: team.stripe_customer_id,
      },
      {
        headers: {
          Authorization: `Bearer ${user?.komo_jwt_token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const data = await res.data;

    // Handle any next actions or errors. See the Handle any next actions step for implementation.
    handleServerResponse(data);
  };
  return (
    <form onSubmit={handleSubmit}>
      <VStack spacing={4} margin={0} width={"100%"}>
        <PaymentElement />
        <Divider />
        <Button
          type="submit"
          colorScheme="primary"
          disabled={!stripe || loading}
          minWidth={"100%"}
          isLoading={loading}
        >
          Add Payment Method
        </Button>
      </VStack>
    </form>
  );
};

export const AddPaymentMethodModal: React.FC<SetupFormProps> = ({
  user,
  team,
  fetchPaymentMethods,
  onSuccess,
}) => {
  const options: StripeElementsOptions = {
    mode: "setup",
    currency: "usd",
    paymentMethodCreation: "manual",
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <SetupForm
        user={user!}
        team={team}
        fetchPaymentMethods={fetchPaymentMethods}
        onSuccess={onSuccess}
      />
    </Elements>
  );
};

const PaymentMethods: React.FC<PaymentMethodProps> = ({
  team,
  isFetchingPaymentMethods,
}) => {
  const { user } = useCognito();
  const modals = useModals();
  const toast = useToast();
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletePaymentMethodId, setDeletePaymentMethodId] = useState<string | null>(null);
  const cancelRef = useRef<HTMLButtonElement | null>(null);
  const { paymentMethods, fetchPaymentMethods } = useBilling();

  useEffect(() => {
  }, [paymentMethods]);

  const handleDeletePaymentMethod = async (paymentMethodId: string) => {
    setIsDeleting(true);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_CORE_URL}/api/v1/payments/payment-methods/${paymentMethodId}`,
        {
          headers: {
            Authorization: `Bearer ${user?.komo_jwt_token}`,
          },
        }
      );
      if (response.status === 200) {
        toast({
          title: "Payment method removed",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        await axios.storage.remove("get-payment-methods");
        await fetchPaymentMethods();
      } else {
        throw new Error("Failed to remove payment method");
      }
    } catch (error) {
      console.error("Error removing payment method:", error);
      toast({
        title: "Error removing payment method",
        description: "Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsDeleting(false);
      setDeletePaymentMethodId(null);
    }
  };

  const openDeleteConfirmation = (paymentMethodId: string) => {
    setDeletePaymentMethodId(paymentMethodId);
  };

  const closeDeleteConfirmation = () => {
    setDeletePaymentMethodId(null);
  };

  

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      p={6}
      textAlign="left"
      shadow="md"
      maxW="95%"
      mx="auto"
      width={"100%"}
    >
      <HStack justify="space-between" mb={4} width={"100%"}>
        <VStack align="flex-start" spacing={4}>
          <Heading fontSize={"2xl"}>Payment Methods</Heading>
        </VStack>
        <Button
          colorScheme="black"
          variant="outline"
          fontSize={"md"}
          onClick={() => {
            modals.open({
              title: `Add a Card`,
              footer: `Adding a payment method for ${team.team_name}`,
              body: (
                <AddPaymentMethodModal
                  user={user!}
                  team={team}
                  fetchPaymentMethods={fetchPaymentMethods}
                  onSuccess={() => {
                    fetchPaymentMethods();
                  }}
                />
              ),
              size: "md",
              scrollBehavior: "inside",
              isCentered: true,
            });
          }}
          isDisabled={paymentMethods && paymentMethods.data.length >= 1}
        >
          Add Card
        </Button>
      </HStack>
      {isFetchingPaymentMethods ? (
        <>
          <SkeletonText noOfLines={1} width="100%" skeletonHeight={10} />
          <Skeleton height="100px" width="100%" />
        </>
      ) : paymentMethods === undefined || paymentMethods.data.length === 0 ? (
        <VStack
          align="center"
          spacing={4}
          width="100%"
          borderWidth="1px"
          borderRadius="md"
          p={4}
        >
          <Icon as={FaCreditCard} boxSize={8} />
          <Text>No payment methods added</Text>
        </VStack>
      ) : (
        paymentMethods.data.map((method) => (
          <HStack
            key={method.id}
            justify="space-between"
            width="100%"
            p={4}
            borderWidth="1px"
            borderRadius="md"
          >
            <HStack>
              <Icon as={FaCreditCard} boxSize={6} />
              {method.type === "card" && method.card && (
                <Text>
                  {method.card.brand.charAt(0).toUpperCase() +
                    method.card.brand.slice(1)}{" "}
                  ending in {method.card.last4}
                </Text>
              )}
              {method.type === "link" && method.link && (
                <Text>Link payment for {method.link.email}</Text>
              )}
            </HStack>
            <HStack>
              {method.is_default && (
                <Badge colorScheme="blue" ml={2}>
                  Default
                </Badge>
              )}
              {method.type === "card" && method.card && (
                <Text>
                  Valid until {method.card.exp_month}/{method.card.exp_year}
                </Text>
              )}
              <IconButton
                aria-label="Remove payment method"
                icon={<FaTrash />}
                size="sm"
                colorScheme="red"
                variant="ghost"
                onClick={() => openDeleteConfirmation(method.id)}
                isDisabled={isDeleting}
              />
            </HStack>
          </HStack>
        ))
      )}

      <AlertDialog
        isOpen={deletePaymentMethodId !== null}
        leastDestructiveRef={cancelRef}
        onClose={closeDeleteConfirmation}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Payment Method
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to remove this payment method? This action cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={closeDeleteConfirmation}>
                Cancel
              </Button>
              <Button 
                colorScheme="red" 
                onClick={() => deletePaymentMethodId && handleDeletePaymentMethod(deletePaymentMethodId)} 
                ml={3}
                isLoading={isDeleting}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

interface CheckoutFormProps {
  team: Team;
}

export const CheckoutForm: React.FC<CheckoutFormProps> = ({ team }) => {
  const { user } = useCognito();
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [loadingStripe, setLoadingStripe] = useState<boolean>(true);
  const [askingForSeats, setAskingForSeats] = useState<boolean>(true);
  const [seats, setSeats] = useState<number>(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const payload = {
          seats: seats,
          customer_email: user!.email,
        };
        const response = await axios.post(
          `${process.env.REACT_APP_API_CORE_URL}/api/v1/payments/create-checkout-session`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${user!.komo_jwt_token}`,
            },
          }
        );
        setClientSecret(response.data);
      } catch (error: any) {
        console.error("Error fetching client secret", error);
      }
    };
    if (!askingForSeats) {
      fetchData();
    }
    setLoadingStripe(false);
  }, [askingForSeats]);

  const handlePurchaseSeats = () => {
    setAskingForSeats(false);
  };

  return (
    <Box id="checkout" overflowY={"scroll"} marginX={"5px"}>
      {askingForSeats ? (
        <VStack align="flex-start" spacing={4}>
          <Text>How many seats would you like to purchase?</Text>
          <HStack>
            <Button
              onClick={() => {
                const newSeats = seats - 1;
                if (newSeats < 0) {
                  return;
                } else if (newSeats < team.members.length) {
                  return;
                } else if (newSeats === 0) {
                } else {
                  setSeats(newSeats);
                }
              }}
            >
              -
            </Button>
            <Text fontSize="xl" fontWeight="bold">
              {seats}
            </Text>
            <Button
              onClick={() => {
                setSeats(seats + 1);
              }}
            >
              +
            </Button>
          </HStack>
          <Button colorScheme="primary" onClick={handlePurchaseSeats}>
            Purchase
          </Button>
        </VStack>
      ) : (
        <HStack align="flex-start" spacing={1} alignItems={"center"}>
          <Text fontSize={"xl"}>Seats:</Text>
          <Text fontSize="xl" fontWeight="bold">
            {seats}
          </Text>
        </HStack>
      )}
      {loadingStripe ? (
        <LoadingOverlay>
          <LoadingSpinner size="lg" color="primary.500" />
          <LoadingText>Loading Stripe</LoadingText>
        </LoadingOverlay>
      ) : (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{ clientSecret }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </Box>
  );
};

const Billing: React.FC = () => {
  const { user } = useCognito();
  const [status, setStatus] = useState<string>("");
  const [customerEmail, setCustomerEmail] = useState<string>("");
  const {
    subscription,
    fetchSubscription,
    isFetchingPaymentMethods,
    isFetchingSubscription,
  } = useBilling();
  const { team } = useTeam();
  const modals = useModals();
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const fetchData = async () => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const sessionId = urlParams.get("session_id");

      if (!sessionId) {
        return;
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_CORE_URL}/api/v1/payments/session-status/${sessionId}`,
          {
            headers: {
              Authorization: `Bearer ${user?.komo_jwt_token}`,
            },
          }
        );
        setStatus(response.data.status);
        setCustomerEmail(response.data.customerEmail);
        fetchSubscription();
        navigate("/billing");
      } catch (error: any) {
        console.error("Error fetching session status", error);
        toast({
          title: "Error fetching session status",
          description: "Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };
    fetchData();
  }, []);

  return (
    <Page variant={"settings"}>
      <PageHeader
        title={
          <Heading size="lg" fontSize={"2xl"}>
            Billing
          </Heading>
        }
      />
      <PageBody>
        <VStack
          divider={<StackDivider />}
          align={"stretch"}
          spacing={8}
          pb={"16"}
          fontSize={"md"}
          width="100%"
        >
          <PaymentMethods
            team={team}
            isFetchingPaymentMethods={isFetchingPaymentMethods}
          />
          <HStack align="start" alignItems={"center"} justifyContent="flex-end" px={6}>
            <Text fontSize={"lg"}>Custom needs?</Text>
            <Button
              colorScheme="black"
              variant="outline"
              onClick={() => {
                window.open("https://www.komodo.io/contact", "_blank");
              }}
              fontSize={"md"}
            >
              Contact Sales
            </Button>
          </HStack>
          {/* <PlanCard
            title={
              Plans[
                subscription && subscription.plan
                  ? subscription.plan.toLowerCase()
                  : "free"
              ].title
            }
            description={
              Plans[
                subscription && subscription.plan
                  ? subscription.plan.toLowerCase()
                  : "free"
              ].description
            }
            cta={
              Plans[
                subscription && subscription.plan
                  ? subscription.plan.toLowerCase()
                  : "free"
              ].cta
            }
            currentPlan={
              subscription && subscription.plan
                ? subscription.plan.toLowerCase()
                : "free"
            }
            onUpgrade={() => {
              modals.open({
                title: "Upgrade to Pro",
                body: <CheckoutForm team={team} />,
                size: "6xl",
                scrollBehavior: "inside",
              });
            }}
            onContact={() => {
              window.open("https://www.komodo.io/contact", "_blank");
            }}
            subscription={subscription}
            isFetchingSubscription={isFetchingSubscription}
          /> */}
          {/* {status === "complete" && (
            <div style={{ position: "absolute", top: "50%", left: "50%" }}>
              <ConfettiExplosion
                force={0.6}
                duration={2200}
                particleCount={80}
                width={1000}
              />
            </div>
          )} */}
        </VStack>
      </PageBody>
    </Page>
  );
};

export default Billing;
