import { Box, Button, Card, CardBody, CardHeader, Flex, useColorMode } from "@chakra-ui/react";
import {
  AuthFormContainer,
  AuthFormDivider,
  PasswordForm
} from "@saas-ui/auth";
import { Field, Form, FormLayout, PinField } from "@saas-ui/forms";
import { SubmitButton, useSnackbar } from "@saas-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useCognito } from "../providers/CognitoContext";

const SignUp: React.FC = () => {
  const { colorMode } = useColorMode();
  const {
    cognitoLoading,
    fetchAuthenticatedUser,
    signedIn,
    setCognitoState,
    signInGoogle,
    signOut,
    user,
    isFetchingUserFromDb,
    signingUp,
    setSigningUp,
    signUpCognitoEmailPassword,
    signInCognitoEmailPassword,
    verifyOtp,
  } = useCognito();
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const [showOtp, setShowOtp] = React.useState(false);
  const [passwordErrors, setPasswordErrors] = React.useState<string[]>([]);

  function validatePassword(password: string): [boolean, string[]] {
    const errors: string[] = [];

    if (password.length < 8) {
      errors.push("Password must be at least 8 characters long.");
    }
    if (!/\d/.test(password)) {
      errors.push("Password must contain at least one number.");
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      errors.push("Password must contain at least one special character.");
    }
    if (!/[A-Z]/.test(password)) {
      errors.push("Password must contain at least one uppercase letter.");
    }
    if (!/[a-z]/.test(password)) {
      errors.push("Password must contain at least one lowercase letter.");
    }

    // Assuming the "temporary passwords set by administrators expire in 7 days" requirement
    // is handled outside of this function, as it pertains to password expiry rather than format.

    return [errors.length === 0, errors];
  }

  return (
    <Flex
      height="100vh"
      alignItems="center"
      justifyContent="center"
      padding={{ base: "2rem", md: "0" }}
    >
      <Card flex="1" maxW="400px">
        <CardHeader display="flex" alignItems="center" justifyContent="center">
          <img
            src={colorMode === "light" ? "/light.svg" : "/dark.svg"}
            style={{ width: "80%" }}
          />
        </CardHeader>
        <CardBody>
          <AuthFormContainer
            onError={(error) => {
              console.error("Error", error);
            }}
          >
            {showOtp ? (
              <Form
                onSubmit={async (data) => {
                  const verifySuccess = await verifyOtp(user!.id, data.otp);
                  if (verifySuccess) {
                    setSigningUp(false);
                    snackbar.success({
                      title: "Success!",
                      description: "Logging in...",
                      status: "success",
                    });
                    setCognitoState({ signedIn: false, cognitoLoading: true});
                    navigate("/");
                    var justSignedUpUserEmail = localStorage.getItem("just_signed_up_user_email");
                    var justSignedUpUserPassword = localStorage.getItem("just_signed_up_user_password");
                    if (justSignedUpUserEmail && justSignedUpUserPassword) {
                      await signInCognitoEmailPassword(justSignedUpUserEmail, atob(justSignedUpUserPassword));
                      localStorage.removeItem("just_signed_up_user_email");
                      localStorage.removeItem("just_signed_up_user_password");
                    }
                  } else {
                    snackbar.error({
                      title: "Error",
                      description: "Invalid OTP",
                      status: "error",
                    });
                  }
                }}
              >
                <FormLayout>
                  <PinField
                    name="otp"
                    label="Your verification code"
                    help="We've sent a one-time password to your email."
                    type="pin"
                    pinLength={6}
                    rules={{ required: true }}
                  />
                  <SubmitButton>Verify</SubmitButton>
                </FormLayout>
              </Form>
            ) : (
              <>
                <PasswordForm
                  onSubmit={async (data) => {
                    const [valid, errors] = validatePassword(data.password);
                    if (!valid) {
                      setPasswordErrors(errors);
                      return;
                    }
                    var user = await signUpCognitoEmailPassword(
                      data.email,
                      data.password,
                      data.firstName,
                      data.lastName
                    );
                    console.log("User", user);
                    if (!user) {
                      snackbar.error({
                        title: "Error registering user",
                        // description: error.message,
                        status: "error",
                      });
                    } else {
                      setShowOtp(true);
                    }
                  }}
                  fields={{
                    submit: {
                      children: "Sign up",
                    },
                  }}
                >
                  {passwordErrors.length > 0 && (
                    <Box color="red.500">
                      {passwordErrors.map((error) => (
                        <div key={error}>{error}</div>
                      ))}
                    </Box>
                  )}
                  <FormLayout columns={2}>
                    <Field name="firstName" label="First name" isRequired />
                    <Field name="lastName" label="Last name" isRequired />
                  </FormLayout>
                </PasswordForm>
                <AuthFormDivider></AuthFormDivider>
                <Button
                  onClick={() => {
                    setSigningUp(false);
                    navigate("/");
                  }}
                >
                  Back to login screen
                </Button>
              </>
            )}
          </AuthFormContainer>
        </CardBody>
      </Card>
    </Flex>
  );
};

export default SignUp;
