// DataGridPage.tsx
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  Badge,
  Button,
  HStack,
  IconButton,
  Text,
  VStack,
  Select,
} from "@chakra-ui/react";
import {
  ColumnDef,
  DataGrid,
  Page,
  PageBody,
  PageHeader,
  TableInstance,
  Toolbar,
} from "@saas-ui-pro/react";
import { EmptyState, LoadingSpinner } from "@saas-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { FiRefreshCw } from "react-icons/fi";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import { useCognito } from "../providers/CognitoContext";
import { axios } from "../utils";

interface DataGridPageProps<T extends { id: string }> {
  title: string;
  description: string;
  columns: ColumnDef<T, any>[];
  data: T[];
  fetchData: () => void;
  fetching: boolean;
  emptyStateTitle: string;
  emptyStateDescription: string;
  createLink: string;
  createButtonText: string;
  detailsBasePath: string;
}

const DataGridPage = <T extends { id: string }>({
  title,
  description,
  columns,
  data,
  fetchData,
  fetching,
  emptyStateTitle,
  emptyStateDescription,
  createLink,
  createButtonText,
  detailsBasePath,
}: DataGridPageProps<T>) => {
  const { user } = useCognito();
  const navigate = useNavigate();
  const dataGridInstanceRef = useRef<TableInstance<T>>(null);
  const ref = useRef<HTMLTableElement>(null);
  const [timeSinceLastFetchInSeconds, setTimeSinceLastFetchInSeconds] =
    useState(0);
  const [refreshInterval, setRefreshInterval] = useState(30000); // Default to 30 seconds

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const calculateRowsPerPage = useCallback(() => {
    const offset = 88; // header + footer (pagination)
    const parent = ref.current?.parentElement;
    const gridHeight = parent?.offsetHeight ? parent.offsetHeight - offset : 0;

    const rowHeight =
      parent?.querySelector<HTMLTableRowElement>("tbody > tr")?.offsetHeight ??
      40;

    if (gridHeight && rowHeight) {
      const pgsize = Math.ceil(gridHeight / rowHeight);
      setPagination((state) => ({
        ...state,
        pageSize: pgsize,
      }));
    }
  }, []);

  const resetPageIndex = useCallback(() => {
    setPagination((state) => ({
      ...state,
      pageIndex: 0,
    }));
  }, []);

  const refreshData = useCallback(() => {
    axios.storage.remove(`get-${detailsBasePath}`);
    setTimeSinceLastFetchInSeconds(0);
    fetchData();
  }, [detailsBasePath, fetchData]);

  useEffect(() => {
    calculateRowsPerPage();
    window.addEventListener("resize", calculateRowsPerPage);
    return () => window.removeEventListener("resize", calculateRowsPerPage);
  }, [data, calculateRowsPerPage]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight !== windowHeight) {
        resetPageIndex();
      }
      setWindowHeight(window.innerHeight);
      // TODO: update local storage
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowHeight, resetPageIndex]);

  useEffect(() => {
    const updateFetchTime = () => {
      const dataLastFetchedAt = localStorage.getItem(
        `${detailsBasePath}_fetched_at`
      );
      const lastFetchedAt = dataLastFetchedAt
        ? new Date(dataLastFetchedAt)
        : null;
      if (lastFetchedAt) {
        const now = new Date().getTime();
        const timeSinceLastFetch = now - (lastFetchedAt.getTime() || 0);
        let seconds = Math.round(timeSinceLastFetch / 1000);
        if (seconds < 1) {
          seconds = 0;
        }
        setTimeSinceLastFetchInSeconds(seconds);
      }
    };

    updateFetchTime();
    const interval = setInterval(updateFetchTime, 5000); // Update every 10 seconds
    return () => clearInterval(interval); // Clear interval on component unmount
  }, [fetching]);

  useEffect(() => {
    // Initial fetch
    refreshData();

    let intervalId: NodeJS.Timeout | null = null;

    if (refreshInterval > 0) {
      // Set up interval for periodic refresh
      intervalId = setInterval(refreshData, refreshInterval);
    }

    // Clean up interval on component unmount or when refreshInterval changes
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [refreshInterval]);

  return (
    <Page
      sx={{
        "& tbody tr:hover": {
          cursor: "pointer",
        },
      }}
      height={"100%"}
    >
      <PageHeader
        title={
          <HStack>
            <Text as="b" fontSize={"xl"}>
              {title}
            </Text>
          </HStack>
        }
        description={description}
        toolbar={
          <Toolbar>
            <HStack>
              {fetching ? (
                <LoadingSpinner size="sm" color="primary.500" />
              ) : (
                <IconButton
                  icon={<FiRefreshCw />}
                  onClick={refreshData}
                  aria-label="Refresh data"
                  sx={{ "background-color": "transparent" }}
                />
              )}
              <Text fontSize="sm" color="gray.500">
                Updated {timeSinceLastFetchInSeconds}s ago
              </Text>
              <HStack>
                <Text fontSize="sm">Auto-refresh</Text>
                <Select
                  value={refreshInterval}
                  onChange={(e) => setRefreshInterval(Number(e.target.value))}
                  size="sm"
                  width="80px"
                >
                  <option value="0">Off</option>
                  <option value="5000">5s</option>
                  <option value="30000">30s</option>
                  <option value="60000">1m</option>
                  <option value="300000">5m</option>
                </Select>
              </HStack>
              {detailsBasePath === "machines" && (
                <ReactRouterLink to={createLink}>
                  <Button
                    colorScheme="primary"
                    isDisabled={!user!.access_approved}
                  >
                    <HStack spacing={1}>
                      <Text>{createButtonText}</Text>
                      <Badge colorScheme="primary">New</Badge>
                    </HStack>
                  </Button>
                </ReactRouterLink>
              )}
            </HStack>
          </Toolbar>
        }
        sx={{
          borderBottom: "none",
        }}
      />
      <PageBody p="0" contentWidth="full">
        {fetching || data.length > 0 ? (
          <DataGrid<T>
            isHoverable
            isSortable
            enableColumnFilters
            enableColumnPinning
            enableColumnResizing
            instanceRef={dataGridInstanceRef}
            ref={ref}
            state={{ pagination }}
            onRowClick={(row) =>
              navigate(`/${detailsBasePath}/${row.original.id}`)
            }
            sx={{
              borderRadius: "md",
              overflow: "scroll",
              th: {
                fontSize: "lg",
                maxHeight: "40px",
              },
              tr: {
                maxHeight: "40px",
              },
            }}
            columns={columns}
            data={data}
          >
            <HStack>
              <Text>
                Page {pagination.pageIndex + 1} of{" "}
                {Math.ceil(data.length / pagination.pageSize)}
              </Text>
              <IconButton
                icon={<ChevronLeftIcon />}
                aria-label="Previous page"
                onClick={() => {
                  const nextPage = pagination.pageIndex - 1;
                  if (nextPage >= 0) {
                    setPagination((state) => ({
                      ...state,
                      pageIndex: nextPage,
                    }));
                  }
                }}
                isDisabled={pagination.pageIndex === 0}
              />
              <IconButton
                icon={<ChevronRightIcon />}
                aria-label="Next page"
                onClick={() => {
                  const nextPage = pagination.pageIndex + 1;
                  if (dataGridInstanceRef.current) {
                    if (
                      nextPage < dataGridInstanceRef.current?.getPageCount()
                    ) {
                      setPagination((state) => ({
                        ...state,
                        pageIndex: nextPage,
                      }));
                    }
                  }
                }}
                isDisabled={
                  dataGridInstanceRef.current
                    ? pagination.pageIndex + 1 >=
                      dataGridInstanceRef.current?.getPageCount()
                    : false
                }
              />
              <Text>Rows per page: {pagination.pageSize}</Text>
            </HStack>
          </DataGrid>
        ) : (
          <EmptyState
            colorScheme="primary"
            icon={HiOutlineDocumentReport}
            title={emptyStateTitle}
            description={emptyStateDescription}
            actions={
              <VStack spacing="4" width="100%">
                {detailsBasePath === "machines" && (
                  <>
                    <Button
                      as={ReactRouterLink}
                      to={createLink}
                      colorScheme="primary"
                      width="100%"
                    >
                      {createButtonText}
                    </Button>
                    <Text>Or</Text>
                  </>
                )}
                <Button
                  as={ReactRouterLink}
                  to={`https://docs.komodo.io/${detailsBasePath}/first_${detailsBasePath.slice(
                    0,
                    -1
                  )}`}
                  target="_blank"
                  variant="outline"
                  colorScheme="primary"
                  width="100%"
                >
                  See CLI instructions in our docs
                </Button>
              </VStack>
            }
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              py: "20",
            }}
          />
        )}
      </PageBody>
    </Page>
  );
};

export default DataGridPage;
