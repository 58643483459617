import { createOpenAI } from "@ai-sdk/openai";
import {
  CompositeAttachmentAdapter,
  SimpleImageAttachmentAdapter,
  SimpleTextAttachmentAdapter, Thread, useDangerousInBrowserRuntime
} from "@assistant-ui/react";
import { makeMarkdownText } from "@assistant-ui/react-markdown";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Collapse,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Input,
  Skeleton,
  Text,
  Textarea,
  useColorModeValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useTheme } from "@emotion/react";
import axios from "axios";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { PiChatsLight } from "react-icons/pi";
import { KomodoService, KomodoServiceStatus } from "../types/types";

export const MarkdownText = makeMarkdownText();
interface ChatLLMProps {
  llmBaseUrl: string;
  modelName: string;
  serviceName: string;
  systemPrompt: string;
}

const SystemPrompt: React.FC<{
  systemPrompt: string;
  setSystemPrompt: (prompt: string) => void;
  onSetSystemPrompt: () => void;
}> = ({ systemPrompt, setSystemPrompt, onSetSystemPrompt }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [localSystemPrompt, setLocalSystemPrompt] = useState(systemPrompt);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
    if (!isOpen && textareaRef.current) {
      // Focus on the textarea when the collapse is opened
      textareaRef.current.focus();
    }
  };

  const handleSetSystemPrompt = () => {
    setSystemPrompt(localSystemPrompt);
    onSetSystemPrompt();
    setIsOpen(false);
  };

  return (
    <Box>
      <Button
        onClick={toggleCollapse}
        rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        mb={2}
        width="100%"
      >
        System Prompt
      </Button>
      <Collapse in={isOpen} animateOpacity>
        <Box border="1px" borderColor="gray.200" borderRadius="md" p={2}>
          <Textarea
            ref={textareaRef}
            value={localSystemPrompt}
            onChange={(e) => setLocalSystemPrompt(e.target.value)}
            placeholder="Enter system prompt"
            size="md"
            resize="vertical"
            mb={2}
          />
          <Button onClick={handleSetSystemPrompt}>Set System Prompt</Button>
        </Box>
      </Collapse>
    </Box>
  );
};

const ChatLLM: React.FC<ChatLLMProps> = ({
  llmBaseUrl,
  modelName,
  serviceName,
  systemPrompt,
}) => {
  const komodo = createOpenAI({
    apiKey: process.env.KOMODO_API_KEY ?? "",
    baseURL: `${llmBaseUrl}/v1`,
  });
  const runtimeKey = useMemo(() => Date.now(), [systemPrompt]);
  const threadRef = useRef<HTMLDivElement>(null);

  const runtime = useDangerousInBrowserRuntime({
    model: komodo(modelName),
    system: systemPrompt,
    adapters: {
      attachments: new CompositeAttachmentAdapter([
        new SimpleImageAttachmentAdapter(),
        new SimpleTextAttachmentAdapter(),
      ]),
    },
  });

  useEffect(() => {
    // Focus only when the component first mounts
    if (threadRef.current) {
      threadRef.current.focus();
    }
  }, []);

  return (
    <div className="h-full ph-no-capture" ref={threadRef} tabIndex={-1}>
      <Thread
        key={runtimeKey}
        runtime={runtime}
        welcome={{
          message: `Chatting with your ${serviceName} model`,
        }}
        assistantAvatar={{
          fallback: "🤖",
        }}
        assistantMessage={{ components: { Text: MarkdownText } }}
      />
    </div>
  );
};

interface ChatDrawerProps {
  llmBaseUrl: string;
  modelName: string;
  serviceName: string;
  isOpen: boolean;
  onClose: () => void;
}

const ChatDrawer: React.FC<ChatDrawerProps> = ({
  llmBaseUrl,
  modelName,
  serviceName,
  isOpen,
  onClose,
}) => {
  const [systemPrompt, setSystemPrompt] = useState<string>("");
  const [systemPromptKey, setSystemPromptKey] = useState(0);
  const theme = useTheme();

  const handleSetSystemPrompt = () => {
    setSystemPromptKey((prev) => prev + 1);
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="right" size="xl">
      <DrawerOverlay />
      <DrawerContent backgroundColor={"app-background"}>
        <DrawerCloseButton />
        <DrawerHeader>Chatting with {serviceName}</DrawerHeader>
        <DrawerBody
          sx={{
            ".aui-thread-root": {
              bg: "app-background",
            },
            ".aui-assistant-message-content": {
              color: useColorModeValue("black", "white"),
            },
            ".aui-composer-root": {
              color: useColorModeValue("black", "white"),
            },
            ".aui-user-message-content": {
              color: useColorModeValue("black", "white"),
              bg: "app-background",
            },
            ".aui-thread-welcome-root": {
              color: useColorModeValue("black", "white"),
            },
            "code:not([class])": {
              color: "white",
              backgroundColor: useColorModeValue(
                "app-background",
                "app-background"
              ),
            },
          }}
        >
          <SystemPrompt
            systemPrompt={systemPrompt}
            setSystemPrompt={setSystemPrompt}
            onSetSystemPrompt={handleSetSystemPrompt}
          />
          <ChatLLM
            key={systemPromptKey}
            llmBaseUrl={llmBaseUrl}
            modelName={modelName}
            serviceName={serviceName}
            systemPrompt={systemPrompt}
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

interface KomodoChatProps {
  service: KomodoService;
}

const KomodoChat: React.FC<KomodoChatProps> = ({ service }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [checkingOpenAICompatibility, setCheckingOpenAICompatibility] =
    useState<boolean>(false);
  const [isOpenAICompatible, setIsOpenAICompatible] = useState<boolean | null>(
    null
  );
  const [modelExists, setModelExists] = useState<boolean | null>(null);
  const [modelNameEmpty, setModelNameEmpty] = useState<boolean>(false);
  const [modelName, setModelName] = useState<string>("");

  const checkOpenAICompatibility = useCallback(async () => {
    if (service.status === KomodoServiceStatus.READY && modelName !== "") {
      setCheckingOpenAICompatibility(true);
      setModelNameEmpty(false);
      try {
        const response = await axios.post(
          `${service.url}/v1/chat/completions`,
          {
            model: modelName,
            messages: [{ role: "user", content: "Hello! What is your name?" }],
            max_tokens: 1,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setIsOpenAICompatible(response.status === 200);
        setModelExists(true);
      } catch (error: any) {
        console.error("Error checking OpenAI compatibility:", error);
        setIsOpenAICompatible(false);
        if (error.response?.status === 404) {
          const errorResponseBody = error.response?.data;
          if (errorResponseBody.type === "NotFoundError") {
            setModelExists(false);
          }
        }
      } finally {
        setCheckingOpenAICompatibility(false);
      }
    } else {
      setIsOpenAICompatible(false);
      setModelNameEmpty(modelName === "");
    }
  }, [service.url, service.status, modelName]);

  useEffect(() => {
    checkOpenAICompatibility();
  }, [checkOpenAICompatibility]);

  if (isOpenAICompatible === null) {
    return (
      <VStack align="center" width="100%" spacing={0}>
        <Skeleton height="40px" width="100%" />
        <Skeleton height="20px" width="80%" mt={2} />
      </VStack>
    );
  }

  const isButtonDisabled =
    !isOpenAICompatible ||
    service.status !== KomodoServiceStatus.READY ||
    checkingOpenAICompatibility;
  const disabledReason =
    service.status !== KomodoServiceStatus.READY
      ? "Service is not ready"
      : modelNameEmpty
      ? "Enter model name to start chatting"
      : modelExists === false
      ? "This model does not exist."
      : !isOpenAICompatible
      ? "This model is not OpenAI compatible."
      : checkingOpenAICompatibility
      ? "Checking compatibility..."
      : "";

  return (
    <VStack align="center" width="100%" spacing={1}>
      <Text fontWeight={"bold"} textAlign={"left"} alignSelf="flex-start">
        Chat with your model{" "}
        <Text as="i" fontWeight={"normal"} fontSize="sm" color="gray.500">
          alpha
        </Text>
      </Text>
      <HStack width="100%" spacing={2} alignItems="center">
        <Input
          placeholder="Enter model name to start chatting"
          value={modelName}
          onChange={(e) => {
            const newModelName = e.target.value.trim();
            setModelName(newModelName);
            setModelNameEmpty(newModelName === "");
          }}
          size="md"
          flex={1}
        />
      </HStack>
      <Button
        leftIcon={<PiChatsLight size={20} />}
        variant="outline"
        size="md"
        onClick={onOpen}
        width="100%"
        isDisabled={isButtonDisabled}
        title={isButtonDisabled ? disabledReason : ""}
      >
        {checkingOpenAICompatibility
          ? "Checking compatibility..."
          : "Chat with your model"}
      </Button>
      {!checkingOpenAICompatibility && (
        <Text as="i">{isButtonDisabled ? disabledReason : ""}</Text>
      )}
      {isOpenAICompatible && service.status === KomodoServiceStatus.READY && (
        <ChatDrawer
          isOpen={isOpen}
          onClose={onClose}
          llmBaseUrl={service.url}
          modelName={modelName}
          serviceName={service.name}
        />
      )}
    </VStack>
  );
};

export default KomodoChat;
