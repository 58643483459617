import axios from "axios";
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useCognito } from "./CognitoContext";

type CloudContextType = {
  connectedClouds: Map<string, ConnectedCloud>;
  fetchConnectedClouds: () => Promise<void>;
  isFetchingConnectedClouds: boolean;
};

interface ConnectedCloud {
  cloud: string;
  connected_cloud_id: string;
  storage_only?: boolean;
}

const CloudContext = createContext<CloudContextType | undefined>(undefined);

export const useConnectedCloud = () => {
  const context = useContext(CloudContext);
  if (context === undefined) {
    throw new Error("useConnectedCloud must be used within a ConnectedCloudProvider");
  }
  return context;
};

export const ConnectedCloudProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { cognitoLoading, user, isFetchingUserFromDb } = useCognito();
  const [connectedClouds, setConnectedClouds] = useState<Map<string, ConnectedCloud>>(new Map());
  const [isFetchingConnectedClouds, setIsFetchingConnectedClouds] = useState<boolean>(true);

  const fetchConnectedClouds = async () => {
    setIsFetchingConnectedClouds(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_CORE_URL}/api/v1/connected-clouds`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.komo_jwt_token}`,
          },
        }
      );
      const responseData = response.data;
      const connectedClouds: Map<string, ConnectedCloud> = new Map(
        responseData.map((el: any) => {
          const connected_cloud_id = el.account_id || el.api_key_name || el.project_id || el.host || el.tenant_id;
          return [el.cloud, {
            cloud: el.cloud,
            connected_cloud_id: connected_cloud_id,
            storage_only: el.storage_only,
          }];
        })
      );
      setConnectedClouds(connectedClouds);
    } catch (error) {
      console.log("Error fetching connected clouds", error);
    } finally {
      setIsFetchingConnectedClouds(false);
    }
  };

  useEffect(() => {
    if (!cognitoLoading && !isFetchingUserFromDb) {
      fetchConnectedClouds();
    }
  }, [cognitoLoading, isFetchingUserFromDb]);

  return (
    <CloudContext.Provider value={{ connectedClouds, fetchConnectedClouds, isFetchingConnectedClouds }}>
      {children}
    </CloudContext.Provider>
  );
};
