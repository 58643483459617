import React, { useState, useCallback } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  SimpleGrid,
  Tag,
  Text,
  VStack,
  Wrap,
  WrapItem,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { FaCogs, FaKey, FaMicrochip, FaServer } from "react-icons/fa";
import { Recipe } from "./Recipe";
import { useServices } from "../providers/ServicesContext";
import { CreateKomodoServicePayload } from "../types/types";
import { useBilling } from "../providers/BillingContext";
import { useCognito } from "../providers/CognitoContext";
import { AddPaymentMethodModal } from "./Billing";
import { useTeam } from "../providers/TeamContext";
import { useModals } from "@saas-ui/react";
import { fetchKomodoCloudInstances, Instance } from "./CreateMachineV2";
import { useNavigate } from "react-router-dom";
import PaymentValidationFailedBanner from "./PaymentValidationFailed"; // Import the banner

interface DeploymentFormProps {
  recipe: Recipe;
  onClose: () => void;
}

const DeploymentForm: React.FC<DeploymentFormProps> = ({ recipe, onClose }) => {
  const [name, setName] = useState(recipe.serviceConfig.name || "");
  const [minReplicas, setMinReplicas] = useState(
    recipe.serviceConfig.service?.replica_policy?.min_replicas || 1
  );
  const [maxReplicas, setMaxReplicas] = useState(
    recipe.serviceConfig.service?.replica_policy?.max_replicas || 3
  );
  const [targetQps, setTargetQps] = useState(
    recipe.serviceConfig.service?.replica_policy?.target_qps_per_replica || 5
  );
  const [upscaleDelay, setUpscaleDelay] = useState(
    recipe.serviceConfig.service?.replica_policy?.upscale_delay_seconds || 300
  );
  const [downscaleDelay, setDownscaleDelay] = useState(
    recipe.serviceConfig.service?.replica_policy?.downscale_delay_seconds ||
      1200
  );
  const [hfToken, setHfToken] = useState("");
  const [isDeploying, setIsDeploying] = useState(false);
  const toast = useToast();
  const { createService } = useServices();

  const bgColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const modals = useModals();
  const { user } = useCognito();
  const { subscription, paymentMethods, fetchPaymentMethods } = useBilling();
  const { team } = useTeam();
  const navigate = useNavigate();
  const [paymentValidationFailed, setPaymentValidationFailed] = useState(false);

  const openPaymentModal = useCallback(
    (name: string, hfToken: string) => {
      modals.open({
        title: `Add a payment method to continue`,
        body: (
          <AddPaymentMethodModal
            user={user!}
            team={team}
            fetchPaymentMethods={fetchPaymentMethods}
            onSuccess={() => {
              console.log("onSuccess in DeploymentForm");
              modals.closeAll();
              handleDeploy(name, hfToken, true);
            }}
          />
        ),
      });
    },
    [modals, user, team, fetchPaymentMethods]
  );

  const handleDeploy = async (
    name: string,
    hfToken: string,
    callingAfterPaymentMethodAdded: boolean = false
  ) => {
    if (!name || !hfToken) {
      toast({
        title: "Error",
        description: "Please enter a service name and Hugging Face token",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const hasPaymentMethod = paymentMethods && paymentMethods.data.length > 0;

    if (callingAfterPaymentMethodAdded) {
      setIsDeploying(true);
      let paymentMethods = await fetchPaymentMethods();
      do {
        paymentMethods = await fetchPaymentMethods();
      } while (!paymentMethods || paymentMethods.data.length === 0);
    } else {
      if (!hasPaymentMethod) {
        var requiredAccelerators = recipe.serviceConfig.resources.accelerators;
        const parsedAccelerators = Object.keys(requiredAccelerators).map(
          (key) => {
            const [gpu_type, num_gpus] = key.split(":");
            return { gpu_type, num_gpus: parseInt(num_gpus, 10) };
          }
        );
        let availableInstances: Instance[] = [];
        try {
          const instances = await fetchKomodoCloudInstances(
            user!.komo_jwt_token
          );
          availableInstances = instances.filter((instance: Instance) => {
            return parsedAccelerators.some(({ gpu_type, num_gpus }) => {
              return (
                instance.gpu_type.includes(gpu_type) &&
                instance.num_gpus == num_gpus
              );
            });
          });

          if (availableInstances.length === 0) {
            toast({
              title: "No Available Instances",
              description:
                "There are currently no instances available that meet the required GPU specifications.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            return;
          }
        } catch (error) {
          console.error("Error fetching Komodo cloud capacity", error);
          toast({
            title: "Error",
            description: "There was an error fetching the Komodo cloud capacity. Please try again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return;
        }

        // Find available regions from the available instances
        const availableRegions = availableInstances.flatMap((instance) =>
          instance.regions.filter((region) => region.available)
        );

        if (availableRegions.length === 0) {
          toast({
            title: "No Available Regions",
            description:
              "There are currently no regions available for the required GPU specifications.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return;
        }
        // Sort regions by price (cents_per_hour) in ascending order
        const sortedRegions = availableRegions.sort(
          (a, b) => a.cents_per_hour - b.cents_per_hour
        );

        // Select the cheapest region
        const selectedRegion = sortedRegions[0];
        var insufficientCredits = false;
        if (
          subscription &&
          selectedRegion &&
          subscription.balance * -1 < selectedRegion.cents_per_hour
        ) {
          insufficientCredits = true;
        }
        if (insufficientCredits) {
          openPaymentModal(name, hfToken);
          return;
        }
      }
    }

    setIsDeploying(true);
    try {
      const updatedConfig: CreateKomodoServicePayload = {
        ...recipe.serviceConfig,
        name,
        service: {
          ...recipe.serviceConfig.service,
          replica_policy: {
            min_replicas: minReplicas,
            max_replicas: maxReplicas,
            target_qps_per_replica: targetQps,
            upscale_delay_seconds: upscaleDelay,
            downscale_delay_seconds: downscaleDelay,
          },
        },
        envs: {
          ...recipe.serviceConfig.envs,
          HF_TOKEN: hfToken,
        },
      };

      const newService = await createService(updatedConfig);

      if (newService) {
        toast({
          title: "Deployment Started",
          description: `${recipe.title} is being deployed as ${name}`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        onClose();
        navigate(`/services/${newService.id}`);
      } else {
        throw new Error("Failed to create service");
      }
    } catch (error) {
      console.error("Error deploying service:", error);
      toast({
        title: "Deployment Failed",
        description:
          "There was an error deploying the service. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsDeploying(false);
    }
  };

  const processAccelerators = (accelerators: object) => {
    return Object.keys(accelerators).map((key) => key.replace(/^\{|\}$/g, ""));
  };

  return (
    <VStack spacing={6} align="stretch">
      <Box
        p={6}
        borderRadius="lg"
        boxShadow="md"
        bg={bgColor}
        borderWidth={1}
        borderColor={borderColor}
      >
        <VStack spacing={4} align="stretch">
          <Heading size="md" display="flex" alignItems="center">
            <Icon as={FaServer} mr={2} />
            Service Configuration
          </Heading>
          <FormControl isRequired>
            <FormLabel>Service Name</FormLabel>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter a name for your service"
            />
          </FormControl>
          <Box>
            <Heading size="sm" mb={2} display="flex" alignItems="center">
              <Icon as={FaMicrochip} mr={2} />
              Hardware Resources
            </Heading>
            <Text fontSize="sm" mb={2}>
              This model will be deployed with one of the following
              accelerators:
            </Text>
            <Wrap>
              {processAccelerators(
                recipe.serviceConfig.resources.accelerators
              ).map((accelerator, index) => (
                <WrapItem key={index}>
                  <Tag
                    size="md"
                    colorScheme={"primary"}
                    borderRadius="full"
                    variant="outline"
                  >
                    {accelerator}
                  </Tag>
                </WrapItem>
              ))}
            </Wrap>
          </Box>
        </VStack>
      </Box>

      <Box
        p={6}
        borderRadius="lg"
        boxShadow="md"
        bg={bgColor}
        borderWidth={1}
        borderColor={borderColor}
      >
        <VStack spacing={4} align="stretch">
          <Heading size="md" display="flex" alignItems="center">
            <Icon as={FaCogs} mr={2} />
            Replica Policy
          </Heading>
          <SimpleGrid columns={2} spacing={4}>
            <FormControl>
              <FormLabel>Min Replicas</FormLabel>
              <NumberInput
                min={1}
                value={minReplicas}
                onChange={(_, value) => setMinReplicas(value)}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
            <FormControl>
              <FormLabel>Max Replicas</FormLabel>
              <NumberInput
                min={1}
                value={maxReplicas}
                onChange={(_, value) => setMaxReplicas(value)}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
          </SimpleGrid>
          <FormControl>
            <FormLabel>Target QPS per Replica</FormLabel>
            <NumberInput
              min={1}
              value={targetQps}
              onChange={(_, value) => setTargetQps(value)}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
          <SimpleGrid columns={2} spacing={4}>
            <FormControl>
              <FormLabel>Upscale Delay</FormLabel>
              <NumberInput
                min={1}
                value={upscaleDelay}
                onChange={(_, value) => setUpscaleDelay(value)}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
            <FormControl>
              <FormLabel>Downscale Delay</FormLabel>
              <NumberInput
                min={1}
                value={downscaleDelay}
                onChange={(_, value) => setDownscaleDelay(value)}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
          </SimpleGrid>
        </VStack>
      </Box>

      <Box
        p={6}
        borderRadius="lg"
        boxShadow="md"
        bg={bgColor}
        borderWidth={1}
        borderColor={borderColor}
      >
        <VStack spacing={4} align="stretch">
          <Heading size="md" display="flex" alignItems="center">
            <Icon as={FaKey} mr={2} />
            Authentication
          </Heading>
          <FormControl isRequired>
            <FormLabel>Hugging Face Token</FormLabel>
            <Input
              type="password"
              value={hfToken}
              onChange={(e) => setHfToken(e.target.value)}
              placeholder="Enter your Hugging Face token"
            />
          </FormControl>
        </VStack>
      </Box>
      <Button
        colorScheme="primary"
        size="lg"
        onClick={() => handleDeploy(name, hfToken, false)}
        isLoading={isDeploying}
        loadingText="Deploying"
        width="full"
      >
        Deploy
      </Button>
    </VStack>
  );
};

export default DeploymentForm;
