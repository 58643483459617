import axios from "axios";
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useCognito } from "./CognitoContext";

type SSHKeyContextType = {
  publicSshKey: string;
  fetchPublicSshKey: () => Promise<void>;
  isFetchingPublicSshKey: boolean;
};

const SSHKeyContext = createContext<SSHKeyContextType | undefined>(undefined);

export const useSSHKey = () => {
  const context = useContext(SSHKeyContext);
  if (context === undefined) {
    throw new Error("useSSHKey must be used within a SSHKeyProvider");
  }
  return context;
};

export const SSHKeyProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { cognitoLoading, user, isFetchingUserFromDb } = useCognito();
  const [publicSshKey, setPublicSshKey] = useState<string>("");
  const [isFetchingPublicSshKey, setIsFetchingPublicSshKey] = useState<boolean>(true);

  const fetchPublicSshKey = async () => {
    setIsFetchingPublicSshKey(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_CORE_URL}/api/v1/public-ssh-key`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.komo_jwt_token}`,
          },
        }
      );
      const publicSshKey = response.data["public-ssh-key"];
      setPublicSshKey(publicSshKey);
    } catch (error) {
      console.log("Error fetching public ssh key", error);
    } finally {
      setIsFetchingPublicSshKey(false);
    }
  };

  useEffect(() => {
    if (!cognitoLoading && !isFetchingUserFromDb && user) {
      fetchPublicSshKey();
    }
  }, [cognitoLoading, isFetchingUserFromDb, user]);

  return (
    <SSHKeyContext.Provider value={{ publicSshKey, fetchPublicSshKey, isFetchingPublicSshKey }}>
      {children}
    </SSHKeyContext.Provider>
  );
};
