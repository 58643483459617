import yaml from 'js-yaml';

export function generateYamlFromConfig(config: any): string {
  const yamlConfig = {
    resources: {
      ...config.resources,
      accelerators: formatAccelerators(config.resources.accelerators),
    },
    service: config.service,
    envs: config.envs,
    setup: config.setup,
    run: config.run,
  };

  return yaml.dump(yamlConfig, { indent: 2, quotingType: '"', lineWidth: 1000 })
    .replace(/accelerators: "(.+)"/g, 'accelerators: $1')
    .replace(/(\n)([a-zA-Z])/g, '$1\n$2')
    .replace('  readiness_probe:', '\n  readiness_probe:')
    .replace(/ \|-/g, ' |')
    .replace('setup: >-', 'setup: |')
    .replace(/setup: ([a-zA-Z0-9])/g, 'setup: |\n  $1')
    .replace(/run: ([a-zA-Z0-9])/g, 'setup: |\n  $1')
}

function formatAccelerators(accelerators: Record<string, null>): string {
  if (typeof accelerators === 'string') {
    return accelerators; // Return the string directly if the argument is a string
  }

  const acceleratorString = Object.keys(accelerators).join(', ');
  return `{${acceleratorString}}`;
}
