import {
  Box,
  Button,
  Link as ChakraLink,
  Flex,
  HStack,
  Heading,
  Icon,
  IconButton,
  Skeleton,
  Spacer,
  StackDivider,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { Page, PageBody, PageHeader } from "@saas-ui-pro/react";
import {
  Field,
  Form,
  FormLayout,
  SubmitButton,
  useModals
} from "@saas-ui/react";
import React, { useState } from "react";
import { FiEdit2, FiRefreshCw } from "react-icons/fi";
import { IoPersonAddOutline, IoPersonRemoveOutline } from "react-icons/io5";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import { BillingProvider, useBilling } from "../providers/BillingContext";
import { useCognito } from "../providers/CognitoContext";
import { useConnectedCloud } from "../providers/ConnectedCloudContext";
import {
  AcceptTeamInvite,
  DeclineTeamInvite,
  EditTeamName,
  InviteUserToTeam,
  RemoveTeamMember,
  useTeam,
} from "../providers/TeamContext";
import { PlanType } from "../types/types";
import { axios } from "../utils";

interface TeamModalProps {
  fetchTeam: () => Promise<void>;
}

const InviteTeammateModal: React.FC<TeamModalProps> = ({ fetchTeam }) => {
  const { user } = useCognito();
  const modals = useModals();
  const toast = useToast();

  const onSubmitInviteTeammate = async (params: any) => {
    modals.closeAll();
    if (params.role === undefined) {
      params.role = "Member";
    }
    try {
      const _ = await InviteUserToTeam(user!, params.email, params.role);
      toast({
        title: "Teammate invited",
        description: `Teammate ${params.email} has been invited`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      await fetchTeam();
    } catch (error: any) {
      console.error("Error inviting teammate", error);
      toast({
        title: "Error inviting teammate",
        description: error.response
          ? error.response.data.detail
          : error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Form onSubmit={onSubmitInviteTeammate}>
      <FormLayout>
        <Field
          name="email"
          label="Email"
          isRequired={true}
          placeholder="Email"
          type="email"
        />
        <Field
          name="role"
          label="Role"
          type="select"
          options={["Member", "Admin"]}
          defaultValue={"Member"}
        />
        <SubmitButton>Invite</SubmitButton>
      </FormLayout>
    </Form>
  );
};

const EditTeamModal: React.FC<TeamModalProps> = ({ fetchTeam }) => {
  const { user } = useCognito();
  const modals = useModals();
  const toast = useToast();

  const onSubmitEditTeam = async (params: any) => {
    modals.closeAll();
    try {
      const _ = await EditTeamName(user!, params.name);
      toast({
        title: "Team edited",
        description: `Team has been edited`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      await fetchTeam();
    } catch (error: any) {
      console.error("Error editing team", error);
      toast({
        title: "Error editing team",
        description: error.response
          ? error.response.data.detail
          : error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Form onSubmit={onSubmitEditTeam}>
      <FormLayout>
        <Field
          name="name"
          label="Name"
          isRequired={true}
          placeholder="Name"
          type="text"
        />
        <SubmitButton>Save</SubmitButton>
      </FormLayout>
    </Form>
  );
};

interface AcceptTeamInviteModalProps {
  fetchTeam: () => Promise<void>;
  fetchTeamInvitations: () => Promise<void>;
  fetchConnectedClouds: () => Promise<void>;
  fetchAuthenticatedUser: () => void;
  invitation_id: string;
}

const AcceptTeamInviteModal: React.FC<AcceptTeamInviteModalProps> = ({
  fetchTeam,
  invitation_id,
  fetchTeamInvitations,
  fetchConnectedClouds,
  fetchAuthenticatedUser,
}) => {
  const { user } = useCognito();
  const modals = useModals();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const onAccepTeamInvite = async () => {
    setIsLoading(true);
    try {
      const _ = await AcceptTeamInvite(user!, invitation_id);
      toast({
        title: "Team invite accepted",
        description: `You have accepted the team invite`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      await fetchAuthenticatedUser();
      await fetchTeam();
      await fetchTeamInvitations();
      await fetchConnectedClouds();
    } catch (error: any) {
      console.error("Error accepting team invite", error);
      toast({
        title: "Error accepting team invite",
        description: error.response
          ? error.response.data.detail
          : error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
      modals.closeAll();
    }
  };

  return (
    <Box>
      <VStack spacing={4} align="stretch">
        <Text fontSize={"md"}>
          By accepting this invitation, you will be joining the team and giving
          up your own personal team. Are you sure you want to proceed?
        </Text>
        <HStack spacing={4} justifyContent="flex-end">
          <Button colorScheme="red" onClick={modals.closeAll}>
            Cancel
          </Button>
          <Button
            colorScheme="green"
            onClick={onAccepTeamInvite}
            isLoading={isLoading}
          >
            Accept
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};

interface RemoveTeamMemberModalProps {
  fetchTeam: () => Promise<void>;
  memberId: string;
  memberEmail: string;
}

const RemoveTeamMemberModal: React.FC<RemoveTeamMemberModalProps> = ({
  fetchTeam,
  memberId,
  memberEmail,
}) => {
  const { user } = useCognito();
  const modals = useModals();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const onRemoveTeamMember = async () => {
    setIsLoading(true);
    modals.closeAll();
    try {
      const _ = await RemoveTeamMember(user!, memberId, memberEmail);
      toast({
        title: "Team member removed",
        description: `The team member has been removed`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      await fetchTeam();
    } catch (error: any) {
      console.error("Error removing team member", error);
      toast({
        title: "Error removing team member",
        description: error.response
          ? error.response.data.detail
          : error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <VStack spacing={4} align="stretch">
        <Text fontSize={"md"}>
          Are you sure you want to remove {memberEmail}? This action cannot be
          undone.
        </Text>
        <HStack spacing={4} justifyContent="flex-end">
          <Button colorScheme="red" onClick={modals.closeAll}>
            Cancel
          </Button>
          <Button
            colorScheme="green"
            onClick={onRemoveTeamMember}
            isLoading={isLoading}
          >
            Remove
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};

const TeamSubscriptionInfo: React.FC = () => {
  const { team } = useTeam();
  const { subscription, isFetchingSubscription } = useBilling();

  // if (isFetchingSubscription) {
  //   return <Skeleton height={"3rem"} width={"15rem"} />;
  // }
  // if (subscription && team) {
  //   return (
  //     <VStack textAlign={"left"} alignItems={"flex-start"}>
  //       <Text fontSize={"md"} color={"gray.500"}>
  //         You have {subscription?.seats - team?.members.length} more seats on
  //         the current subscription.{" "}
  //       </Text>
  //       <ChakraLink as={ReactRouterLink} to="/billing" color="primary">
  //         Manage Subscription
  //       </ChakraLink>
  //     </VStack>
  //   );
  // }
  return <></>;
};

const TeamPageToolbar: React.FC = () => {
  const { user } = useCognito();
  const { team, fetchTeam } = useTeam();
  const { subscription } = useBilling();
  const modals = useModals();
  const navigate = useNavigate();

  const handleRouteToBilling = () => {
    modals.closeAll();
    navigate("/billing");
  };

  return (
    <Flex
      justifyContent="space-between"
      width="100%"
      justify={"flex-end"}
      alignContent={"center"}
    >
      <Spacer />
      {user!.role === "Admin" ?
      // (subscription?.plan === PlanType.PRO ||
      //   subscription?.plan === PlanType.POC) ?
      (
        <Button
          colorScheme="primary"
          size={"lg"}
          onClick={() => {
            // if (team?.members.length < subscription.seats) {
              modals.open({
                title: "Invite teammate",
                body: <InviteTeammateModal fetchTeam={fetchTeam} />,
              });
            // } else {
            //   modals.open({
            //     title: "Invite teammate",
            //     body: (
            //       <VStack align="center" spacing={4}>
            //         <Text fontSize={"md"}>
            //           You have reached the maximum number of seats for your
            //           subscription ({subscription.seats}). Please add more seats
            //           to invite a new teammate.
            //         </Text>
            //         <Button variant={"primary"} onClick={handleRouteToBilling}>
            //           Manage subscription
            //         </Button>
            //       </VStack>
            //     ),
            //   });
            // }
          }}
          isDisabled={!user?.access_approved}
        >
          <HStack>
            <Icon as={IoPersonAddOutline} />
            <Text>Invite</Text>
          </HStack>
        </Button>
      )
      // : user!.role === "Admin" && subscription?.plan !== PlanType.PRO ? (
      //   <Tooltip label="Upgrade to Pro to invite teammates.">
      //     <Button colorScheme="primary" size={"lg"} isDisabled>
      //       <HStack>
      //         <Icon as={IoPersonAddOutline} />
      //         <Text>Invite</Text>
      //       </HStack>
      //     </Button>
      //   </Tooltip>
      // )
      : (
        <Tooltip label="Only team administrators can perform this action.">
          <Button
            colorScheme="primary"
            size={"lg"}
            onClick={() =>
              modals.open({
                title: "Invite teammate",
                body: <InviteTeammateModal fetchTeam={fetchTeam} />,
              })
            }
            isDisabled={user?.role !== "Admin"}
          >
            <HStack>
              <Icon as={IoPersonAddOutline} />
              <Text>Invite</Text>
            </HStack>
          </Button>
        </Tooltip>
      )}
    </Flex>
  );
};

const Team: React.FC = () => {
  const { user, fetchAuthenticatedUser } = useCognito();
  const {
    team,
    fetchTeam,
    teamInvitation,
    fetchPendingInvitation,
    isFetchingTeam,
  } = useTeam();
  const { fetchConnectedClouds } = useConnectedCloud();
  const modals = useModals();
  const [refreshingTeam, setRefreshingTeam] = useState(false);

  const handleRefresh = async () => {
    await axios.storage.remove("get-team");
    await axios.storage.remove("get-team-invitations");
    setRefreshingTeam(true);
    await fetchTeam();
    await fetchPendingInvitation();
    setRefreshingTeam(false);
  };

  return (
    <Page variant={"settings"}>
      <PageHeader
        title={
          <VStack spacing={2} align="stretch" width={"100%"}>
            <HStack spacing={2} maxW={"100%"}>
              {isFetchingTeam ? (
                <Skeleton height={"1.5rem"} width={"10rem"} />
              ) : (
                <Heading maxW={"100%"} size="lg" whiteSpace={"nowrap"}>
                  {team?.team_name}
                </Heading>
              )}

              <Box>
                <IconButton
                  aria-label="Edit team"
                  icon={<FiEdit2 />}
                  fontSize={"md"}
                  onClick={() => {
                    modals.open({
                      title: "Edit team",
                      body: <EditTeamModal fetchTeam={fetchTeam} />,
                    });
                  }}
                  sx={{ "background-color": "transparent" }}
                  isDisabled={user?.role !== "Admin"}
                />
              </Box>
              <Box>
                <IconButton
                  icon={<FiRefreshCw />}
                  onClick={handleRefresh}
                  aria-label="Refresh data"
                  fontSize={"md"}
                  sx={{ "background-color": "transparent" }}
                  isLoading={refreshingTeam}
                />
              </Box>
            </HStack>
            <BillingProvider>
              <TeamSubscriptionInfo />
            </BillingProvider>
          </VStack>
        }
        toolbar={
          <BillingProvider>
            <TeamPageToolbar />
          </BillingProvider>
        }
      />
      <PageBody>
        <VStack
          divider={<StackDivider />}
          align={"stretch"}
          spacing={8}
          pb={"16"}
          fontSize={"md"}
        >
          {teamInvitation && (
            <Box width={"100%"} overflowX={"auto"}>
              <Heading size="md" mb={4}>
                Pending Invitations
              </Heading>
              <VStack spacing={4} align="stretch">
                <Box p={5} shadow="md" borderWidth="1px" borderRadius="md">
                  <Flex justifyContent="space-between" alignItems="center">
                    <Box>
                      <Text fontWeight="bold">Invited by (email)</Text>
                      <Text>{teamInvitation.invited_by_email}</Text>
                    </Box>
                    <Box>
                      <Text fontWeight="bold">Team</Text>
                      <Text>{teamInvitation.team_name}</Text>
                    </Box>
                    <Box>
                      <Text fontWeight="bold">Role</Text>
                      <Text>{teamInvitation.role}</Text>
                    </Box>
                    <HStack>
                      <Button
                        colorScheme="red"
                        onClick={async () => {
                          try {
                            const _ = await DeclineTeamInvite(
                              user!,
                              teamInvitation.invitation_id
                            );
                            fetchPendingInvitation();
                          } catch (error: any) {
                            console.error("Error declining team invite", error);
                          }
                        }}
                      >
                        Decline
                      </Button>
                      <Button
                        colorScheme="green"
                        onClick={() => {
                          modals.open({
                            title: "Accept Invitation",
                            body: (
                              <AcceptTeamInviteModal
                                fetchTeam={fetchTeam}
                                invitation_id={teamInvitation.invitation_id}
                                fetchTeamInvitations={fetchPendingInvitation}
                                fetchConnectedClouds={fetchConnectedClouds}
                                fetchAuthenticatedUser={fetchAuthenticatedUser}
                              />
                            ),
                          });
                        }}
                      >
                        Accept
                      </Button>
                    </HStack>
                  </Flex>
                </Box>
              </VStack>
            </Box>
          )}

          <Box width="100%" overflowX="auto">
            <Table variant="simple" size="lg">
              <Thead>
                <Tr>
                  <Th>EMAIL</Th>
                  <Th>ROLE</Th>
                  <Th>ACTIONS</Th>
                </Tr>
              </Thead>
              <Tbody>
                {team &&
                  team.members.map((teamUser, index) => (
                    <Tr key={index}>
                      <Td>
                        {teamUser.email === user!.email
                          ? `${teamUser.email} (You)`
                          : teamUser.pending_invitation
                          ? `${teamUser.email} (pending)`
                          : teamUser.email}
                      </Td>
                      <Td>{<Text>{teamUser.role}</Text>}</Td>
                      <Td>
                        <HStack>
                          {/* <IconButton
                          aria-label="Edit user"
                          icon={<FiEdit2 />}
                          size="sm"
                        /> */}
                          <IconButton
                            aria-label="Remove user"
                            icon={<IoPersonRemoveOutline />}
                            size="sm"
                            colorScheme="red"
                            isDisabled={
                              user?.role !== "Admin" ||
                              teamUser.email === user?.email
                            }
                            onClick={() => {
                              modals.open({
                                title: "Remove team member",
                                body: (
                                  <RemoveTeamMemberModal
                                    fetchTeam={fetchTeam}
                                    memberId={teamUser.user_id}
                                    memberEmail={teamUser.email}
                                  />
                                ),
                              });
                            }}
                          />
                        </HStack>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </Box>
        </VStack>
      </PageBody>
    </Page>
  );
};

export default Team;
