import {
  Badge,
  Box,
  Button,
  Code,
  Container,
  HStack,
  IconButton,
  SimpleGrid,
  Text,
  VStack,
  useColorMode,
  useToast,
  Kbd,
} from "@chakra-ui/react";
import { BackButton, Page, PageHeader, Toolbar } from "@saas-ui-pro/react";
import { EmptyState, LoadingSpinner, useModals } from "@saas-ui/react";
import React, { useEffect, useState } from "react";
import { FiRefreshCw } from "react-icons/fi";
import { SiJupyter } from "react-icons/si";
import { useLocation, useNavigate } from "react-router-dom";
import { useCognito } from "../providers/CognitoContext";
import {
  GetMachineById,
  GetMachineHostInfo,
  TerminateMachine,
} from "../providers/MachinesContext";
import {
  KomodoStatus,
  KomodoStatusColorScheme,
  Machine,
  KomodoStatusToString,
} from "../types/types";
import {
  ScriptModalBody,
  ScriptModalContentProps,
  RequestedResources,
  Networking,
} from "./common";
import LogBox from "./LogBox";

const MachineDetails: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = useCognito();
  const modals = useModals();
  const toast = useToast();

  const machine_id = location.pathname.split("/").pop();
  const [machine, setMachine] = useState<Machine | undefined>(undefined);
  const [isLoadingMachine, setIsLoadingMachine] = useState(false);
  const [machineNoFound, setMachineNotFound] = useState(false);

  useEffect(() => {
    const fetchMachineDetails = async () => {
      if (!machine_id) {
        setMachineNotFound(true);
        return;
      }

      setIsLoadingMachine(true);
      try {
        const fetchedMachine = await GetMachineById(machine_id, user);
        if (fetchedMachine) {
          // try {
          //   const machineHostInfoResponse = await GetMachineHostInfo(
          //     machine_id,
          //     user
          //   );
          //   fetchedMachine.hostName = machineHostInfoResponse?.host_name;
          //   fetchedMachine.userName = machineHostInfoResponse?.user;
          // } catch (error) {
          //   console.error("Error fetching machine host info", error);
          // }
          setMachine(fetchedMachine);
          setMachineNotFound(false);
        } else {
          console.log(`Machine ${machine_id} not found in API`);
          setMachineNotFound(true);
        }
      } catch (error) {
        console.error("Error fetching machine", error);
      } finally {
        setIsLoadingMachine(false);
      }
    };

    fetchMachineDetails();
  }, [machine_id, user]);

  const refreshMachineDetails = async () => {
    setIsLoadingMachine(true);
    try {
      const updatedMachine = await GetMachineById(machine_id!, user);
      if (updatedMachine) {
        setMachine(updatedMachine);
        setMachineNotFound(false);

        // try {
        //   const machineHostInfoResponse = await GetMachineHostInfo(
        //     updatedMachine!.id,
        //     user
        //   );
        //   updatedMachine.hostName = machineHostInfoResponse?.host_name;
        //   updatedMachine.userName = machineHostInfoResponse?.user;
        // } catch (error) {
        //   console.error("Error fetching machine host info", error);
        // }
      } else {
        console.log(`Machine ${machine_id} not found in API`);
        setMachineNotFound(true);
      }
    } catch (error) {
      console.error("Error fetching machine", error);
    } finally {
      setIsLoadingMachine(false);
    }
  };

  const handleTerminateMachine = async () => {
    // if (machine?.status !== KomodoStatus.Running) {
    //   toast({
    //     title: "Machine cannot be terminated",
    //     description: "The machine is not in terminable state.",
    //     status: "error",
    //     duration: 5000,
    //     isClosable: true,
    //   });
    //   return;
    // }
    const response = await TerminateMachine(machine_id!, user);
    if (response && response.status === 200) {
      toast({
        title: "Machine terminated",
        description: `Machine ${machine_id} has been terminated.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/machines");
    } else {
      toast({
        title: "Error terminating machine",
        description: response
          ? response.data
          : `Machine ${machine_id} could not be terminated.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    // if job is undefined show 404
    // if job is defined show the job details
    <Page overflow={"scroll"}>
      {isLoadingMachine ? (
        <VStack align="center" spacing={4} p={10}>
          <LoadingSpinner size="lg" color="primary.500"/>
          <Text>Loading machine details...</Text>
        </VStack>
      ) : machineNoFound ? (
        <Container maxW="container.xl" p={5}>
          <EmptyState
            colorScheme="red"
            title="404: Machine Not Found"
            description="The machine you are looking for does not exist or may have been removed."
            actions={
              <Button variant={"primary"} onClick={() => navigate("/machines")}>
                Go Back
              </Button>
            }
          />
        </Container>
      ) : !isLoadingMachine && !machineNoFound && machine ? (
        <>
          <PageHeader
            title={
              <>
                <HStack>
                  <Text>{machine.name}</Text>
                  <Badge colorScheme={KomodoStatusColorScheme[machine.status]}>
                    {KomodoStatusToString[machine.status]}
                  </Badge>
                </HStack>
              </>
            }
            nav={
              <BackButton
                onClick={() => {
                  navigate("/machines");
                }}
              />
            }
            toolbar={
              <Toolbar>
                <IconButton
                  icon={<FiRefreshCw />}
                  onClick={refreshMachineDetails}
                  aria-label="Refresh machine"
                />
                <Button
                  variant="secondary"
                  onClick={() => {
                    modals.confirm({
                      title: "Terminate Machine",
                      body: (
                        <VStack align="start" width={"full"} p={5}>
                          <Text>
                            Machine <b>{machine_id}</b>
                          </Text>
                          <Text>
                            Are you sure you want to terminate this machine?
                            This cannot be undone.
                          </Text>
                        </VStack>
                      ),
                      confirmProps: {
                        colorScheme: "red",
                      },
                      onConfirm: () => {
                        handleTerminateMachine();
                      },
                    });
                  }}
                >
                  Terminate
                </Button>
              </Toolbar>
            }
          />
          <Container
            maxWidth="container.xl"
            maxHeight={"100%"}
            width={"100%"}
            p={5}
            fontSize={"md"}
          >
            {/* Top-level job details */}
            <VStack align="stretch" spacing={4}>
              {/* Adjusted top-level job details */}
              <Box p={5} shadow="md" borderWidth="1px">
                <SimpleGrid columns={[1, null, 2]} spacing={10}>
                  <VStack align="start" spacing={4}>
                    {/* Job ID */}
                    <Text fontWeight="bold">
                      Machine ID: <Code>{machine.id}</Code>
                    </Text>
                    {/* Status Message */}
                    {machine.statusMessage && (
                      <Box
                        p={4}
                        borderWidth="1px"
                        borderRadius="lg"
                        borderColor={`${
                          KomodoStatusColorScheme[machine.status]
                        }.500`}
                      >
                        <Badge
                          colorScheme={KomodoStatusColorScheme[machine.status]}
                          borderRadius="full"
                          px={2}
                          py={1}
                          mr={2}
                          fontSize="0.8em" // Adjust font size as needed
                        >
                          {KomodoStatusToString[machine.status]}
                        </Badge>
                        <Text
                          as="span" // Using Text as span allows for inline display with wrapping
                          fontSize="md" // Adjust font size as needed
                        >
                          {machine.statusMessage}
                        </Text>
                      </Box>
                    )}

                    {machine.createdTimestamp && (
                      <VStack align="start" spacing={0}>
                        <Text>
                          Created:{" "}
                          {new Date(
                            machine.createdTimestamp * 1000
                          ).toLocaleString()}
                        </Text>
                        <Text>
                          Updated:{" "}
                          {new Date(
                            machine.updatedTimestamp * 1000
                          ).toLocaleString()}
                        </Text>
                      </VStack>
                    )}
                    {/* Resources Section with aligned labels */}
                    <Box>
                      <Text fontWeight="bold">Resources</Text>
                      <VStack align="start" spacing={1}>
                        {machine.requestedResources?.image_id && (
                          <HStack justify="start" w="100%">
                            <Text minW="82px">Image:</Text>{" "}
                            <Code>{machine.requestedResources?.image_id}</Code>
                          </HStack>
                        )}
                        <HStack justify="start" w="100%">
                          <Text minW="82px">Accelerators:</Text>{" "}
                          {/* Adjust the minW as needed */}
                          <Code>
                            {machine.accelerators
                              ? machine.accelerators
                              : "n/a"}
                          </Code>
                        </HStack>
                        <HStack justify="start" w="100%">
                          <Text minW="82px">{`Disk Size:`}</Text>{" "}
                          {/* Adjust the minW as needed */}
                          <Code>{machine.diskSize}</Code>
                        </HStack>
                      </VStack>
                    </Box>
                    <Networking komodoResource={machine} />
                    <Box>
                      <Text fontWeight={"bold"}>File Mounts</Text>
                      <HStack justify="start" w="100%" align={"top"}>
                        <VStack spacing={1} align="start" marginLeft={"20px"}>
                          {Object.entries(machine.fileMounts).map(
                            ([key, value]) => (
                              <VStack key={key} align="start" spacing={1}>
                                <Code fontWeight="bold">{key}:</Code>
                                <HStack>
                                  <Box minW={"45px"}>
                                    <Text fontSize="sm" as="span">
                                      Source:
                                    </Text>
                                  </Box>
                                  <Code>{value.source}</Code>
                                </HStack>
                                <HStack>
                                  <Box minW={"45px"}>
                                    <Text fontSize="sm" as="span">
                                      Mode:
                                    </Text>
                                  </Box>
                                  <Code>{value.mode}</Code>
                                </HStack>
                              </VStack>
                            )
                          )}
                        </VStack>
                      </HStack>
                    </Box>
                  </VStack>
                  <VStack align="start" spacing={4}>
                    {/* Cloud Details Section with aligned labels */}
                    <Box>
                      <Text fontWeight="bold">Cloud Details</Text>
                      <VStack align="start" spacing={1}>
                        <HStack justify="start" w="100%">
                          <Text minW="92px">Cloud:</Text>{" "}
                          {/* Adjust the minW as needed */}
                          <Code>{machine.cloud ? machine.cloud : "n/a"}</Code>
                        </HStack>
                        <HStack justify="start" w="100%">
                          <Text minW="92px">Instance Type:</Text>{" "}
                          {/* Adjust the minW as needed */}
                          <HStack wrap={"wrap"}>
                            <Code>
                              {machine.instanceType
                                ? machine.instanceType
                                : "n/a"}
                            </Code>
                            <Badge>{machine.spot ? "Spot" : "On-Demand"}</Badge>
                          </HStack>
                        </HStack>
                        <HStack justify="start" w="100%">
                          <Text minW="92px">Region:</Text>{" "}
                          <Code>{machine.region ? machine.region : "n/a"}</Code>
                        </HStack>
                        <HStack justify="start" w="100%">
                          <Text minW="92px">Zone:</Text>{" "}
                          <Code>{machine.zone ? machine.zone : "n/a"}</Code>
                        </HStack>
                      </VStack>
                    </Box>
                    <Box>
                      <HStack justify="start" w="100%" wrap="wrap">
                        <Button
                          onClick={() =>
                            modals.open({
                              title: "Setup Script",
                              body: <ScriptModalBody script={machine.setup} />,
                              contentProps: ScriptModalContentProps,
                            })
                          }
                        >
                          Setup Script
                        </Button>
                        <Button
                          onClick={() =>
                            modals.open({
                              title: "Requested Resources",
                              body: (
                                <RequestedResources
                                  requestedResources={
                                    machine.requestedResources
                                  }
                                />
                              ),
                            })
                          }
                        >
                          Requested Resources
                        </Button>
                        {/* <Button>SSH</Button> */}
                        {/* <Button color={"#007ACC"}>
                          <HStack>
                            <TbBrandVscode size={"1.5em"} />
                            <Text>Open in VSCode</Text>
                          </HStack>
                        </Button> */}
                        <Button
                          color={"#F37626"}
                          onClick={() => {
                            var notebookUrl = machine.notebookUrl ? machine.notebookUrl : "";
                            window.open(notebookUrl, "_blank");
                          }}
                          isDisabled={
                            machine.status !== KomodoStatus.Running ||
                            (!machine.notebookToken && !machine.notebookUrl) ||
                            !machine.sshInfo
                          }
                        >
                          <HStack>
                            <SiJupyter size={"1.5em"} />
                            <Text>Open Notebook</Text>
                          </HStack>
                        </Button>
                      </HStack>
                    </Box>
                  </VStack>
                </SimpleGrid>
              </Box>
              <LogBox
                logBoxType="machine"
                targetId={machine_id!}
                targetObj={machine}
                subtargetIds={null}
              />
            </VStack>
          </Container>
        </>
      ) : null}
    </Page>
  );
};

export default MachineDetails;
