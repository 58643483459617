import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Machine,
  StringToKomodoStatus
} from "../types/types";
import { useCognito } from "./CognitoContext";
import { axios } from "../utils";

type MachinesContextType = {
  machines: Machine[];
  fetchMachines: () => void;
  fetchingMachines: boolean;
};

const MachinesContext = createContext<MachinesContextType | undefined>(
  undefined
);

export const useMachines = () => {
  const context = useContext(MachinesContext);
  if (context === undefined) {
    throw new Error("useMachines must be used within a MachinesProvider");
  }
  return context;
};

const apiResponseToKomodoMachine = (machineData: any): Machine => {
  return {
    id: machineData.id,
    status: StringToKomodoStatus[machineData.status as keyof typeof StringToKomodoStatus],
    statusMessage: machineData.status_message,

    cloud: machineData.cloud,
    region: machineData.region,
    zone: machineData.zone,
    instanceType: machineData.instance_type,
    accelerators: machineData.accelerators,
    ports: machineData.ports,
    diskSize: machineData.disk_size,
    spot: machineData.spot,
    skypilotClusterId: machineData.skypilot_cluster_id,
    sshInfo: machineData.ssh_info,

    name: machineData.name,
    requestedResources: machineData.requested_resources,
    envs: machineData.envs,
    fileMounts: machineData.file_mounts,
    setup: machineData.setup,
    notebookToken: machineData.notebook_token,
    notebookUrl: machineData.notebook_url,

    createdTimestamp: machineData.created_timestamp,
    startedTimestamp: machineData.started_timestamp,
    updatedTimestamp: machineData.updated_timestamp,
    terminatedTimestamp: machineData.finished_timestamp,
  };
}

export const MachinesProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [machines, setMachines] = useState<Machine[]>([]);
  const [fetchingMachines, setFetchingMachines] = useState<boolean>(false);
  const { cognitoLoading, user, isFetchingUserFromDb } = useCognito();

  const fetchMachines = async () => {
    if (cognitoLoading || isFetchingUserFromDb) {
      setFetchingMachines(true);
      return;
    }
    if (!user) {
      return;
    }

    setFetchingMachines(true);
    try {
      const params = { fetch_all: true }
      const response = await axios.get(
        `${process.env.REACT_APP_API_CORE_URL}/api/v1/machines`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.komo_jwt_token}`,
          },
          params: params,
          id: "get-machines",
        }
      );
      const responseBody = response.data;
      if (!responseBody) {
        setFetchingMachines(false);
        return;
      }
      const fetchedMachines: Machine[] = responseBody.map(
        (machineData: any) => {
          return apiResponseToKomodoMachine(machineData);
        }
      );
      if (!response.cached) {
        localStorage.setItem("machines_fetched_at", new Date().toISOString());
      }
      setMachines(fetchedMachines);
      setFetchingMachines(false);
    } catch (error) {
      console.error("Error fetching machines", error);
      setFetchingMachines(false);
    }
  };

  useEffect(() => {
    fetchMachines();
  }, [user, isFetchingUserFromDb]);

  return (
    <MachinesContext.Provider
      value={{ machines, fetchMachines, fetchingMachines }}
    >
      {children}
    </MachinesContext.Provider>
  );
};

export const GetMachineById = async (machineId: string, user: any) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_CORE_URL}/api/v1/machines/${machineId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.komo_jwt_token}`,
        },
        cache: false,
      }
    );
    const responseBody = response.data;
    if (!responseBody) {
      return null;
    }
    const fetchedMachine: Machine = apiResponseToKomodoMachine(responseBody);
    return fetchedMachine;
  } catch (error) {
    console.error("Error fetching machine", error);
    return null;
  }
};

export const TerminateMachine = async (machineId: string, user: any) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_CORE_URL}/api/v1/machines/${machineId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.komo_jwt_token}`,
        },
        validateStatus: (status) => status >= 200 && status < 500, // Only consider 2xx status codes as successful
      }
    );
    return response;
  } catch (error) {
    console.error("Error terminating machine", error);
    return null;
  }
};

export const GetMachineHostInfo = async (machineId: string, user: any) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_CORE_URL}/api/v1/machines/${machineId}/host-info`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.komo_jwt_token}`,
        },
        cache: false,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching machine host info", error);
    return null;
  }
};

export const GetMachinePrivateSshKey = async (user: any) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_CORE_URL}/api/v1/machines/ssh-key`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.komo_jwt_token}`,
        },
        cache: false,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching machine ssh key", error);
    return null;
  }
};

export const StartMachine = async (user: any, payload: any) => {
  // try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_CORE_URL}/api/v1/machines`,
      payload,
      {
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${user?.komo_jwt_token}`,
        },
      }
    );
    return response;
  // } catch (error) {
    // console.error("Error starting machine", error);
    // return null;
  // }
};

export const GetUploadInfo= async (user: any) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_CORE_URL}/api/v1/jobs/upload_info`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.komo_jwt_token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching upload info", error);
    return null;
  }
}

export const UploadWorkingDirectory = async (user: any, workdirZipfile: Blob, uploadUrl: string, fields: Record<string, string>) => {
  const formData = new FormData();
  
  Object.keys(fields).forEach(key => {
    formData.append(key, fields[key]);
  });

  formData.append("file", workdirZipfile);

  try {
    const response = await axios.post(
      uploadUrl,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error uploading working directory", error);
    return null;
  }
}

export const GetMachineLogs = async (machineId: string, user: any, startTime: number | null, endTime: number | null, nextToken: string | null) : Promise<any | null> => {
  try {
    var params: { next_token: any; start_time?: any, end_time?: any } = { next_token: null };
    if (startTime) {
      params.start_time = Math.floor(startTime);
    }
    if (nextToken) {
      params.next_token = nextToken;
    }
    if (endTime) {
      params.end_time = endTime;
    }

    const response = await axios.get(
      `${process.env.REACT_APP_API_CORE_URL}/api/v1/machines/${machineId}/setup_logs`,
      {
        headers: {
          Authorization: `Bearer ${user?.komo_jwt_token}`,
        },
        params: params,
        validateStatus: (status) => status >= 200 && status < 500, // Only consider 2xx status codes as successful
        cache: false,
      }
    );
    if (response.status !== 200) {
      console.log(`Error fetching machine ${machineId} logs`, response);
      return null;
    }
    return response.data;
  } catch (error) {
    console.error("Error fetching machine logs", error);
    return null;
  }
};
