import { Badge, Skeleton } from "@chakra-ui/react";
import { ColumnDef } from "@saas-ui-pro/react";
import React, { useState, useEffect } from "react";
import { useServices } from "../providers/ServicesContext";
import {
  DisplayKomodoService,
  KomodoService,
  KomodoServiceStatusColorScheme,
  KomodoServiceStatusToString,
} from "../types/types";
import DataGridPage from "./DataGridPage";
import { StyledPageWrapper } from "./common";
import { useCognito } from "../providers/CognitoContext";
import AccountNotApprovedBanner from "./AccountNotApprovedBanner";

const displayColumns: ColumnDef<DisplayKomodoService, any>[] = [
  {
    id: "name",
    header: "Name",
    accessorKey: "name",
  },
  {
    id: "status",
    header: "Status",
    accessorKey: "status",
  },
  { id: "created", header: "Created", accessorKey: "created" },
  // { id: "cloud", header: "Cloud", accessorKey: "cloud" },
  // { id: "region", header: "Region", accessorKey: "region" },
  // { id: "duration", header: "Duration", accessorKey: "duration" },
];

const Services: React.FC = () => {
  const { services, fetchServices, fetchingServices } = useServices();
  // const { connectedClouds } = useMetadata();
  const { user } = useCognito();
  const [initialLoad, setInitialLoad] = useState(true);
  // const [selections, setSelections] = React.useState<string[]>([]);

  // convert services to display services
  const displayServices: DisplayKomodoService[] = services.map(
    (service: KomodoService) => {
      return {
        id: service.id,
        name: service.name,
        status: (
          <Badge colorScheme={KomodoServiceStatusColorScheme[service.status]}>
            {KomodoServiceStatusToString[service.status]}
          </Badge>
        ),
        // cloud: service.cloud,
        // region: service.region,
        created: new Date(service.createdTimestamp * 1000).toLocaleString(),
      };
    }
  );

  useEffect(() => {
    if (!fetchingServices && initialLoad) {
      setInitialLoad(false);
    }
  }, [fetchingServices]);

  const tableData = React.useMemo(
    () =>
      fetchingServices || initialLoad ? Array(15).fill({}) : displayServices,
    [fetchingServices, initialLoad, displayServices]
  );
  const tableColumns = React.useMemo(
    () =>
      fetchingServices || initialLoad
        ? [
            {
              id: "Loading...",
              cell: () => (
                <Skeleton height="20px" width="100%" borderRadius={"md"} />
              ),
            },
          ]
        : displayColumns,
    [fetchingServices, initialLoad]
  );

  return (
    <StyledPageWrapper>
      <DataGridPage
        title="Services"
        description="Easily deploy and autoscale AI models."
        columns={tableColumns}
        data={tableData}
        fetching={fetchingServices || initialLoad}
        fetchData={fetchServices}
        emptyStateTitle="No services yet"
        emptyStateDescription="You haven't created any services yet."
        createLink="/services/new"
        createButtonText="Launch service"
        detailsBasePath="services"
      />
    </StyledPageWrapper>
  );
};

export default Services;
