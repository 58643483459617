import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { PlanType, Team, TeamInvitation } from "../types/types";
import { useCognito, User } from "./CognitoContext";
import {axios} from "../utils";

export interface GetSubscriptionResponse {
  subscription_id: string;
  seats: number;
  subscription_status: string;
  current_period_end: number;
  plan: PlanType;
  balance: number;
}

export interface CardDetails {
  brand: string;
  last4: string;
  exp_month: string;
  exp_year: string;
  cvc?: string;
}

export interface LinkDetails {
  email: string;
}

export interface PaymentMethod {
  id: string;
  type: 'card' | 'link';
  is_default: boolean;
  card?: CardDetails;
  link?: LinkDetails;
}

export interface GetPaymentMethodsResponse {
  data: PaymentMethod[];
}

type BillingContextType = {
  subscription: GetSubscriptionResponse | null;
  paymentMethods: GetPaymentMethodsResponse | undefined;
  fetchSubscription: () => Promise<void>;
  fetchPaymentMethods: () => Promise<GetPaymentMethodsResponse | undefined>;
  fetchBillingData: () => Promise<void>;
  isFetchingSubscription: boolean;
  isFetchingPaymentMethods: boolean;
  isFetchingBilling: boolean;
};

const SubscriptionContext = createContext<BillingContextType | undefined>(
  undefined
);

export const useBilling = () => {
  const context = useContext(SubscriptionContext);
  if (context === undefined) {
    throw new Error("useBilling must be used within a BillingProvider");
  }
  return context;
};

export const BillingProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [subscription, setSubscription] =
    useState<GetSubscriptionResponse | null>(null);
  const [paymentMethods, setPaymentMethods] = useState<GetPaymentMethodsResponse | undefined>(
    undefined
  );
  const { cognitoLoading, user, isFetchingUserFromDb } = useCognito();
  const [isFetchingSubscription, setIsFetchingSubscription] =
    useState<boolean>(true);
  const [isFetchingPaymentMethods, setIsFetchingPaymentMethods] =
    useState<boolean>(true);
  const [isFetchingBilling, setIsFetchingBilling] = useState<boolean>(true);

  const fetchSubscription = async () => {
    if (!user) {
      return;
    }
    setIsFetchingSubscription(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_CORE_URL}/api/v1/payments/subscription`,
        {
          headers: {
            Authorization: `Bearer ${user?.komo_jwt_token}`,
          },
          id: "get-subscription",
        }
      );
      if (response.status === 200) {
        setSubscription(response.data);
      }
    } catch (error: any) {
      console.error("Error fetching subscription", error);
      setSubscription(null);
    } finally {
      setIsFetchingSubscription(false);
    }
  };

  const fetchPaymentMethods = async (): Promise<GetPaymentMethodsResponse | undefined> => {
    if (!user) {
      return;
    }
    setIsFetchingPaymentMethods(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_CORE_URL}/api/v1/payments/payment-methods`,
        {
          headers: {
            Authorization: `Bearer ${user?.komo_jwt_token}`,
          },
          id: "get-payment-methods",
        }
      );
      if (response.status === 200) {
        setPaymentMethods(response.data);
        return response.data;
      } else {
        setPaymentMethods(undefined);
        return undefined;
      }
    } catch (error: any) {
      console.error("Error fetching payment methods", error);
      setPaymentMethods(undefined);
      return undefined;
    } finally {
      setIsFetchingPaymentMethods(false);
    }
  };

  const fetchBillingData = async () => {
    setIsFetchingBilling(true);
    await fetchSubscription();
    await fetchPaymentMethods();
    setIsFetchingBilling(false);
  };

  useEffect(() => {
    if (!cognitoLoading && !isFetchingUserFromDb) {
      fetchBillingData();
    }
  }, [cognitoLoading, isFetchingUserFromDb]);

  return (
    <SubscriptionContext.Provider
      value={{
        subscription,
        paymentMethods,
        fetchSubscription,
        fetchPaymentMethods,
        fetchBillingData,
        isFetchingSubscription,
        isFetchingPaymentMethods,
        isFetchingBilling,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export const CancelSubscription = async (user: User) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_CORE_URL}/api/v1/payments/cancel-subscription`,
      {},
      {
        headers: {
          Authorization: `Bearer ${user!.komo_jwt_token}`,
        },
        cache: {
          update: {
            "get-subscription": "delete",
          },
        },
      }
    );
    return response;
  } catch (error: any) {
    console.error("Error cancelling subscription", error);
    throw error;
  }
};

export const UpdateSubscription = async (user: User, seats: number) => {
  try {
    const payload = {
      seats: seats,
      customer_email: user!.email,
    };
    const _ = await axios.post(
      `${process.env.REACT_APP_API_CORE_URL}/api/v1/payments/update-subscription`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${user!.komo_jwt_token}`,
        },
        cache: {
          update: {
            "get-subscription": "delete",
          },
        },
      }
    );
  } catch (error: any) {
    console.error("Error updating subscription", error);
    throw error;
  }
};
