
interface Cloud {
  // Define properties based on the Python Cloud model
  provider: string;
  region: string;
}

interface Log {
  timestamp: number;
  replicaId?: number;
  message: string;
}

export type {Log};

export type GetLogsResponse = {
  logs: Log[];
  next_token: string;
};

export type GetServiceLogsResponse = {
  [replicaId: number]: GetLogsResponse;
};

export type GetJobLogsResponse = {
  [nodeIndex: number]: GetLogsResponse;
};

enum KomodoStatus {
  Pending,
  Initializing,
  RunningSetup,
  Running,
  ShuttingDown,
  Finished,
  Cancelling,
  Cancelled,
  Error,
  Unknown,
  NotFound,
  Unauthorized,
  Unreachable,
  Stopping,
  Stopped,
  Terminated,
  Terminating,
}

const StringToKomodoStatus = {
  "pending": KomodoStatus.Pending,
  "initializing": KomodoStatus.Initializing,
  "running_setup": KomodoStatus.RunningSetup,
  "running": KomodoStatus.Running,
  "shutting_down": KomodoStatus.ShuttingDown,
  "finished": KomodoStatus.Finished,
  "cancelling": KomodoStatus.Cancelling,
  "cancelled": KomodoStatus.Cancelled,
  "error": KomodoStatus.Error,
  "unreachable": KomodoStatus.Unreachable,
  "not found": KomodoStatus.NotFound,
  "unauthorized": KomodoStatus.Unauthorized,
  "unknown": KomodoStatus.Unknown,
  "stopping": KomodoStatus.Stopping,
  "stopped": KomodoStatus.Stopped,
  "terminated": KomodoStatus.Terminated,
  "terminating": KomodoStatus.Terminating,
};

const IntegerToKomodoStatus = {
  0: KomodoStatus.Pending,
  1: KomodoStatus.Initializing,
  2: KomodoStatus.RunningSetup,
  3: KomodoStatus.Running,
  4: KomodoStatus.ShuttingDown,
  5: KomodoStatus.Finished,
  6: KomodoStatus.Cancelling,
  7: KomodoStatus.Cancelled,
  8: KomodoStatus.Error,
  9: KomodoStatus.Unreachable,
  10: KomodoStatus.NotFound,
  11: KomodoStatus.Unauthorized,
  12: KomodoStatus.Unknown,
  13: KomodoStatus.Stopping,
  14: KomodoStatus.Stopped,
  15: KomodoStatus.Terminated,
  16: KomodoStatus.Terminating,
};

const KomodoStatusToString = {
  [KomodoStatus.Pending]: "Pending",
  [KomodoStatus.Initializing]: "Initializing",
  [KomodoStatus.RunningSetup]: "Running Setup",
  [KomodoStatus.Running]: "Running",
  [KomodoStatus.ShuttingDown]: "Shutting Down",
  [KomodoStatus.Finished]: "Finished",
  [KomodoStatus.Cancelling]: "Cancelling",
  [KomodoStatus.Cancelled]: "Cancelled",
  [KomodoStatus.Error]: "Error",
  [KomodoStatus.Unreachable]: "Unreachable",
  [KomodoStatus.NotFound]: "Not Found",
  [KomodoStatus.Unauthorized]: "Unauthorized",
  [KomodoStatus.Unknown]: "Unknown",
  [KomodoStatus.Stopping]: "Stopping",
  [KomodoStatus.Stopped]: "Stopped",
  [KomodoStatus.Terminated]: "Terminated",
  [KomodoStatus.Terminating]: "Terminating",
};

interface Port {
  port: number;
  endpoint: string;
}

export type { Port };

interface SSHInfo {
  role: string;
  ip_address: string;
  ssh_port: number;
  ssh_user: string;
}

export type { SSHInfo };

interface Job {
  id: string;
  status: KomodoStatus;
  statusMessage: string;
  cloud: string;
  region: string | null;
  zone: string | null;
  instanceType: string | null;
  accelerators: string | null;
  ports: Port[] | null;
  diskSize: number | null;
  spot: boolean | null;
  skyPilotClusterId: string;
  sshInfo: SSHInfo[] | null;

  name?: string;
  numNodes: number;
  requestedResources: Record<string, any>;
  envs: Record<string, any>;
  fileMounts: Record<string, any>;
  setup: string;
  run: string;

  createdTimestamp: number
  startedTimestamp?: number;
  updatedTimestamp: number;
  finishedTimestamp?: number;
}

interface DisplayJob {
  id: string;
  name?: string;
  created: string;
  // duration: string;
  status: JSX.Element;
  cloud: string;
  region: string | null;
};


const KomodoStatusColorScheme = {
  [KomodoStatus.Pending]: "purple", // Awaiting action, purple suggests something in progress or awaiting
  [KomodoStatus.Initializing]: "yellow", // Beginning or startup phase, yellow for a cautionary state of initialization
  [KomodoStatus.RunningSetup]: "yellow", // Setup phase, yellow to indicate a different phase from Initializing
  [KomodoStatus.Running]: "blue", // Active process, blue is commonly associated with ongoing processes
  [KomodoStatus.ShuttingDown]: "orange", // Process is stopping, orange to indicate a transition or action required
  [KomodoStatus.Finished]: "green", // Completion, success, green universally signifies success
  [KomodoStatus.Cancelling]: "orange", // Process in the act of stopping by user command, orange again for action required but different context from ShuttingDown
  [KomodoStatus.Cancelled]: "teal", // Stopped by the user, teal for a neutral but distinct completion state
  [KomodoStatus.Error]: "red", // Error, attention needed, red for errors and problems
  [KomodoStatus.Unreachable]: "gray", // Temporary communication issue, gray to indicate uncertainty or unavailability
  [KomodoStatus.NotFound]: "gray", // Neutral, not found, similar reasoning as Unreachable
  [KomodoStatus.Unauthorized]: "pink", // Issue with permissions, pink to stand out and indicate an issue without implying error severity like red would
  [KomodoStatus.Unknown]: "gray", // Uncertainty or undefined state, gray for undefined, unknown, or neutral statuses
  [KomodoStatus.Stopping]: "orange", // In the act of stopping but hasn’t completed yet, orange again for ongoing action
  [KomodoStatus.Stopped]: "gray", // No longer running, gray for a neutral state indicating inactivity
  [KomodoStatus.Terminated]: "red", // Forceful stop or end, red to indicate a severe or forced stop
  [KomodoStatus.Terminating]: "red", // In the act of being forcefully ended, red to align with Terminated but indicating the process is ongoing
};


export { KomodoStatus, KomodoStatusColorScheme, StringToKomodoStatus, IntegerToKomodoStatus, KomodoStatusToString };

export type { Cloud, DisplayJob, Job };

interface Machine {
  id: string;
  status: KomodoStatus;
  statusMessage: string;

  cloud: string;
  region: string | null;
  zone: string | null;
  instanceType: string | null;
  accelerators: string | null;
  ports: Port[] | null;
  diskSize: number | null;
  spot: boolean | null;
  skypilotClusterId: string;
  sshInfo: SSHInfo | null;

  name: string;
  requestedResources: Record<string, any>;
  envs: Record<string, any>;
  fileMounts: Record<string, any>;
  setup: string;
  notebookToken: string | null;
  notebookUrl: string | null;

  createdTimestamp: number;
  startedTimestamp?: number;
  updatedTimestamp: number;
  terminatedTimestamp?: number;
  // Set from get machine host info
  // hostName?: string;
  // userName?: string;
}

// Define the structure for displayable machine data if needed
interface DisplayMachine {
  id: string;
  name: string;
  created: string;
  status: JSX.Element; // For rendering a badge or similar component
  cloud: string;
  region: string;
}

export type { DisplayMachine, Machine };

interface EnvVar {
  key: string;
  value: string;
}

interface ResourceParams {
  cloud?: string;
  region?: string;
  zone?: string;
  accelerators?: string;
  cpus?: string;
  memory?: string;
  instance_type?: string;
  use_spot?: boolean;
  job_recovery?: string;
  disk_size?: number; // gb
  disk_tier?: string;
  ports?: string[];
  accelerator_args?: Record<string, any>;
  image_id?: string;
  labels?: Record<string, any>;
  any_of?: Record<string, any>[];
}

interface CreateJobPayload {
  name?: string;
  workdir_upload_id?: string;
  num_nodes?: string;
  resources?: ResourceParams;
  envs?: EnvVar[];
  file_mounts: Record<string, any>;
  setup?: string;
  run?: string;
}

interface CreateMachinePayload {
  name: string;
  notebook: boolean;

  workdir_upload_id?: string;
  resources: ResourceParams;
  envs?: EnvVar[];
  file_mounts?: Record<string, any>;
  setup?: string;

  docker_image?: string;
}

export type { EnvVar, CreateJobPayload, CreateMachinePayload }

enum KomodoServiceStatus {
    CONTROLLER_INIT = "CONTROLLER_INIT",
    REPLICA_INIT = "REPLICA_INIT",
    CONTROLLER_FAILED = "CONTROLLER_FAILED",
    READY = "READY",
    SHUTTING_DOWN = "SHUTTING_DOWN",
    FAILED = "FAILED",
    FAILED_CLEANUP = "FAILED_CLEANUP",
    NO_REPLICA = "NO_REPLICA",
    DELETED = "DELETED",
    UNAUTHORIZED = "unauthorized",
    NOT_FOUND = "not found",
    UNKNOWN = "unknown"
}

const StringToKomodoServiceStatus = {
  "CONTROLLER_INIT": KomodoServiceStatus.CONTROLLER_INIT,
  "REPLICA_INIT": KomodoServiceStatus.REPLICA_INIT,
  "CONTROLLER_FAILED": KomodoServiceStatus.CONTROLLER_FAILED,
  "READY": KomodoServiceStatus.READY,
  "SHUTTING_DOWN": KomodoServiceStatus.SHUTTING_DOWN,
  "FAILED": KomodoServiceStatus.FAILED,
  "FAILED_CLEANUP": KomodoServiceStatus.FAILED_CLEANUP,
  "NO_REPLICA": KomodoServiceStatus.NO_REPLICA,
  "DELETED": KomodoServiceStatus.DELETED,
  "unauthorized": KomodoServiceStatus.UNAUTHORIZED,
  "not found": KomodoServiceStatus.NOT_FOUND,
  "unknown": KomodoServiceStatus.UNKNOWN,
};

const IntegerToKomodoServiceStatus = {
  0: KomodoServiceStatus.CONTROLLER_INIT,
  1: KomodoServiceStatus.REPLICA_INIT,
  2: KomodoServiceStatus.CONTROLLER_FAILED,
  3: KomodoServiceStatus.READY,
  4: KomodoServiceStatus.SHUTTING_DOWN,
  5: KomodoServiceStatus.FAILED,
  6: KomodoServiceStatus.FAILED_CLEANUP,
  7: KomodoServiceStatus.NO_REPLICA,
  8: KomodoServiceStatus.DELETED,
  9: KomodoServiceStatus.UNAUTHORIZED,
  10: KomodoServiceStatus.NOT_FOUND,
  11: KomodoServiceStatus.UNKNOWN,
};

const KomodoServiceStatusToString = {
  [KomodoServiceStatus.CONTROLLER_INIT]: "Controller Initializing",
  [KomodoServiceStatus.REPLICA_INIT]: "Replica Initializing",
  [KomodoServiceStatus.CONTROLLER_FAILED]: "Controller Failed",
  [KomodoServiceStatus.READY]: "Ready",
  [KomodoServiceStatus.SHUTTING_DOWN]: "Shutting Down",
  [KomodoServiceStatus.FAILED]: "Failed",
  [KomodoServiceStatus.FAILED_CLEANUP]: "Failed Cleanup",
  [KomodoServiceStatus.NO_REPLICA]: "No Replica",
  [KomodoServiceStatus.DELETED]: "Deleted",
  [KomodoServiceStatus.UNAUTHORIZED]: "Unauthorized",
  [KomodoServiceStatus.NOT_FOUND]: "Not Found",
  [KomodoServiceStatus.UNKNOWN]: "Unknown",
};

const KomodoServiceStatusColorScheme = {
  [KomodoServiceStatus.CONTROLLER_INIT]: "yellow",
  [KomodoServiceStatus.REPLICA_INIT]: "yellow",
  [KomodoServiceStatus.CONTROLLER_FAILED]: "red",
  [KomodoServiceStatus.READY]: "green",
  [KomodoServiceStatus.SHUTTING_DOWN]: "orange",
  [KomodoServiceStatus.FAILED]: "red",
  [KomodoServiceStatus.FAILED_CLEANUP]: "red",
  [KomodoServiceStatus.NO_REPLICA]: "gray",
  [KomodoServiceStatus.DELETED]: "gray",
  [KomodoServiceStatus.UNAUTHORIZED]: "pink",
  [KomodoServiceStatus.NOT_FOUND]: "gray",
  [KomodoServiceStatus.UNKNOWN]: "gray",
};

enum KomodoServiceReplicaStatus {
    PENDING = "PENDING",
    PROVISIONING = "PROVISIONING",
    STARTING = "STARTING",
    READY = "READY",
    NOT_READY = "NOT_READY",
    SHUTTING_DOWN = "SHUTTING_DOWN",
    FAILED = "FAILED",
    FAILED_INITIAL_DELAY = "FAILED_INITIAL_DELAY",
    FAILED_PROBING = "FAILED_PROBING",
    FAILED_PROVISION = "FAILED_PROVISION",
    FAILED_CLEANUP = "FAILED_CLEANUP",
    PREEMPTED = "PREEMPTED",
    UNKNOWN = "UNKNOWN",
    DELETED = "DELETED",
};

const KomodoServiceReplicaStatusColorScheme = {
  [KomodoServiceReplicaStatus.PENDING]: "purple",
  [KomodoServiceReplicaStatus.PROVISIONING]: "yellow",
  [KomodoServiceReplicaStatus.STARTING]: "yellow",
  [KomodoServiceReplicaStatus.READY]: "green",
  [KomodoServiceReplicaStatus.NOT_READY]: "red",
  [KomodoServiceReplicaStatus.SHUTTING_DOWN]: "orange",
  [KomodoServiceReplicaStatus.FAILED]: "red",
  [KomodoServiceReplicaStatus.FAILED_INITIAL_DELAY]: "red",
  [KomodoServiceReplicaStatus.FAILED_PROBING]: "red",
  [KomodoServiceReplicaStatus.FAILED_PROVISION]: "red",
  [KomodoServiceReplicaStatus.FAILED_CLEANUP]: "red",
  [KomodoServiceReplicaStatus.PREEMPTED]: "red",
  [KomodoServiceReplicaStatus.UNKNOWN]: "gray",
  [KomodoServiceReplicaStatus.DELETED]: "gray",
};

const StringToKomodoServiceReplicaStatus = {
  "PENDING": KomodoServiceReplicaStatus.PENDING,
  "PROVISIONING": KomodoServiceReplicaStatus.PROVISIONING,
  "STARTING": KomodoServiceReplicaStatus.STARTING,
  "READY": KomodoServiceReplicaStatus.READY,
  "NOT_READY": KomodoServiceReplicaStatus.NOT_READY,
  "SHUTTING_DOWN": KomodoServiceReplicaStatus.SHUTTING_DOWN,
  "FAILED": KomodoServiceReplicaStatus.FAILED,
  "FAILED_INITIAL_DELAY": KomodoServiceReplicaStatus.FAILED_INITIAL_DELAY,
  "FAILED_PROBING": KomodoServiceReplicaStatus.FAILED_PROBING,
  "FAILED_PROVISION": KomodoServiceReplicaStatus.FAILED_PROVISION,
  "FAILED_CLEANUP": KomodoServiceReplicaStatus.FAILED_CLEANUP,
  "PREEMPTED": KomodoServiceReplicaStatus.PREEMPTED,
  "UNKNOWN": KomodoServiceReplicaStatus.UNKNOWN,
  "DELETED": KomodoServiceReplicaStatus.DELETED,
};

const IntegerToKomodoServiceReplicaStatus = {
  0: KomodoServiceReplicaStatus.PENDING,
  1: KomodoServiceReplicaStatus.PROVISIONING,
  2: KomodoServiceReplicaStatus.STARTING,
  3: KomodoServiceReplicaStatus.READY,
  4: KomodoServiceReplicaStatus.NOT_READY,
  5: KomodoServiceReplicaStatus.SHUTTING_DOWN,
  6: KomodoServiceReplicaStatus.FAILED,
  7: KomodoServiceReplicaStatus.FAILED_INITIAL_DELAY,
  8: KomodoServiceReplicaStatus.FAILED_PROBING,
  9: KomodoServiceReplicaStatus.FAILED_PROVISION,
  10: KomodoServiceReplicaStatus.FAILED_CLEANUP,
  11: KomodoServiceReplicaStatus.PREEMPTED,
  12: KomodoServiceReplicaStatus.UNKNOWN,
  13: KomodoServiceReplicaStatus.DELETED,
};

const KomodoServiceReplicaStatusToString = {
  [KomodoServiceReplicaStatus.PENDING]: "Pending",
  [KomodoServiceReplicaStatus.PROVISIONING]: "Provisioning",
  [KomodoServiceReplicaStatus.STARTING]: "Starting",
  [KomodoServiceReplicaStatus.READY]: "Ready",
  [KomodoServiceReplicaStatus.NOT_READY]: "Not Ready",
  [KomodoServiceReplicaStatus.SHUTTING_DOWN]: "Shutting Down",
  [KomodoServiceReplicaStatus.FAILED]: "Failed",
  [KomodoServiceReplicaStatus.FAILED_INITIAL_DELAY]: "Failed Initial Delay",
  [KomodoServiceReplicaStatus.FAILED_PROBING]: "Failed Probing",
  [KomodoServiceReplicaStatus.FAILED_PROVISION]: "Failed Provision",
  [KomodoServiceReplicaStatus.FAILED_CLEANUP]: "Failed Cleanup",
  [KomodoServiceReplicaStatus.PREEMPTED]: "Preempted",
  [KomodoServiceReplicaStatus.UNKNOWN]: "Unknown",
  [KomodoServiceReplicaStatus.DELETED]: "Deleted",
};

interface KomodoServiceReadinessProbe {
  path: string;
  initialDelaySeconds?: number | null;
  postData?: Record<string, any>;
}

interface KomodoServiceReplicaPolicy {
  minReplicas: number;
  maxReplicas?: number | null;
  targetQpsPerReplica?: number | null;
  // dynamicOnDemandFallback?: boolean | null;
  // baseOnDemandFallbackReplicas?: number | null;
  upscaleDelaySeconds?: number | null;
  downscaleDelaySeconds?: number | null;
}

interface ServiceSection {
  readinessProbe: KomodoServiceReadinessProbe;
  replicaPolicy: KomodoServiceReplicaPolicy;
}

interface KomodoService {
    id: string;
    status: KomodoServiceStatus;
    statusMessage: string;

    name: string;
    numNodes: number;
    requestedResources: Record<string, any>;
    envs: Record<string, any>;
    fileMounts: Record<string, any>;
    setup: string;
    run: string;
    service: ServiceSection;

    uptime: number;
    // TODO: add back by querying version specs
    // currentVersion: number
    activeVersions: number[];

    createdTimestamp: number;
    updatedTimestamp: number;
    url: string;
}

interface KomodoServiceReplica {
  serviceId: string;
  replicaId: number;
  version: number;
  status: KomodoServiceReplicaStatus;

  cloud: string;
  region: string | null;
  zone: string | null;
  instanceType: string | null;
  accelerators: string | null;
  ports: Port[] | null;
  diskSize: number | null;
  spot: boolean | null;
  skyPilotClusterId: string;
  sshInfo: SSHInfo | null;

  createdTimestamp: number;
  updatedTimestamp: number;
}

interface KomodoServiceReplicaList {
  replicas: KomodoServiceReplica[];
}

interface DisplayKomodoService {
    id: string;
    name: string;
    created: string;
    status: JSX.Element;
    // cloud: string;
    // region: string;
}

interface CreateKomodoServicePayload {
    name?: string | null;
    workdir_upload_id?: string | null;
    setup_script: string;
    run_script: string;
    env: EnvVar[];
    resources: ResourceParams;
    storage: Record<string, any>;
    cloud: string;
    docker_image?: string | null;

    readiness_path: string;
    initial_delay_seconds?: number | null;
    post_data?: Record<string, any>;

    min_replicas: number;
    max_replicas?: number | null;
    target_qps_per_replica?: number | null;
    dynamic_ondemand_fallback?: boolean | null;
    base_ondemand_fallback_replicas?: number | null;
    upscale_delay_seconds?: number | null;
    downscale_delay_seconds?: number | null;

    controller_port?: number | null;
    load_balancer_port?: number | null;
}

export type { KomodoService, DisplayKomodoService, CreateKomodoServicePayload, KomodoServiceReplicaList, KomodoServiceReplica, ServiceSection, KomodoServiceReadinessProbe, KomodoServiceReplicaPolicy};
export { KomodoServiceStatus, KomodoServiceReplicaStatus, StringToKomodoServiceStatus, IntegerToKomodoServiceStatus, KomodoServiceStatusToString, StringToKomodoServiceReplicaStatus, IntegerToKomodoServiceReplicaStatus, KomodoServiceReplicaStatusToString, KomodoServiceStatusColorScheme, KomodoServiceReplicaStatusColorScheme };

export interface TeamMember {
  user_id: string;
  email: string;
  role: string;
  pending_invitation: boolean;
}

export enum PaymentValidationStatus {
  NO_CARD = "no_card",
  PENDING_VALIDATION = "pending_validation",
  VALID = "valid",
  REQUIRES_AUTHENTICATION = "requires_authentication",
  FAILED = "failed",
}

export interface Team {
  team_id: string;
  team_name: string;
  members: TeamMember[];
  stripe_customer_id: string;
  stripe_subscription_id: string;
  payment_validation_status: PaymentValidationStatus;
}

export interface PricingCardProps {
  plan: string;
  description: string;
  price: string;
  features: string[];
  buttonText: string;
  team: Team;
}

export interface TeamInvitation {
  invitation_id: string;
  team_id: string;
  team_name: string;
  email: string;
  invited_by: string;
  invited_by_email: string;
  role: string;
  invitation_status: string;
  invited_at: string;
  accepted_at?: string;
}

export enum PlanType {
  FREE = "free",
  PRO = "pro",
  ENTERPRISE = "enterprise",
  POC = "poc",
  NULL = "null",
}
