import { CopyIcon, DownloadIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Grid,
  Heading,
  HStack,
  ListItem,
  OrderedList,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useColorModeValue,
  Divider,
} from "@chakra-ui/react";
import { Page, PageHeader } from "@saas-ui-pro/react";
import React, { useMemo, useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  oneDark,
  oneLight,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import { generateYamlFromConfig } from "../utils/yamlGenerator";
import DeploymentForm from "./DeploymentForm";
import { Recipe, RecipeCard, recipes, RecipeType } from "./Recipe";

const StudioPage: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedRecipe, setSelectedRecipe] = useState<Recipe | null>(null);
  const syntaxHighlightTheme = useColorModeValue(oneLight, oneDark);
  const commandBackgroundColor = useColorModeValue("gray.800", "gray.900");

  const generatedYaml = useMemo(() => {
    if (selectedRecipe) {
      return generateYamlFromConfig(selectedRecipe.serviceConfig);
    }
    return "";
  }, [selectedRecipe]);

  const handleCardClick = (recipe: Recipe) => {
    setSelectedRecipe(recipe);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedRecipe(null);
  };

  const downloadYaml = () => {
    if (!selectedRecipe) return;
    const blob = new Blob([generatedYaml], { type: "text/yaml" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${selectedRecipe.yamlFileName}`;
    link.click();
  };

  const copyToClipboard = () => {
    if (!selectedRecipe) return;
    navigator.clipboard.writeText(generatedYaml);
  };

  const copyCommandToClipboard = () => {
    if (!selectedRecipe) return;
    const commandWithoutDollar = selectedRecipe.command
      .replace(/^\$/, "")
      .trim();
    navigator.clipboard.writeText(commandWithoutDollar);
  };

  const copyUsageCommandToClipboard = () => {
    if (!selectedRecipe || !selectedRecipe.usageCommand) return;
    navigator.clipboard.writeText(selectedRecipe.usageCommand);
  };

  const modelRecipes = useMemo(
    () => recipes.filter((recipe) => recipe.type === RecipeType.Model),
    []
  );
  const fineTuningRecipes = useMemo(
    () => recipes.filter((recipe) => recipe.type === RecipeType.FineTuning),
    []
  );

  return (
    <Page overflow="scroll">
      <PageHeader
        title="AI Studio"
        description="Deploy and fine-tune state-of-the-art AI models with ease. Choose from pre-configured models or customize your own to bring cutting-edge AI to your projects."
      />
      <Box
        borderRadius="xl"
        borderWidth={"1px"}
        my={5}
        mx={5}
        px={4}
        py={4}
        alignContent={"start"}
        justifyContent={"center"}
        overflow={"auto"}
        borderColor={"transparent"}
      >
        <Heading as="h2" size="lg" mb={2}>
          Models
        </Heading>
        <Text mb={4} fontSize="md" color="gray.600">
          Ready-to-deploy AI models. Select any model below for instant 1-click
          deployment to your Komodo environment.
        </Text>
        <Grid
          templateColumns="repeat(auto-fill, minmax(300px, 1fr))"
          gap={6}
          mb={8}
        >
          {modelRecipes.map((recipe, index) => (
            <RecipeCard
              key={index}
              {...recipe}
              onClick={() => handleCardClick(recipe)}
            />
          ))}
        </Grid>

        <Divider my={8} borderColor="gray.300" />

        <Heading as="h2" size="lg" mb={4}>
          Fine-Tuning Recipes
        </Heading>
        <Grid templateColumns="repeat(auto-fill, minmax(300px, 1fr))" gap={6}>
          {fineTuningRecipes.map((recipe, index) => (
            <RecipeCard
              key={index}
              {...recipe}
              onClick={() => handleCardClick(recipe)}
            />
          ))}
        </Grid>
      </Box>

      {selectedRecipe && (
        <Drawer
          isOpen={isDrawerOpen}
          placement="right"
          onClose={closeDrawer}
          size="lg"
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>{selectedRecipe.title}</DrawerHeader>

            <DrawerBody>
              <Tabs>
                <TabList>
                  {selectedRecipe.type === RecipeType.Model && (
                    <Tab>1-Click Deploy</Tab>
                  )}
                  <Tab>Manual Deploy</Tab>
                  <Tab>Usage</Tab>
                </TabList>

                <TabPanels>
                  {selectedRecipe.type === RecipeType.Model && (
                    <TabPanel>
                      <DeploymentForm
                        recipe={selectedRecipe}
                        onClose={closeDrawer}
                      />
                    </TabPanel>
                  )}
                  <TabPanel>
                    <Box position="relative" fontSize={"md"}>
                      <Text mb={4}>
                        Follow these steps to use the YAML file:
                      </Text>
                      <OrderedList mb={4}>
                        {selectedRecipe.steps.map((step, index) => (
                          <ListItem key={index}>{step}</ListItem>
                        ))}
                      </OrderedList>

                      <Box
                        position="relative"
                        fontSize={"sm"}
                        overflow={"scroll"}
                      >
                        <SyntaxHighlighter
                          language="yaml"
                          style={syntaxHighlightTheme}
                          showLineNumbers
                        >
                          {generatedYaml}
                        </SyntaxHighlighter>

                        <HStack
                          position="absolute"
                          top="10px"
                          right="10px"
                          spacing={2}
                        >
                          <Tooltip label="Copy to clipboard" hasArrow>
                            <Button
                              aria-label="Copy YAML"
                              onClick={copyToClipboard}
                            >
                              <CopyIcon />
                            </Button>
                          </Tooltip>
                          <Tooltip label="Download YAML" hasArrow>
                            <Button
                              aria-label="Download YAML"
                              onClick={downloadYaml}
                            >
                              <DownloadIcon />
                            </Button>
                          </Tooltip>
                        </HStack>
                      </Box>

                      <Text mt={4}>
                        Run the following command to start the recipe:
                      </Text>

                      <Box
                        mt={4}
                        p={3}
                        bg={commandBackgroundColor}
                        color="white"
                        fontFamily="monospace"
                        borderRadius="md"
                        position="relative"
                      >
                        <Text>$ {selectedRecipe.command}</Text>
                        <HStack
                          position="absolute"
                          top="5px"
                          right="10px"
                          spacing={2}
                        >
                          <Button
                            aria-label="Copy command"
                            variant="ghost"
                            onClick={copyCommandToClipboard}
                          >
                            <CopyIcon />
                          </Button>
                        </HStack>
                      </Box>
                    </Box>
                  </TabPanel>
                  <TabPanel>
                    <Box position="relative" fontSize={"md"}>
                      <Text mb={4}>{selectedRecipe.finalText}</Text>
                      {selectedRecipe.usageCommand &&
                        selectedRecipe.usageLanguage && (
                          <Box
                            mt={4}
                            p={3}
                            fontFamily="monospace"
                            borderRadius="md"
                            position="relative"
                          >
                            <SyntaxHighlighter
                              language={selectedRecipe.usageLanguage}
                              style={syntaxHighlightTheme}
                              showLineNumbers
                            >
                              {selectedRecipe.usageCommand}
                            </SyntaxHighlighter>
                            <HStack
                              position="absolute"
                              top="15px"
                              right="10px"
                              spacing={2}
                            >
                              <Button
                                aria-label="Copy command"
                                variant="ghost"
                                onClick={copyUsageCommandToClipboard}
                              >
                                <HStack spacing={2}>
                                  <CopyIcon />
                                  <Text>Copy</Text>
                                </HStack>
                              </Button>
                            </HStack>
                          </Box>
                        )}
                    </Box>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </DrawerBody>

            <DrawerFooter>
              <Button variant="outline" onClick={closeDrawer}>
                Close
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      )}
    </Page>
  );
};

export default StudioPage;
