import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  VStack,
  useColorMode,
  useDisclosure,
  useBreakpointValue,
  PlacementWithLogical,
} from "@chakra-ui/react";
import { Navbar, NavbarBrand, NavbarContent, NavbarItem } from "@saas-ui/react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { FaDiscord, FaMoon, FaSun } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { IoReceiptOutline } from "react-icons/io5";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import {
  BillingProvider,
  GetPaymentMethodsResponse,
  GetSubscriptionResponse,
  useBilling,
} from "../providers/BillingContext";
import { useCognito } from "../providers/CognitoContext";
import { PlanType } from "../types/types";
import { axios } from "../utils";

const DISCORD_INVITE_URL = "https://discord.gg/baJGK6RKZC";

const MemoizedNavbarCreditBalance: React.FC = () => {
  const { subscription, paymentMethods, isFetchingBilling, fetchBillingData } =
    useBilling();
  const [isLoading, setIsLoading] = useState(false);
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);
  const prevMemoizedBalanceRef = useRef(0);
  const prevMemoizedPaymentMethodsCountRef = useRef(0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const fetchDataRef = useRef(() => {
    setIsLoading(true);
    // Remove cached storage before fetching
    axios.storage.remove("get-subscription");
    axios.storage.remove("get-payment-methods");

    fetchBillingData().finally(() => {
      setIsLoading(false);
      setHasLoadedOnce(true);
      if (subscription?.balance !== prevMemoizedBalanceRef.current) {
        prevMemoizedBalanceRef.current = subscription?.balance ?? 0;
      }
      if (
        (paymentMethods?.data.length ?? 0) !==
        prevMemoizedPaymentMethodsCountRef.current
      ) {
        prevMemoizedPaymentMethodsCountRef.current =
          paymentMethods?.data.length ?? 0;
      }
    });
  });

  useEffect(() => {
    // Clear any existing interval
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    // Set a new interval to fetch data every 10 seconds
    intervalRef.current = setInterval(fetchDataRef.current, 10000);

    // Initial fetch
    fetchDataRef.current();

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []); // Empty dependency array

  // Force update when actual data changes
  useEffect(() => {
    if (
      subscription?.balance !== prevMemoizedBalanceRef.current ||
      (paymentMethods?.data.length ?? 0) !==
        prevMemoizedPaymentMethodsCountRef.current
    ) {
      prevMemoizedBalanceRef.current = subscription?.balance ?? 0;
      prevMemoizedPaymentMethodsCountRef.current =
        paymentMethods?.data.length ?? 0;
    }
  }, [subscription?.balance, paymentMethods?.data.length]);

  return useMemo(() => {
    return (
      <NavbarCreditBalanceV2
        subscription={subscription}
        paymentMethods={paymentMethods}
        isFetchingBilling={!hasLoadedOnce && (isFetchingBilling || isLoading)}
      />
    );
  }, [
    subscription?.balance,
    paymentMethods?.data.length,
    isFetchingBilling,
    isLoading,
    hasLoadedOnce,
  ]);
};

interface NavbarCreditBalanceV2Props {
  subscription: GetSubscriptionResponse | null;
  paymentMethods: GetPaymentMethodsResponse | undefined;
  isFetchingBilling: boolean;
}

const NavbarCreditBalanceV2: React.FC<NavbarCreditBalanceV2Props> = ({
  subscription,
  paymentMethods,
  isFetchingBilling,
}) => {
  const navigate = useNavigate();
  const [displayBalance, setDisplayBalance] = useState<number | null>(null);
  const [displayBalanceColor, setDisplayBalanceColor] =
    useState<string>("green.500");
  const { isOpen, onOpen, onClose } = useDisclosure({defaultIsOpen: true});
  const popoverPlacement: PlacementWithLogical = useBreakpointValue({ base: "bottom", lg: "left" }) || "left";

  useEffect(() => {
    const calculateDisplayBalance = () => {
      if (!isFetchingBilling) {
        if (subscription?.balance != null) {
          const balance = (subscription.balance / 100) * -1;
          setDisplayBalance(balance);

          // Set color based on balance and payment methods
          if (paymentMethods?.data.length === 0) {
            if (balance > 5.0) {
              setDisplayBalanceColor("green.500");
            } else if (balance > 2.5) {
              setDisplayBalanceColor("#FF8C00");
            } else {
              setDisplayBalanceColor("#FF4500");
            }
          } else {
            setDisplayBalanceColor("green.500");
          }
        } else {
          setDisplayBalance(null);
          setDisplayBalanceColor("gray.500");
        }
      }
    };

    calculateDisplayBalance();
  }, [subscription, paymentMethods, isFetchingBilling]);

  if (isFetchingBilling) {
    return <></>;
  }

  const has_credits = displayBalance !== null && displayBalance > 0;
  const has_payment_method =
    paymentMethods?.data.length !== undefined &&
    paymentMethods?.data.length > 0;

  if (has_credits) {
    if (has_payment_method) {
      return (
        <HStack spacing={1} marginRight={5}>
          <Text>Free Credits</Text>
          <Text fontWeight={"bold"} color={displayBalanceColor}>
            ${displayBalance.toFixed(2)}
          </Text>
        </HStack>
      );
    } else {
      return (
        <Box>
          <Popover placement={popoverPlacement} autoFocus={false} isOpen={isOpen} onClose={onClose}>
            <PopoverTrigger>
              <HStack spacing={1} marginRight={5} cursor="pointer" onClick={onOpen}>
                {displayBalance < 7.5 && <InfoOutlineIcon color={"gray.500"} />}
                <Text>Free Credits</Text>
                <Text fontWeight={"bold"} color={displayBalanceColor}>
                  ${displayBalance.toFixed(2)}
                </Text>
              </HStack>
            </PopoverTrigger>
            {displayBalance < 7.5 && (
              <PopoverContent marginTop={"4px"}>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader color={displayBalanceColor} fontWeight={"750"}>
                  Low Balance Warning
                </PopoverHeader>
                <PopoverBody>
                  <Text>
                    {displayBalance === 0
                      ? "You've ran out of free credits. Add a payment method to continue using our services."
                      : "You're almost out of free credits. Add a payment method to avoid service interruption."}
                  </Text>
                  <Button
                    colorScheme="primary"
                    mt={3}
                    onClick={() => {
                      navigate("/billing");
                    }}
                  >
                    Add Payment Method
                  </Button>
                </PopoverBody>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      );
    }
  } else {
    if (has_payment_method) {
      return <></>;
    } else {
      return (
        <Box>
          <Popover placement={popoverPlacement} autoFocus={false} isOpen={isOpen} onClose={onClose}>
            <PopoverTrigger>
              <HStack spacing={1} marginRight={5} cursor="pointer" onClick={onOpen}>
                <InfoOutlineIcon color={"gray.500"} />
                <Text>Free Credits</Text>
              </HStack>
            </PopoverTrigger>
            <PopoverContent marginTop={"4px"}>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader fontWeight={"750"}>
                Claim Free GPU Credits
              </PopoverHeader>
              <PopoverBody>
                <Text>
                  Join our Discord and tell us what you're building to claim
                  free GPU credits!
                </Text>
                <Button
                  as="a"
                  href={DISCORD_INVITE_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  colorScheme="primary"
                  mt={3}
                >
                  Join Discord
                </Button>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
      );
    }
  }
};

const NavbarUserMenuUpgradeButton: React.FC = () => {
  const { subscription, paymentMethods, fetchBillingData, isFetchingBilling } =
    useBilling();
  if (subscription?.plan && subscription?.plan === PlanType.FREE) {
    return (
      <ReactRouterLink to="/billing">
        <MenuItem
          alignContent={"center"}
          justifyContent={"center"}
          _hover={{ background: "transparent" }}
        >
          <Button variant="primary">Upgrade to Pro</Button>
        </MenuItem>
      </ReactRouterLink>
    );
  } else {
    return <></>;
  }
};

const AppNavbar: React.FC = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const { user, isFetchingUserFromDb, signOut } = useCognito();
  return (
    <Navbar borderBottomWidth="1px" position="sticky" top="0">
      <NavbarBrand paddingLeft={{ base: "8", md: "0" }}>
        <ReactRouterLink to="/">
          <img
            src={colorMode === "light" ? "/light.svg" : "/dark.svg"}
            style={{ width: "100px", height: "100px" }}
          />
        </ReactRouterLink>
      </NavbarBrand>
      <NavbarContent justifyContent="flex-end" spacing={"5px"}>
        <BillingProvider>
          <MemoizedNavbarCreditBalance />
        </BillingProvider>
        <ReactRouterLink to={DISCORD_INVITE_URL} target="_blank">
          <NavbarItem>
            <Button
              variant={"primary"}
              backgroundColor={"#5865F2"}
              _hover={{ backgroundColor: "#5865F2" }}
            >
              <HStack backgroundColor={"#5865F2"}>
                <Text>Join us on Discord!</Text>
                <FaDiscord size={"1.8em"} />
              </HStack>
            </Button>
          </NavbarItem>
        </ReactRouterLink>
        <NavbarItem>
          <IconButton
            icon={colorMode === "light" ? <FaMoon /> : <FaSun />}
            onClick={toggleColorMode}
            variant="ghost"
            aria-label="Toggle color mode"
          />
        </NavbarItem>
        <NavbarItem>
          <Menu>
            <MenuButton
              as={IconButton}
              icon={
                <Avatar
                  size="xs"
                  src={user?.picture ? user?.picture : "/showcase-avatar.jpg"}
                  name={user?.given_name + " " + user?.family_name}
                  referrerPolicy="no-referrer"
                />
              }
              variant="ghost"
              aria-label="User menu"
            />
            <MenuList>
              <VStack alignContent={"left"} py={1} px={10}>
                <Text>
                  {user?.given_name} {user?.family_name}
                </Text>
                <Text>{user?.email || "Unknown User"}</Text>
              </VStack>
              <ReactRouterLink to="/billing">
                <MenuItem>
                  <HStack>
                    <IoReceiptOutline />
                    <Text>Billing</Text>
                  </HStack>
                </MenuItem>
              </ReactRouterLink>
              <ReactRouterLink to="/settings">
                <MenuItem>
                  <HStack>
                    <FiSettings />
                    <Text>Settings</Text>
                  </HStack>
                </MenuItem>
              </ReactRouterLink>
              <MenuDivider />
              <MenuItem
                onClick={() => {
                  signOut();
                }}
              >
                Sign out
              </MenuItem>
              <MenuDivider />
              <BillingProvider>
                <NavbarUserMenuUpgradeButton />
              </BillingProvider>
            </MenuList>
          </Menu>
        </NavbarItem>
      </NavbarContent>
    </Navbar>
  );
};

export default AppNavbar;
