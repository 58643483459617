import { Box, Heading, Text } from "@chakra-ui/react";
import React from "react";
import { CreateKomodoServicePayload } from "../types/types";

export enum RecipeType {
  Model = "model",
  FineTuning = "fine-tuning",
}

export interface Recipe {
  title: string;
  description: string;
  steps: string[];
  finalText: string;
  command: string;
  image: string;
  yamlFileName: string;
  usageCommand?: string;
  usageLanguage?: string;
  serviceConfig: any; // You might want to define a more specific type for this
  type: RecipeType;
}

const recipes: Recipe[] = [
  {
    title: "Fine-tune Llama 3.1 8B",
    description:
      "Customize Llama 3.1 8B for your specific use case with efficient LoRA fine-tuning.",
    steps: [
      "Download the YAML config",
      "Set your Hugging Face token and model repo",
      "Launch with Komodo CLI",
    ],
    finalText:
      "After fine-tuning, deploy your custom model using our Llama serving recipe.",
    command: "komo job launch llama3_8b_finetune.yaml",
    image: "assets/komodo_llama3_finetune_card.webp",
    yamlFileName: "llama3_8b_finetune.yaml",
    serviceConfig: {
      name: "llama3-8b-finetune",
      resources: {
        accelerators: "A100:8",
      },
      envs: {
        HF_TOKEN: "YOUR_HF_TOKEN",
        DATASET: "yahma/alpaca-cleaned",
        MODEL_REPO: "YOUR MODEL REPO",
      },
      setup: `pip install torch torchao torchvision torchtune huggingface

tune download meta-llama/Meta-Llama-3.1-8B-Instruct \\
  --hf-token $HF_TOKEN \\
  --output-dir /tmp/Meta-Llama-3.1-8B-Instruct \\
  --ignore-patterns \"original/consolidated\*\"
  
wget https://raw.githubusercontent.com/pytorch/torchtune/651a7300435aa31f86d49511ea84400f89d7f59e/recipes/configs/llama3_1/8B_lora.yaml`,
      run: `tune run --nproc_per_node 8 \\
  lora_finetune_distributed \\
  --config 8B_lora.yaml \\
  dataset.source=$DATASET

# Remove the checkpoint files to save space, LoRA serving only needs the
# adapter files.
rm /tmp/Meta-Llama-3.1-8B-Instruct/*.pt
rm /tmp/Meta-Llama-3.1-8B-Instruct/*.safetensors

mkdir -p output_model/logs
rsync -Pavz /tmp/Meta-Llama-3.1-8B-Instruct/* output_model/
cp -r /tmp/lora_finetune_output/* output_model/logs

# upload to Hugging Face
huggingface-cli upload $MODEL_REPO output_model .`,
    },
    type: RecipeType.FineTuning,
  },
  {
    title: "Flux.1-dev",
    description:
      "Launch an OpenAI-compatible API for FLUX.1 image generation models.",
    steps: [
      "Download the YAML config",
      "Add your Hugging Face token",
      "Deploy with Komodo CLI",
    ],
    finalText:
      "Once the service is in READY status, you can use it to generate an image using the command below (make sure to replace SERVICE_ENDPOINT with the endpoint displayed for your service on the web dashboard):",
    command: "komo service launch --name flux -d flux.yaml",
    image: "assets/komodo_flux.webp",
    yamlFileName: "flux.yaml",
    usageLanguage: "bash",
    usageCommand:
      '\
BASE64_IMAGE=$(curl -X POST "$SERVICE_ENDPOINT/v1/images/generations" \\\n\
-H "Content-Type: application/json" \\\n\
-d \'{"prompt": "A beautiful sunset over the ocean", "n": 1, "size": "1024x1024"}\' \\\n\
| jq -r \'.data[0].url\' | sed \'s/data:image\\/png;base64,//g\') \
\n\n\
echo "$BASE64_IMAGE" | base64 --decode > generated_image.png',
    serviceConfig: {
      name: "flux",
      resources: {
        accelerators: {
          "A100:1": null,
          "H100:1": null,
        },
        ports: 8000,
      },
      service: {
        replica_policy: {
          min_replicas: 1,
          max_replicas: 3,
          target_qps_per_replica: 5,
        },
        readiness_probe: {
          initial_delay_seconds: 1800,
          path: "/health",
        },
      },
      envs: {
        HF_TOKEN: "YOUR_HF_TOKEN",
      },
      setup: `git clone git@github.com:matatonic/openedai-images-flux.git
cd openedai-images-flux
pip install -r requirements.txt
touch images.env
echo "HF_TOKEN=$HF_TOKEN\\n" >> images.env
cp config.default.json config/config.json`,
      run: `cd openedai-images-flux
python images.py --port 8000`,
    },
    type: RecipeType.Model,
  },
  {
    title: "Ultravox v0.4",
    description:
      "Set up an API for the Ultravox speech language model with vLLM optimization.",
    steps: [
      "Download the YAML config",
      "Set your Hugging Face token",
      "Deploy with Komodo CLI",
    ],
    finalText:
      "After deployment, use the Python script below to interact with your Ultravox model. Make sure to update base_url accordingly",
    command: "komo service launch --name ultravox -d ultravox.yaml",
    image: "assets/komodo_ultravox_card.avif",
    yamlFileName: "ultravox.yaml",
    usageLanguage: "python",
    usageCommand: `
import base64
from openai import OpenAI
from vllm.assets.audio import AudioAsset

client = OpenAI(api_key="EMPTY", base_url="YOUR_KOMODO_SERVICE_URL/v1")

audio_url = AudioAsset("mary_had_lamb").url
# For local audio: audio_url = f"data:audio/mp3;base64,{base64.b64encode(open('your_file.mp3', 'rb').read()).decode('utf-8')}"

response = client.chat.completions.create(
    model="fixie-ai/ultravox-v0_4",
    messages=[{
        "role": "user",
        "content": [
            {"type": "text", "text": "What's in this audio?"},
            {"type": "audio_url", "audio_url": {"url": audio_url}},
        ],
    }],
    max_tokens=64,
)

print(f"Model output: {response.choices[0].message.content}")`.trim(),
    serviceConfig: {
      name: "ultravox",
      service: {
        replica_policy: {
          min_replicas: 1,
        },
        readiness_probe: {
          initial_delay_seconds: 1800,
          path: "/health",
        },
      },
      envs: {
        HF_TOKEN: "YOUR_HF_TOKEN",
      },
      resources: {
        accelerators: {
          "A100:1": null,
        },
        ports: 8000,
      },
      setup: "pip install vllm==0.6.1.post2 vllm-flash-attn==2.6.1 vllm[audio]",
      run: "vllm serve fixie-ai/ultravox-v0_4 --gpu-memory-utilization 0.98 --max-model-len 22416 --port 8000",
    },
    type: RecipeType.Model,
  },
  {
    title: "Pixtral 12B",
    description:
      "Launch a high-performance API for the Pixtral 12B multi-modal model using vLLM.",
    steps: [
      "Download the YAML config",
      "Add your Hugging Face token",
      "Deploy with Komodo CLI",
    ],
    finalText:
      "Once deployed, use the curl command below to interact with your Pixtral model.",
    command: "komo service launch --name pixtral pixtral.yaml",
    image: "assets/komodo_pixtral.webp",
    yamlFileName: "pixtral.yaml",
    usageLanguage: "bash",
    usageCommand: `curl -X POST "$SERVICE_ENDPOINT/v1/chat/completions" \\
-H "Content-Type: application/json" \\
-d '{
  "model": "mistralai/Pixtral-12B-2409",
  "messages": [
    {
      "role": "user",
      "content": [
        {
          "type": "text",
          "text": "Describe this image in one sentence."
        },
        {
          "type": "image_url",
          "image_url": {
            "url": "https://picsum.photos/id/237/200/300"
          }
        }
      ]
    }
  ]
}'`,
    serviceConfig: {
      name: "pixtral",
      resources: {
        accelerators: {
          "A100:1": null,
          "H100:1": null,
        },
        ports: 8000,
      },
      service: {
        replica_policy: {
          min_replicas: 1,
        },
        readiness_probe: {
          initial_delay_seconds: 1800,
          path: "/health",
        },
      },
      envs: {
        HF_TOKEN: "YOUR_HF_TOKEN",
      },
      setup: "pip install vllm vllm-flash-attn mistral_common[opencv]",
      run: "vllm serve mistralai/Pixtral-12B-2409 --tokenizer-mode mistral --max-model-len 2048 --port 8000",
    },
    type: RecipeType.Model,
  },
  {
    title: "LLama 3 8B with vLLM",
    description:
      "Launch a high-performance API for the LLama 3 8B model using vLLM.",
    steps: [
      "Download the YAML config",
      "Add your Hugging Face token",
      "Deploy with Komodo CLI",
    ],
    finalText:
      "Once deployed, use the curl command below to interact with your LLama 3 8B model or chat with it in the web dashboard.",
    command: "komo service launch --name llama3-8b-vllm -d llama3_8b_vllm.yaml",
    image: "assets/komodo_llama3_finetune_card.webp",
    yamlFileName: "llama3_8b_vllm.yaml",
    usageLanguage: "bash",
    usageCommand: `curl -X POST "$SERVICE_ENDPOINT/v1/chat/completions" \\
-H "Content-Type: application/json" \\
-d '{
  "model": "meta-llama/Meta-Llama-3-8B-Instruct",
  "messages": [
    {
      "role": "user",
      "content": [
        {
          "type": "text",
          "text": "Who are you?"
        }
      ]
    }
  ]
}'`,
    serviceConfig: {
      name: "llama3-8b-vllm",
      resources: {
        accelerators: {
          "A100:1": null,
        },
        ports: 8000,
      },
      service: {
        replica_policy: {
          min_replicas: 1,
        },
        readiness_probe: {
          initial_delay_seconds: 1800,
          path: "/health",
        },
      },
      envs: {
        HF_TOKEN: "YOUR_HF_TOKEN",
      },
      setup: "pip install vllm vllm-flash-attn",
      run: "vllm serve meta-llama/Meta-Llama-3-8B-Instruct --tensor-parallel-size 1 --port 8000",
    },
    type: RecipeType.Model,
  },
];

interface RecipeCardProps {
  title: string;
  description: string;
  onClick: () => void;
  image: string;
}

const RecipeCard: React.FC<RecipeCardProps> = ({
  title,
  description,
  onClick,
  image,
}) => {
  return (
    <Box
      borderWidth={1}
      borderRadius="lg"
      boxShadow="md"
      cursor="pointer"
      onClick={onClick}
      _hover={{ boxShadow: "lg" }}
      position="relative"
      overflow="hidden"
    >
      {/* Background Image */}
      <Box
        backgroundImage={`url(${image})`}
        backgroundSize="cover"
        backgroundPosition="center"
        height="150px"
        position="relative"
      >
        {/* Title Overlay */}
        <Box
          position="absolute"
          bottom="0"
          width="100%"
          bg="rgba(0, 0, 0, 0.6)"
          color="white"
          p={3}
          textAlign="center"
        >
          <Heading as="h2" size="md">
            {title}
          </Heading>
        </Box>
      </Box>

      {/* Description Below Image */}
      <Box p={4}>
        <Text>{description}</Text>
      </Box>
    </Box>
  );
};

export { RecipeCard, recipes };
// export type { Recipe };
