import { Box, Container, HStack, Text, Link, Flex } from "@chakra-ui/react";
import React from "react";

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  return (
    <Box as="footer" width="full" py="2" borderTopWidth="1px">
      <Container maxW="container.xl">
        <Flex
          direction={{ base: "column", md: "row" }}
          justify="space-between"
          align="center"
        >
          <HStack
            justify="center"
            spacing={4}
            margin="auto" // Centers the content in the middle of the Flex container
          >
            <Text textAlign="center" fontSize="sm">
              © {currentYear} Komodo AI
            </Text>
            <Text>/|\</Text>
            <Text textAlign="center" fontSize="sm">
              Built with{" "}
              <Box
                as="span"
                role="img"
                aria-label="love"
                sx={{
                  display: "inline-block",
                  _hover: {
                    animation: "flutter 0.6s ease-in-out infinite",
                  },
                  "@keyframes flutter": {
                    "0%": { transform: "scale(1)" },
                    "25%": { transform: "scale(1.2)" },
                    "50%": { transform: "scale(1)" },
                    "75%": { transform: "scale(1.2)" },
                    "100%": { transform: "scale(1)" },
                  },
                }}
              >
                ❤️
              </Box>{" "}
              in San Francisco
            </Text>
          </HStack>
          <HStack>
            <Link
              href="https://www.komodo.io/privacy-policy"
              fontSize="sm"
              mt={{ base: 2, md: 0 }}
              isExternal
              alignSelf={{ base: "center", md: "flex-end" }} // Align right on larger screens
            >
              Privacy Policy
            </Link>
            <Link
              href="https://www.komodo.io/terms-and-conditions"
              fontSize="sm"
              mt={{ base: 2, md: 0 }}
              isExternal
              alignSelf={{ base: "center", md: "flex-end" }} // Align right on larger screens
            >
              Terms and Conditions
            </Link>
          </HStack>
        </Flex>
      </Container>
    </Box>
  );
};

export default Footer;
