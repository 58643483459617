// LogBox.tsx
import {
  Box,
  Button,
  Container,
  HStack,
  Text,
  VStack,
  useColorMode,
} from "@chakra-ui/react";
import {
  EmptyState,
  LoadingSpinner,
  Select,
  SelectButton,
  SelectList,
} from "@saas-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { ImFileEmpty } from "react-icons/im";
import { useCognito } from "../providers/CognitoContext";
import { GetJobById, GetJobLogs } from "../providers/JobsContext";
import { GetMachineLogs } from "../providers/MachinesContext";
import { GetServiceById, GetServiceLogs } from "../providers/ServicesContext";
import {
  LogBorderColor,
  LogBoxBgColor,
  LogTextBackgroundColor,
  LogTextColor,
  LogTimestampColor,
} from "../theme";
import {
  GetLogsResponse,
  GetServiceLogsResponse,
  Job,
  KomodoService,
  KomodoServiceStatus,
  KomodoStatus,
  Log,
  Machine,
} from "../types/types";
import { LiaSlashSolid } from "react-icons/lia";


interface LogBoxProps {
  logBoxType: string;
  targetId: string;
  subtargetIds: number[] | null;
  targetObj?: Job | Machine | KomodoService;
}

const TIME_BETWEEN_QUERIES = 1;
const JOB_FINISH_WAIT_TIME = 30;

interface FetchLogReturn {
  nextToken: string | null;
  logs: Log[];
}

interface FetchServiceLogReturn {
  [replicaId: number]: FetchLogReturn;
}

interface FetchJobLogReturn {
  [nodeId: number]: FetchLogReturn;
}

interface TimeFilter {
  label: string;
  value: number | null;
}

const timeFilters: TimeFilter[] = [
  { label: "Last minute", value: 1 },
  { label: "Last 5 minutes", value: 5 },
  { label: "Last 15 minutes", value: 15 },
  { label: "Last 30 minutes", value: 30 },
  { label: "Last hour", value: 60 },
  { label: "Last 3 hours", value: 180 },
  { label: "Last 6 hours", value: 360 },
  { label: "Last day", value: 1440 },
  { label: "All logs", value: null },
];

const FetchJobLogs = async (
  user: any,
  jobId: string,
  nodeIds: number[],
  startTime: number | null,
  endTime: number | null,
  nextTokens: Record<number, string | null>
): Promise<FetchJobLogReturn | null> => {
  try {
    const response = await GetJobLogs({
      jobId: jobId,
      nodeIds: nodeIds,
      startTime: startTime,
      endTime: endTime,
      nextTokens: nextTokens,
      user: user,
    });
    if (response === null) {
      console.log(`Error loading logs for job ${jobId}`);
      return null;
    }

    var newJobLogs: FetchJobLogReturn = {} as FetchJobLogReturn;
    for (var nodeId in response) {
      const intNodeId = parseInt(nodeId);
      if (response[nodeId].logs.length !== 0) {
        const fetchedLogs = response[nodeId].logs.map((log) => {
          const logObj: Log = {
            timestamp: log.timestamp,
            message: log.message,
            replicaId: intNodeId,
          };
          return logObj;
        });

        newJobLogs[intNodeId] = { logs: fetchedLogs, nextToken: null };
        if (response[nodeId].next_token === nextTokens[intNodeId]) {
          newJobLogs[intNodeId].nextToken = null;
        } else {
          newJobLogs[intNodeId].nextToken = response[nodeId].next_token;
        }
      } else {
        newJobLogs[intNodeId] = { logs: [], nextToken: null };
      }
    }
    return newJobLogs;
  } catch (error) {
    console.log("Error loading logs for job", jobId, error);
    return null;
  }
};

const FetchMachineLogs = async (
  user: any,
  targetId: string,
  startTime: number | null,
  endTime: number | null,
  nextToken: string | null
): Promise<FetchLogReturn | null> => {
  try {
    var response: GetLogsResponse = {} as GetLogsResponse;
    response = await GetMachineLogs(
      targetId,
      user,
      startTime,
      endTime,
      nextToken
    );

    if (response === null) {
      console.log(`Error loading logs for machine ${targetId}`);
      return null;
    }

    var newLogs: Log[] = [];
    if (response.logs.length !== 0) {
      const fetchedLogs = response.logs.map((log) => {
        const logObj: Log = {
          timestamp: log.timestamp,
          message: log.message,
        };
        return logObj;
      });
      newLogs = fetchedLogs;
    }

    if (response.next_token === nextToken) {
      console.log(`Reached end of logs for machine ${targetId}`);
      return { nextToken: null, logs: newLogs };
    }
    return { nextToken: response.next_token, logs: newLogs };
  } catch (error) {
    console.log("Error loading logs", error);
    return null;
  }
};

const FetchServiceLogs = async (
  user: any,
  serviceId: string,
  replicaIds: number[],
  startTime: number | null,
  endTime: number | null,
  nextTokens: Record<number, string | null>
): Promise<FetchServiceLogReturn | null> => {
  try {
    var response: GetServiceLogsResponse | null = {} as GetServiceLogsResponse;
    response = await GetServiceLogs({
      serviceId: serviceId,
      replicaIds: replicaIds,
      startTime: startTime,
      endTime: endTime,
      nextTokens: nextTokens,
      user: user,
    });

    if (response === null) {
      console.log(`Error loading logs for service ${serviceId}`);
      return null;
    }

    var newServiceLogs: FetchServiceLogReturn = {} as FetchServiceLogReturn;
    for (var replicaId in response) {
      const intReplicaId = parseInt(replicaId);
      if (response[replicaId].logs.length !== 0) {
        const fetchedLogs = response[replicaId].logs.map((log) => {
          const logObj: Log = {
            timestamp: log.timestamp,
            message: log.message,
            replicaId: intReplicaId,
          };
          return logObj;
        });

        newServiceLogs[intReplicaId] = { logs: fetchedLogs, nextToken: null };
        if (response[replicaId].next_token === nextTokens[intReplicaId]) {
          newServiceLogs[intReplicaId].nextToken = null;
        } else {
          newServiceLogs[intReplicaId].nextToken =
            response[replicaId].next_token;
        }
      } else {
        newServiceLogs[intReplicaId] = { logs: [], nextToken: null };
      }
    }
    return newServiceLogs;
  } catch (error) {
    console.log("Error loading logs", error);
    return null;
  }
};

const LoadExistingJobLogs = async (
  user: any,
  jobId: string,
  nodeIds: number[],
  startTime: number | null,
  endTime: number | null,
  setLogs: React.Dispatch<
    React.SetStateAction<{ timestamp: number; message: string }[]>
  >,
  append: boolean = true
) => {
  var nextTokens: Record<number, string | null> = {};
  for (var i = 0; i < nodeIds.length; i++) {
    nextTokens[nodeIds[i]] = null;
  }
  var newLogs: Log[] = [];
  while (true) {
    const retval: FetchJobLogReturn | null = await FetchJobLogs(
      user,
      jobId,
      nodeIds,
      startTime,
      endTime,
      nextTokens
    );
    if (retval === null) {
      break;
    }

    const fetchedLogs: Log[] = [];
    for (const nodeId in retval) {
      if (retval[nodeId].logs.length !== 0) {
        fetchedLogs.push(...retval[parseInt(nodeId)].logs);
      }
      if (retval[nodeId].nextToken === null) {
        delete nextTokens[parseInt(nodeId)];
      } else {
        nextTokens[parseInt(nodeId)] = retval[parseInt(nodeId)].nextToken;
      }
    }
    fetchedLogs.sort((a, b) => a.timestamp - b.timestamp);
    newLogs = newLogs.concat(fetchedLogs);

    if (Object.keys(nextTokens).length === 0) {
      break;
    }
  }
  setLogs((prevLogs) =>
    append ? prevLogs.concat(newLogs) : newLogs.concat(prevLogs)
  );
};

const LoadExistingMachineLogs = async (
  user: any,
  targetId: string,
  startTime: number | null,
  endTime: number | null,
  setLogs: React.Dispatch<
    React.SetStateAction<{ timestamp: number; message: string }[]>
  >,
  append: boolean = true
) => {
  var nextToken = null;
  var newLogs: Log[] = [];
  while (true) {
    const retval: FetchLogReturn | null = await FetchMachineLogs(
      user,
      targetId,
      startTime,
      endTime,
      nextToken
    );
    if (retval === null) {
      break;
    }
    newLogs = newLogs.concat(retval.logs);
    if (retval.nextToken === null) {
      break;
    }
    nextToken = retval.nextToken;
  }
  // setLogs((prevLogs) => prevLogs.concat(newLogs));
  setLogs((prevLogs) =>
    append ? prevLogs.concat(newLogs) : newLogs.concat(prevLogs)
  );
};

const LoadExistingServiceLogs = async (
  user: any,
  serviceId: string,
  replicaIds: number[],
  startTime: number | null,
  endTime: number | null,
  setLogs: React.Dispatch<React.SetStateAction<Log[]>>,
  append: boolean = true
) => {
  var nextTokens: Record<number, string | null> = {};
  for (var i = 0; i < replicaIds.length; i++) {
    nextTokens[replicaIds[i]] = null;
  }
  var newLogs: Log[] = [];
  while (true) {
    const retval: FetchServiceLogReturn | null = await FetchServiceLogs(
      user,
      serviceId,
      replicaIds,
      startTime,
      endTime,
      nextTokens
    );
    if (retval === null) {
      break;
    }

    const fetchedLogs: Log[] = [];
    for (const replicaId in retval) {
      if (retval[replicaId].logs.length !== 0) {
        fetchedLogs.push(...retval[parseInt(replicaId)].logs);
      }
      if (retval[replicaId].nextToken === null) {
        delete nextTokens[parseInt(replicaId)];
      } else {
        nextTokens[parseInt(replicaId)] = retval[parseInt(replicaId)].nextToken;
      }
    }
    fetchedLogs.sort((a, b) => a.timestamp - b.timestamp);
    newLogs = newLogs.concat(fetchedLogs);

    if (Object.keys(nextTokens).length === 0) {
      break;
    }
  }
  // setLogs((prevLogs) => prevLogs.concat(newLogs));
  setLogs((prevLogs) =>
    append ? prevLogs.concat(newLogs) : newLogs.concat(prevLogs)
  );
};

const IsJobLive = (job: Job) => {
  const isJobLive =
    (job.status === KomodoStatus.Running ||
      job.status === KomodoStatus.Cancelled ||
      job.status === KomodoStatus.Cancelling ||
      job.status === KomodoStatus.Finished) &&
    new Date().getTime() / 1000 - job.updatedTimestamp < 30;
  return isJobLive;
};

const IsServiceLive = (service: KomodoService) => {
  return service.status === KomodoServiceStatus.READY;
};

const LogBox: React.FC<LogBoxProps> = ({
  logBoxType,
  targetId,
  subtargetIds,
  targetObj,
}) => {
  const { colorMode } = useColorMode();
  const { user } = useCognito();
  const [logs, setLogs] = useState<Log[]>([]);
  const [loadLogs, setLoadLogs] = useState(false);
  const [tailLogs, setTailLogs] = useState(false);
  const [subtargetsToLoad, setSubtargetsToLoad] = useState<number[] | null>(
    subtargetIds
  );
  const [logsLastXMinutes, setLogsLastXMinutes] = useState<number | null>(5);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const previousScrollTop = useRef(0);
  const fetchingDueToScroll = useRef(false);
  const firstLogRef = useRef<Log>();
  const logBoxRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (logBoxRef.current) {
      if (fetchingDueToScroll.current) {
        const scrollHeight = logBoxRef.current.scrollHeight; // new scroll height
        const scrollTo = scrollHeight - previousScrollTop.current - 50; // scroll to where it was before minus some amount
        logBoxRef.current.scrollTo(0, scrollTo);
        fetchingDueToScroll.current = false;
      } else {
        const scrollHeight = logBoxRef.current.scrollHeight;
        logBoxRef.current.scrollTo(0, scrollHeight);
      }
    }
  }, [logs]);

  useEffect(() => {
    firstLogRef.current = logs[0];
  }, [logs]);

  useEffect(() => {
    const handleScroll = () => {
      if (logBoxRef.current) {
        if (logBoxRef.current.scrollTop === 0 && !isFetchingMore) {
          previousScrollTop.current = logBoxRef.current.scrollHeight;
          fetchingDueToScroll.current = true;
          fetchMoreLogs();
        }
      }
    };

    if (logBoxRef.current) {
      logBoxRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (logBoxRef.current) {
        logBoxRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isFetchingMore]);

  const fetchMoreLogs = async () => {
    setIsFetchingMore(true);
    if (firstLogRef.current === undefined || firstLogRef.current === null) {
      setIsFetchingMore(false);
      return;
    }
    var endTime = firstLogRef.current.timestamp;
    var startTime = endTime - (logsLastXMinutes ?? 1) * 60 * 1000;
    if (logBoxType === "job") {
      await LoadExistingJobLogs(
        user,
        targetId,
        subtargetsToLoad!,
        startTime,
        endTime,
        setLogs,
        false
      );
    } else if (logBoxType === "machine") {
      await LoadExistingMachineLogs(
        user,
        targetId,
        startTime,
        endTime,
        setLogs,
        false
      );
    } else if (logBoxType === "service") {
      await LoadExistingServiceLogs(
        user,
        targetId,
        subtargetsToLoad!,
        startTime,
        endTime,
        setLogs,
        false
      );
    }
    setIsFetchingMore(false);
  };

  const handleClearLogsClick = () => {
    setLoadLogs(false);
    setLogs([]);
    previousScrollTop.current = 0;
    fetchingDueToScroll.current = false;
  };

  const handleLoadLogsClick = () => {
    setLogs([]); // Clear logs
    setLoadLogs(true);
  };

  const handleTailLogsClick = () => {
    if (!tailLogs) {
      setLogs([]); // Clear logs
    }
    setTailLogs(!tailLogs); // Toggle tailing
  };

  useEffect(() => {
    const fetchLogs = async () => {
      var startTimeMillisecondsSinceEpoch = null;
      if (logsLastXMinutes !== null) {
        startTimeMillisecondsSinceEpoch =
          new Date().getTime() - logsLastXMinutes * 60 * 1000;
      }
      var endTimeMillisecondsSinceEpoch = new Date().getTime();
      if (loadLogs) {
        if (logBoxType === "machine") {
          var machineStartedTimestampSecondsSinceEpoch = (targetObj as Machine)
            .startedTimestamp;
          if (machineStartedTimestampSecondsSinceEpoch) {
            startTimeMillisecondsSinceEpoch =
              machineStartedTimestampSecondsSinceEpoch * 1000;
          }
          LoadExistingMachineLogs(
            user,
            targetId,
            startTimeMillisecondsSinceEpoch,
            endTimeMillisecondsSinceEpoch,
            setLogs,
            false
          ).then(() => setLoadLogs(false)); // Reset loadLogs state to prevent re-fetching
        } else if (logBoxType === "job") {
          var job = targetObj as Job;
          if (
            job.status === KomodoStatus.Finished ||
            job.status === KomodoStatus.Cancelled
          ) {
            var jobFinishedTimestampSecondsSinceEpoch = job.finishedTimestamp;
            if (
              jobFinishedTimestampSecondsSinceEpoch &&
              logsLastXMinutes !== null
            ) {
              startTimeMillisecondsSinceEpoch =
                jobFinishedTimestampSecondsSinceEpoch * 1000 -
                logsLastXMinutes * 60 * 1000;
            }
          }
          LoadExistingJobLogs(
            user,
            targetId,
            subtargetsToLoad!,
            startTimeMillisecondsSinceEpoch,
            endTimeMillisecondsSinceEpoch,
            setLogs,
            false
          ).then(() => setLoadLogs(false)); // Reset loadLogs state to prevent re-fetching
        } else if (logBoxType === "service") {
          LoadExistingServiceLogs(
            user,
            targetId,
            subtargetsToLoad!,
            startTimeMillisecondsSinceEpoch,
            endTimeMillisecondsSinceEpoch,
            setLogs
          ).then(() => setLoadLogs(false)); // Reset loadLogs state to prevent re-fetching
        } else {
          console.log("Unknown log target", logBoxType);
        }
      }
    };
    fetchLogs();
  }, [loadLogs, targetId, subtargetsToLoad, user]);

  useEffect(() => {
    let cancelFetch = false;
    const streamLogs = async (follow: boolean) => {
      var nextToken: string | null = null;
      var nextTokens: Record<number, string | null> = {};
      if (subtargetIds) {
        for (var i = 0; i < subtargetsToLoad!.length; i++) {
          nextTokens[subtargetsToLoad![i]] = null;
        }
      }
      var lastQueryTime = new Date().getTime() / 1000 - TIME_BETWEEN_QUERIES;
      var endTime = null;

      while (!cancelFetch) {
        await new Promise((r) =>
          setTimeout(
            r,
            Math.max(
              0,
              TIME_BETWEEN_QUERIES -
                (new Date().getTime() / 1000 - lastQueryTime)
            )
          )
        );

        var startTimeMillisecondsSinceEpoch = null;
        if (logsLastXMinutes !== null) {
          startTimeMillisecondsSinceEpoch =
            new Date().getTime() - logsLastXMinutes * 60 * 1000;
        }

        if (logBoxType === "job") {
          const retval: FetchJobLogReturn | null = await FetchJobLogs(
            user,
            targetId,
            subtargetsToLoad!,
            startTimeMillisecondsSinceEpoch,
            endTime,
            nextTokens
          );
          if (retval === null) {
            continue;
          }
          const newLogs: Log[] = [];
          for (const nodeId in retval) {
            if (retval[nodeId].logs.length !== 0) {
              newLogs.push(...retval[nodeId].logs);
            }
            if (retval[nodeId].nextToken === null) {
              console.log(
                `Reached end of logs for node ${nodeId}, but we're tailing so continue`
              );
              var job = await GetJobById(targetId, user);
              if (job === undefined || job === null) {
                console.log(
                  `Job ${targetId} not found but looping so try again?`
                );
                continue;
              }
              if (
                job.status !== KomodoStatus.Finished &&
                job.status !== KomodoStatus.Cancelled
              ) {
                continue;
              }
            } else {
              nextTokens[parseInt(nodeId)] = retval[parseInt(nodeId)].nextToken;
            }
          }
          newLogs.sort((a, b) => a.timestamp - b.timestamp);
          setLogs((prevLogs) => prevLogs.concat(newLogs));
        } else if (logBoxType === "machine") {
          const retval: FetchLogReturn | null = await FetchMachineLogs(
            user,
            targetId,
            startTimeMillisecondsSinceEpoch,
            endTime,
            nextToken
          );
          if (retval === null) {
            continue;
          }
          setLogs((prevLogs) => prevLogs.concat(retval.logs));
          if (!retval.nextToken) {
            if (!follow) {
              console.log("Reached end of logs, follow is false, breaking");
              break;
            } else {
              console.log("Reached end of logs, follow is true, continuing");
            }
          } else {
            nextToken = retval.nextToken;
          }
        } else if (logBoxType === "service") {
          console.log("streaming service logs");
          const retval: FetchServiceLogReturn | null = await FetchServiceLogs(
            user,
            targetId,
            subtargetsToLoad!,
            startTimeMillisecondsSinceEpoch,
            endTime,
            nextTokens
          );
          if (retval === null) {
            continue;
          }

          const newLogs: Log[] = [];
          for (const replicaId in retval) {
            if (retval[replicaId].logs.length !== 0) {
              newLogs.push(...retval[parseInt(replicaId)].logs);
            }
            if (retval[replicaId].nextToken === null) {
              console.log(
                `Reached end of logs for replica ${replicaId}, but we're tailing so continue`
              );
              var service = await GetServiceById(targetId, user);
              if (service === undefined || service === null) {
                console.log(
                  `Service ${targetId} not found but looping so try again?`
                );
                continue;
              }
              if (service.status !== KomodoServiceStatus.READY) {
                if (IsServiceLive(service)) {
                  continue;
                }
              }
            } else {
              nextTokens[parseInt(replicaId)] =
                retval[parseInt(replicaId)].nextToken;
            }
          }
          newLogs.sort((a, b) => a.timestamp - b.timestamp);
          setLogs((prevLogs) => prevLogs.concat(newLogs));
        }
      }
    };

    if (tailLogs) {
      streamLogs(true);
    }

    return () => {
      cancelFetch = true;
      if (tailLogs) {
        setTailLogs(false); // Stop tailing when component unmounts or tailLogs changes
      }
    };
  }, [tailLogs, targetId, user]);

  if (logBoxType === "service" && !subtargetIds) {
    throw new Error("subtargetIds must be provided for service logs");
  }

  const handleTimeFilterSelectChange = (selectedValue: string) => {
    const selectedFilter = timeFilters.find(
      (filter) => filter.label === selectedValue
    );
    setLogsLastXMinutes(selectedFilter?.value ?? null);
  };

  return (
    <Container
      ref={logBoxRef}
      maxWidth="100%"
      minHeight={"400px"}
      maxHeight={"750px"}
      width={"100%"}
      fontSize={"md"}
      overflowY={"scroll"}
      bg={LogBoxBgColor[colorMode]}
      borderColor={LogBorderColor[colorMode]}
      borderWidth={"1px"}
      shadow={"md"}
      marginBottom={"20px"}
      resize={"vertical"}
      borderBottomWidth="1px"
    >
      <Box position="sticky" top="0" zIndex="sticky">
        <HStack
          justify="space-between"
          backgroundColor={LogBoxBgColor[colorMode]}
          py={2}
        >
          <HStack justify={"start"}>
            <Text fontWeight="bold">
              {logBoxType.charAt(0).toUpperCase() + logBoxType.slice(1)}
              {logBoxType === "machine" ? " Setup" : ""} Logs
            </Text>
            {(logBoxType === "service" || logBoxType === "job") &&
              subtargetIds &&
              subtargetIds.length > 0 && (
                <>
                  <LiaSlashSolid size={20}/>
                  <Text fontSize="sm">
                    {logBoxType === "service" ? "replicas" : "nodes"} to
                    view logs for
                  </Text>
                  <Select
                    name={logBoxType === "service" ? "Replicas" : "Nodes"}
                    placeholder={
                      logBoxType === "service"
                        ? "Select replica"
                        : "Select node"
                    }
                    options={subtargetIds.map((id) => id.toString())}
                    defaultValue={subtargetIds.map((id) => id.toString())}
                    multiple
                    onChange={(selected) => {
                      setSubtargetsToLoad(
                        selected.map((value) => parseInt(value))
                      );
                    }}
                  >
                    <SelectButton />
                    <SelectList />
                  </Select>
                </>
              )}
          </HStack>

          <HStack spacing={4} justifyContent="end" paddingRight={2}>
            {logs.length !== 0 && (
              <Button
                variant="secondary"
                colorScheme="primary"
                onClick={handleClearLogsClick}
              >
                Clear
              </Button>
            )}
            <Select
              name="logsTimeFilter"
              placeholder="Time Filter"
              options={timeFilters.map((filter) => filter.label)}
              defaultValue={"Last 5 minutes"}
              onChange={handleTimeFilterSelectChange}
            >
              <SelectButton />
              <SelectList />
            </Select>
            <Button
              variant="secondary"
              colorScheme="primary"
              onClick={handleLoadLogsClick}
            >
              Load logs
            </Button>
            <Button
              variant="secondary"
              colorScheme="primary"
              onClick={handleTailLogsClick}
            >
              {tailLogs ? "Stop tailing" : "Tail logs"}
            </Button>
          </HStack>
        </HStack>
      </Box>
      {logs.length === 0 ? (
        loadLogs || tailLogs ? (
          <VStack align="center" spacing={4} p={10}>
            <LoadingSpinner size="sm" color="primary.500" />
            <Text>Loading logs...</Text>
          </VStack>
        ) : (
          <EmptyState
            colorScheme="primary"
            title="No logs yet"
            description="Logs will appear here."
            icon={ImFileEmpty}
            sx={{
              py: 10,
            }}
          />
        )
      ) : (
        <>
          {isFetchingMore && (
            <HStack justify="center" py={2}>
              <LoadingSpinner size="sm" color="primary.500" />
              <Text>Loading older logs...</Text>
            </HStack>
          )}
          {logs.map((log, index) => (
            <Text
              key={index}
              whiteSpace="pre-wrap"
              fontFamily="monospace"
              color={LogTextColor[colorMode]}
              bg={LogTextBackgroundColor[index % 2][colorMode]}
              fontSize="sm"
              sx={{
                textIndent: "-1.5em",
                paddingLeft: "1.5em",
              }}
            >
              <Text
                as="span"
                color={LogTimestampColor[colorMode]}
                fontFamily="monospace"
              >
                {`${new Date(log.timestamp).toDateString()}, ${new Date(
                  log.timestamp
                ).toLocaleTimeString()}: `}
              </Text>
              {log.replicaId !== undefined && (
                <Text
                  as="span"
                  color={LogTimestampColor[colorMode]}
                  fontFamily="monospace"
                >
                  {`[${logBoxType === 'job' ? 'node' : 'replica'} ${log.replicaId}] `}
                </Text>
              )}
              {log.message}
            </Text>
          ))}
        </>
      )}
    </Container>
  );
};

export default LogBox;
