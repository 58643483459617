import { ColorModeProvider } from "@chakra-ui/color-mode";
import { ModalsProvider, SaasProvider } from "@saas-ui/react";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import App from "./App";
import Billing from "./components/Billing";
import CreateMachineContainer from "./components/CreateMachineContainer";
import JobDetails from "./components/JobDetails";
import Jobs from "./components/Jobs";
import MachineDetails from "./components/MachineDetails";
import Machines from "./components/Machines";
import ServiceDetails from "./components/ServiceDetails";
import Services from "./components/Services";
import Settings from "./components/Settings";
import SignUp from "./components/SignUp";
import Team from "./components/Team";
import Usage from "./components/Usage";
import { BillingProvider } from "./providers/BillingContext";
import { CognitoProvider } from "./providers/CognitoContext";
import { ConnectedCloudProvider } from "./providers/ConnectedCloudContext";
import { JobsProvider } from "./providers/JobsContext";
import { MachinesProvider } from "./providers/MachinesContext";
import { ServicesProvider } from "./providers/ServicesContext";
import { SSHKeyProvider } from "./providers/SSHKeyContext";
import { TeamProvider } from "./providers/TeamContext";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import theme from "./theme";
import "@assistant-ui/react/styles/index.css";
import "@assistant-ui/react/styles/modal.css"; // if using AssistantModal
import "@assistant-ui/react-markdown/styles/markdown.css";
import StudioPage from "./components/Studio";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    // loader: rootLoader,
    children: [
      {
        index: true,
        element: <Navigate to="/studio" />,
      },
      {
        path: "/studio",
        element: (
          <TeamProvider>
            <ServicesProvider>
              <BillingProvider>
                <StudioPage />
              </BillingProvider>
            </ServicesProvider>
          </TeamProvider>
        ),
      },
      {
        path: "/sign-up",
        element: <SignUp />,
      },
      {
        path: "/jobs",
        element: (
          <JobsProvider>
            <Jobs />
          </JobsProvider>
        ),
      },
      // { path: "/jobs/new", element: <CreateJob /> },
      {
        path: "/jobs/:id",
        element: (
          <JobsProvider>
            <JobDetails />
          </JobsProvider>
        ),
      },
      {
        path: "/settings",
        element: (
          <ConnectedCloudProvider>
            <SSHKeyProvider>
              <Settings />
            </SSHKeyProvider>
          </ConnectedCloudProvider>
        ),
      },
      {
        path: "/machines",
        element: (
          <MachinesProvider>
            <Machines />
          </MachinesProvider>
        ),
      },
      {
        path: "/machines/:id",
        element: (
          <MachinesProvider>
            <MachineDetails />
          </MachinesProvider>
        ),
      },
      {
        path: "/machines/new",
        element: (
          <MachinesProvider>
            <ConnectedCloudProvider>
              <BillingProvider>
                <CreateMachineContainer />
              </BillingProvider>
            </ConnectedCloudProvider>
          </MachinesProvider>
        ),
      },
      {
        path: "/services",
        element: (
          <ServicesProvider>
            <Services />
          </ServicesProvider>
        ),
      },
      { path: "/services/:id", element: <ServiceDetails /> },
      {
        path: "/team",
        element: (
          <ConnectedCloudProvider>
            <Team />
          </ConnectedCloudProvider>
        ),
      },
      {
        path: "/billing",
        element: (
          <BillingProvider>
            <Billing />
          </BillingProvider>
        ),
      },
      { path: "/usage", element: <Usage /> },
      // { path: "/services/new", element: <></>},
    ],
  },
]);

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <ColorModeProvider>
      <SaasProvider theme={theme}>
        <CognitoProvider>
          <TeamProvider>
            <ModalsProvider>
              <RouterProvider router={router} />
            <Analytics />
            <SpeedInsights />
          </ModalsProvider>
          </TeamProvider>
        </CognitoProvider>
      </SaasProvider>
    </ColorModeProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
