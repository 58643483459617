import { CopyIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  Center,
  Code,
  Link,
  HStack,
  Icon,
  Input,
  Spacer,
  StackDivider,
  Text,
  VStack,
  useClipboard,
  useToast,
} from "@chakra-ui/react";
import {
  Beacon,
  Page,
  PageBody,
  PageHeader,
  Section,
  SectionBody,
  SectionDescription,
  SectionHeader,
  SectionTitle,
} from "@saas-ui-pro/react";
import {
  Field,
  Form,
  FormLayout,
  SubmitButton,
  useModals,
} from "@saas-ui/react";
import React, { useEffect } from "react";

import { FaAws, FaGoogle, FaMicrosoft } from "react-icons/fa";
import { SiKubernetes, SiMicrosoftazure } from "react-icons/si";

import { LoadingOverlay, LoadingSpinner, LoadingText } from "@saas-ui/react";
import { useState, useRef } from "react";
import { useCognito } from "../providers/CognitoContext";
import {
  ConnectGcp,
  ConnectLambda,
  ConnectRunPod,
  ConnectKubernetes,
  ConnectAzure,
} from "../providers/MetadataContext";

import {
  Link as ReactRouterLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { FiUpload } from "react-icons/fi";
import { useConnectedCloud } from "../providers/ConnectedCloudContext";
import { useSSHKey } from "../providers/SSHKeyContext";

const supportedCloudAccounts = [
  { name: "aws", displayName: "AWS" },
  { name: "azure", displayName: "Azure" },
  { name: "gcp", displayName: "Google Cloud" },
  { name: "kubernetes", displayName: "Kubernetes" },
  // { name: "lambda", displayName: "Lambda Labs" },
  // { name: "runpod", displayName: "RunPod" },
];

interface ConnectModalProps {
  fetchConnectedClouds: () => Promise<void>;
  navigate?: any;
  successRedirect?: string | null;
}

const ConnectLambdaModal: React.FC<ConnectModalProps> = ({
  fetchConnectedClouds,
  navigate,
  successRedirect,
}) => {
  const { user } = useCognito();
  const modals = useModals();
  const toast = useToast();
  const onSubmitLambdaLabsConnect = async (params: any) => {
    modals.closeAll();
    try {
      const _ = await ConnectLambda(params.lambda_labs_api_key, user);
      toast({
        title: "Lambda Labs connected",
        description: "You have successfully connected your Lambda Labs account",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      await fetchConnectedClouds();
      if (navigate && successRedirect) {
        navigate(`/${successRedirect}`);
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to connect your Lambda Labs account",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box maxW={"100%"}>
      <VStack spacing={4} align="stretch">
        <Form onSubmit={onSubmitLambdaLabsConnect}>
          <FormLayout>
            <Field
              name="lambda_labs_api_key"
              // label="API key"
              type="password"
              help="Enter your Lambda Labs API key above"
              isRequired={true}
            />
            <SubmitButton variant={"outline"} colorScheme="black">
              Connect
            </SubmitButton>
          </FormLayout>
        </Form>
        <Box overflow="hidden">
          <VStack spacing={2} align="stretch">
            <Text fontSize={"lg"}>To configure Lambda Cloud access:</Text>
            <Text fontSize={"md"}>
              1. Go to the{" "}
              <Link
                href="https://cloud.lambdalabs.com/api-keys"
                isExternal
                color="teal.500"
              >
                API Keys
              </Link>{" "}
              page on your Lambda console.
            </Text>
            <Text fontSize={"md"}>2. Generate a new API key.</Text>
            <Text fontSize={"md"}>3. Paste the generated key above</Text>
          </VStack>
        </Box>
      </VStack>
    </Box>
  );
};

const ConnectRunPodModal: React.FC<ConnectModalProps> = ({
  fetchConnectedClouds,
  navigate,
  successRedirect,
}) => {
  const { user } = useCognito();
  const modals = useModals();
  const toast = useToast();
  const onSubmitRunPodConnect = async (params: any) => {
    modals.closeAll();
    try {
      const _ = await ConnectRunPod(params.runpod_api_key, user);
      toast({
        title: "RunPod connected",
        description: "You have successfully connected your RunPod account",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      await fetchConnectedClouds();
      if (navigate && successRedirect) {
        navigate(`/${successRedirect}`);
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to connect your RunPod account",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box maxW={"100%"}>
      <VStack spacing={4} align="stretch">
        <Form onSubmit={onSubmitRunPodConnect}>
          <FormLayout>
            <Field
              name="runpod_api_key"
              // label="API key"
              type="password"
              help="Enter your RunPod API key above"
              isRequired={true}
            />
            <SubmitButton variant={"outline"} colorScheme="black">
              Connect
            </SubmitButton>
          </FormLayout>
        </Form>
        <Box overflow="hidden">
          <VStack spacing={2} align="stretch">
            <Text fontSize={"lg"}>To configure RunPod access:</Text>
            <Text fontSize={"md"}>
              1. Go to the{" "}
              <Link
                href="https://www.runpod.io/console/user/settings"
                isExternal
                color="teal.500"
              >
                RunPod settings
              </Link>{" "}
              page.
            </Text>
            <Text fontSize={"md"}>
              2. Generate a new API key in the API Keys section.
            </Text>
            <Text fontSize={"md"}>3. Paste the generated key above</Text>
          </VStack>
        </Box>
      </VStack>
    </Box>
  );
};

const ConnectGcpModal: React.FC<ConnectModalProps> = ({
  fetchConnectedClouds,
}) => {
  const { user } = useCognito();
  const modals = useModals();
  const toast = useToast();

  const [selectedGcpSACredentialsFile, setSelectedGcpSACredentialsFile] =
    useState<File>();
  const fileInputRef = useRef<HTMLInputElement>(null); // Reference to the file input
  const handleGcpSACredentialsUploadButtonClick = () => {
    fileInputRef.current?.click(); // Triggers the file input dialog
  };
  const handleGcpSACredentialsUploadFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedGcpSACredentialsFile(event.target.files[0]);
    }
  };

  const onSubmitGcpConnect = async (params: any) => {
    modals.closeAll();
    try {
      const _ = await ConnectGcp(user!, selectedGcpSACredentialsFile!);
      toast({
        title: "GCP connected",
        description: "You have successfully connected your GCP account",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      await fetchConnectedClouds();
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to connect your GCP account",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box maxW={"100%"} overflow={"scroll"}>
      <VStack spacing={4} align="stretch" overflow={"scroll"} maxW={"100%"}>
        <Text fontSize={"md"}>
          To connect your Google Cloud account, you need to create a service
          account and upload it's JSON key file.
        </Text>
        <HStack>
          <Button
            variant={"outline"}
            colorScheme="black"
            onClick={handleGcpSACredentialsUploadButtonClick}
            leftIcon={<Icon as={FiUpload} />}
          >
            Upload JSON Key File
          </Button>
          <Input
            id="file-upload"
            type="file"
            onChange={handleGcpSACredentialsUploadFileChange}
            multiple={false}
            ref={fileInputRef} // Associate the ref with the input
            p={1}
            w="full"
            // _hover={{ bg: hoverBgColor }}
            opacity={0} // Optionally hide the input if you want the button to be the only clickable element
            position="absolute"
            zIndex="-1"
            accept="application/json"
          />
          <Button
            key={selectedGcpSACredentialsFile ? "enabled" : "disabled"}
            variant={"outline"}
            colorScheme="primary"
            isDisabled={!selectedGcpSACredentialsFile}
            onClick={onSubmitGcpConnect}
          >
            Connect
          </Button>
        </HStack>
        <Text>
          {selectedGcpSACredentialsFile
            ? `File selected: ${selectedGcpSACredentialsFile.name}`
            : "No file selected"}
        </Text>
        <Text fontSize={"md"}>
          Peep the{" "}
          <Link
            href="https://docs.komodo.io/tutorials/connecting-clouds/gcp"
            isExternal
            color="teal.500"
          >
            instructions
          </Link>{" "}
          in our docs 👀
        </Text>
      </VStack>
      <VStack spacing={2} align="stretch">
        <Text>To configure Google Cloud access:</Text>
        <Text>
          1. Make sure Compute Engine API is enabled for your project. You can
          enable it or check it's status{" "}
          <Link
            href="https://console.cloud.google.com/apis/api/compute.googleapis.com/"
            isExternal
            color="teal.500"
          >
            here.
          </Link>
        </Text>
        <Text>
          2. Go to the{" "}
          <Link
            href="https://console.cloud.google.com/iam-admin/serviceaccounts"
            isExternal
            color="teal.500"
          >
            Service Accounts
          </Link>{" "}
          page on your Google Cloud console.
        </Text>
        <Text>
          3. Create a new service account and download the JSON key file.
        </Text>
      </VStack>
    </Box>
  );
};

const ConnectKubernetesModal: React.FC<ConnectModalProps> = ({
  fetchConnectedClouds,
  navigate,
  successRedirect,
}) => {
  const { user } = useCognito();
  const modals = useModals();
  const toast = useToast();

  const onSubmitKubernetesConnect = async (params: any) => {
    modals.closeAll();
    try {
      const _ = await ConnectKubernetes(
        user,
        params.cluster_host,
        params.komodo_sa_token,
        params.cluster_ca_data
      );
      toast({
        title: "Kubernetes connected",
        description: "You have successfully connected your Kubernetes cluster",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      await fetchConnectedClouds();
      if (navigate && successRedirect) {
        navigate(`/${successRedirect}`);
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to connect your Kubernetes cluster",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box maxW={"100%"}>
      <VStack spacing={4} align="stretch">
        <Text fontSize={"md"}>
          Follow the{" "}
          <Link
            href="https://docs.komodo.io/tutorials/connecting-clouds/kubernetes"
            isExternal
            color="teal.500"
          >
            docs
          </Link>{" "}
          to make sure your Kubernetes cluster is configured correctly and to
          get the required information.
        </Text>
        <Form onSubmit={onSubmitKubernetesConnect}>
          <FormLayout>
            <Field
              name="cluster_host"
              label="Host"
              // type="string"
              help="Enter your Kubernetes Cluster Host url"
              isRequired={true}
            />
            <Field
              name="komodo_sa_token"
              label="Komodo Service Account Token"
              // type="password"
              help="Enter your Komodo Service Account Token"
              isRequired={true}
            />
            <Field
              name="cluster_ca_data"
              label="Cluster Certificate Authority Data"
              // type="password"
              help="Enter your Kubernetes Cluster Certificate Authority data"
              isRequired={false}
            />
            <SubmitButton variant={"outline"} colorScheme="black">
              Connect
            </SubmitButton>
          </FormLayout>
        </Form>
      </VStack>
    </Box>
  );
};

const ConnectAzureModal: React.FC<ConnectModalProps> = ({
  fetchConnectedClouds,
  navigate,
  successRedirect,
}) => {
  const { user } = useCognito();
  const modals = useModals();
  const toast = useToast();

  const [selectedAzureSPCredentialsFile, setSelectedAzureSPCredentialsFile] =
    useState<File>();
  const fileInputRef = useRef<HTMLInputElement>(null); // Reference to the file input
  const handleAzureSPCredentialsUploadButtonClick = () => {
    fileInputRef.current?.click(); // Triggers the file input dialog
  };
  const handleAzureSPCredentialsUploadFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedAzureSPCredentialsFile(event.target.files[0]);
    }
  };

  const onSubmitAzureConnect = async (params: any) => {
    modals.closeAll();
    try {
      const _ = await ConnectAzure(user!, selectedAzureSPCredentialsFile!);
      toast({
        title: "Azure connected",
        description: "You have successfully connected your Azure account",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      await fetchConnectedClouds();
      if (navigate && successRedirect) {
        navigate(`/${successRedirect}`);
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to connect your Azure account",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box maxW={"100%"} overflow={"scroll"}>
      <VStack spacing={4} align="stretch" overflow={"scroll"} maxW={"100%"}>
        <Text fontSize={"md"}>
          To connect your Azure account, you need to create a service
          principal and upload it's credentials as a JSON file.
        </Text>
        <HStack>
          <Button
            variant={"outline"}
            colorScheme="black"
            onClick={handleAzureSPCredentialsUploadButtonClick}
            leftIcon={<Icon as={FiUpload} />}
          >
            Upload JSON Credentials File
          </Button>
          <Input
            id="file-upload"
            type="file"
            onChange={handleAzureSPCredentialsUploadFileChange}
            multiple={false}
            ref={fileInputRef} // Associate the ref with the input
            p={1}
            w="full"
            // _hover={{ bg: hoverBgColor }}
            opacity={0} // Optionally hide the input if you want the button to be the only clickable element
            position="absolute"
            zIndex="-1"
            accept="application/json"
          />
          <Button
            key={selectedAzureSPCredentialsFile ? "enabled" : "disabled"}
            variant={"outline"}
            colorScheme="primary"
            isDisabled={!selectedAzureSPCredentialsFile}
            onClick={onSubmitAzureConnect}
          >
            Connect
          </Button>
        </HStack>
        <Text>
          {selectedAzureSPCredentialsFile
            ? `File selected: ${selectedAzureSPCredentialsFile.name}`
            : "No file selected"}
        </Text>
        <Text fontSize={"md"}>
          Peep the{" "}
          <Link
            href="https://docs.komodo.io/tutorials/connecting-clouds/azure"
            isExternal
            color="teal.500"
          >
            instructions
          </Link>{" "}
          in our docs 👀
        </Text>
      </VStack>
    </Box>
  );
};

const Settings: React.FC = () => {
  const { user } = useCognito();
  const { connectedClouds, fetchConnectedClouds, isFetchingConnectedClouds } = useConnectedCloud();
  const { publicSshKey, fetchPublicSshKey, isFetchingPublicSshKey } = useSSHKey();
  const { hasCopied: hasCopiedApiKey, onCopy: onCopyApiKey } = useClipboard(
    user && user.komo_api_key ? user.komo_api_key : ""
  );
  const { hasCopied: hasCopiedSshKey, onCopy: onCopySshKey } =
    useClipboard(publicSshKey);

  const [isSshKeyVisible, setIsSshKeyVisible] = useState(false);
  const [isApiKeyVisible, setIsApiKeyVisible] = useState(false);

  let [searchParams] = useSearchParams();
  const referrer = searchParams.get("referrer");
  const successRedirect = searchParams.get("successRedirect");
  const navigate = useNavigate();

  const modals = useModals();

  const renderCloudAccount = (account: any) => {
    const { name, displayName } = account;
    const connected = connectedClouds.has(name);

    var accountId = null;
    var connectedCloud = null;
    var storageOnly = undefined;
    if (connected) {
      connectedCloud = connectedClouds.get(name);
      accountId = connectedCloud?.connected_cloud_id;
      storageOnly = connectedCloud?.storage_only;
    }
    let cloudIcon;

    switch (name) {
      case "aws":
        cloudIcon = <FaAws size={"1.5em"} />;
        break;
      case "gcp":
        cloudIcon = <FaGoogle size={"1.5em"} />;
        break;
      case "azure":
        cloudIcon = <SiMicrosoftazure size={"1.5em"} />;
        break;
      case "kubernetes":
        cloudIcon = <SiKubernetes size={"1.5em"} />;
        break;
      default:
        cloudIcon = null;
        break;
    }

    return (
      <Box
        key={name}
        p={4}
        borderRadius="lg"
        width="full"
        shadow="md"
        borderWidth={"1px"}
      >
        <HStack justifyContent="left">
          {cloudIcon}
          {cloudIcon === null && (
            <Text fontWeight="bold" minWidth={"88px"}>
              {displayName}
            </Text>
          )}
          {connected ? (
            <Badge colorScheme="green">Connected</Badge>
          ) : (
            <Badge colorScheme="red">Not Connected</Badge>
          )}
          {connected ? (
            name === "aws" ? (
              <>
                <Badge colorScheme="gray">Account ID: {accountId}</Badge>
                {storageOnly && <Badge colorScheme="blue">Storage Only</Badge>}
              </>
            ) : name === "lambda" ? (
              <Badge colorScheme="gray">Key ID: {accountId}</Badge>
            ) : name === "gcp" ? (
              <>
                <Badge colorScheme="gray">Project ID: {accountId}</Badge>
              </>
            ) : name === "kubernetes" ? (
              <>
                <Badge colorScheme="gray">Host: {accountId}</Badge>
              </>
            ) : name === "azure" ? (
              <>
                <Badge colorScheme="gray">Tenant ID: {accountId}</Badge>
              </>
            ) : (
              <></>
            )
          ) : name === "aws" ? (
            <VStack align="left">
              <HStack spacing={4}>
                <ReactRouterLink
                  to={`https://us-east-1.console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/create/review?templateURL=https://cf-templates-kc3z90t2fuad-us-east-1.s3.amazonaws.com/setup_cf.yaml&param_TeamId=${user?.team_id}&param_KomoJwtToken=${user?.komo_jwt_token}&param_KomoApiUrl=${process.env.REACT_APP_API_CORE_URL}&stackName=KomodoAI-${user?.team_id}`}
                  target="_blank"
                >
                  <Button variant={"outline"} colorScheme="black" isDisabled={!user!.access_approved}>
                    Connect
                  </Button>
                </ReactRouterLink>
                <ReactRouterLink
                  to={`https://us-east-1.console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/create/review?templateURL=https://cf-templates-kc3z90t2fuad-us-east-1.s3.amazonaws.com/komodo_aws_connect_s3_only.yaml&param_TeamId=${user?.team_id}&param_KomoJwtToken=${user?.komo_jwt_token}&param_KomoApiUrl=${process.env.REACT_APP_API_CORE_URL}&stackName=KomodoAI-S3-${user?.team_id}`}
                  target="_blank"
                >
                  <Button variant={"outline"} colorScheme="black" isDisabled={!user!.access_approved}>
                    Connect Storage Only
                  </Button>
                </ReactRouterLink>
              </HStack>
              <Text fontSize="sm">
                This will deploy a CloudFormation stack in your account. Use the "Storage Only" option if you want to mount S3 buckets on non-AWS instances.
              </Text>
            </VStack>
          ) : name === "lambda" ? (
            <Button
              variant={"outline"}
              colorScheme="black"
              onClick={() => {
                modals.open({
                  title: "Connect Lambda Labs",
                  body: (
                    <ConnectLambdaModal
                      fetchConnectedClouds={fetchConnectedClouds}
                      navigate={navigate}
                      successRedirect={successRedirect}
                    />
                  ),
                });
              }}
              isDisabled={!user!.access_approved}
            >
              Connect
            </Button>
          ) : name === "gcp" ? (
            <>
              <Button
                variant={"outline"}
                colorScheme="black"
                onClick={() =>
                  modals.open({
                    title: "Connect Google Cloud",
                    body: (
                      <ConnectGcpModal
                        fetchConnectedClouds={fetchConnectedClouds}
                      />
                    ),
                  })
                }
                isDisabled={!user!.access_approved}
              >
                Connect
              </Button>
            </>
          ) : name === "runpod" ? (
            <>
              <Button
                variant={"outline"}
                colorScheme="black"
                onClick={() =>
                  modals.open({
                    title: "Connect RunPod",
                    body: (
                      <ConnectRunPodModal
                        fetchConnectedClouds={fetchConnectedClouds}
                        navigate={navigate}
                        successRedirect={successRedirect}
                      />
                    ),
                  })
                }
                isDisabled={!user!.access_approved}
              >
                Connect
              </Button>
            </>
          ) : name === "kubernetes" ? (
            <>
              <Button
                variant={"outline"}
                colorScheme="black"
                onClick={() =>
                  modals.open({
                    title: "Connect Kubernetes",
                    body: (
                      <ConnectKubernetesModal
                        fetchConnectedClouds={fetchConnectedClouds}
                        navigate={navigate}
                        successRedirect={successRedirect}
                      />
                    ),
                  })
                }
                isDisabled={!user!.access_approved}
              >
                Connect
              </Button>
            </>
          ) : name === "azure" ? (
            <Button
              variant={"outline"}
              colorScheme="black"
              onClick={() =>
                modals.open({
                  title: "Connect Azure",
                  body: (
                    <ConnectAzureModal
                      fetchConnectedClouds={fetchConnectedClouds}
                    />
                  ),
                })
              }
              isDisabled={!user!.access_approved}
            >
              Connect
            </Button>
          ) : (
            <Badge colorScheme="purple">Coming Soon</Badge>
          )}
        </HStack>
        {!connected && name === "aws" ? <></> : !connected ? <></> : <></>}
      </Box>
    );
  };

  // https://eu-central-1.console.aws.amazon.com/cloudformation/home?region=eu-central-1#/stacks/create/review
  //  ?templateURL=https://s3.eu-central-1.amazonaws.com/cloudformation-templates-eu-central-1/WordPress_Single_Instance.template
  //  &stackName=MyWPBlog
  //  &param_DBName=mywpblog
  //  &param_InstanceType=t2.medium
  //  &param_KeyName=MyKeyPair

  const formatPrivateDataObscured = (key: string | undefined) => {
    if (!key) return "";
    // Show asterisks for the entire key
    return "*".repeat(key.length);
  };

  const formatPrivateDataPreview = (key: string, firstNChars: number) => {
    if (!key) return "";
    // Show the first 6 characters followed by asterisks
    return `${key.substring(0, firstNChars)}...`;
  };

  return (
    <Page variant={"settings"}>
      <PageHeader
        title={"Settings"}
        description={"Manage your settings & connect your accounts"}
      />
      <PageBody>
        <VStack
          divider={<StackDivider />}
          align="stretch"
          spacing={8}
          pb="16"
          fontSize={"md"}
        >
          <Section>
            <SectionHeader>
              <SectionTitle>
                <HStack>
                  <Text>Cloud Accounts</Text>
                  {referrer === "connect-cloud-account-banner" &&
                    connectedClouds.size === 0 && <Beacon></Beacon>}
                </HStack>
              </SectionTitle>
              <SectionDescription>
                Connect your cloud accounts to manage your resources
              </SectionDescription>
            </SectionHeader>
            <SectionBody>
              <VStack spacing={4} align="stretch">
                {!isFetchingConnectedClouds ? (
                  supportedCloudAccounts.map(renderCloudAccount)
                ) : (
                  <Center>
                    <LoadingOverlay>
                      <LoadingSpinner />
                      <LoadingText>Fetching cloud accounts...</LoadingText>
                    </LoadingOverlay>
                  </Center>
                )}
              </VStack>
            </SectionBody>
          </Section>
          <Section>
            <SectionHeader
              title="API Keys"
              description="API keys are used to authenticate with the Komodo API"
            />
            <SectionBody>
              <VStack spacing={4} align="stretch">
                <Text>
                  Run <Code>komo login</Code> and enter this API key when
                  prompted.
                </Text>
                <Spacer />
                <Box>
                  {user ? (
                    <>
                      <HStack>
                        <Button
                          onClick={() => setIsApiKeyVisible(!isApiKeyVisible)}
                          size="sm"
                          leftIcon={
                            isApiKeyVisible ? <ViewOffIcon /> : <ViewIcon />
                          }
                          isDisabled={!user!.access_approved}
                        >
                          {isApiKeyVisible ? "Hide API Key" : "Show API Key"}
                        </Button>
                        <Button onClick={onCopyApiKey} size="sm" p={2} isDisabled={!user!.access_approved}>
                          <Icon as={CopyIcon} mr={1} />{" "}
                          {hasCopiedApiKey ? "Copied" : "Copy"}
                        </Button>
                      </HStack>
                      <HStack spacing={2} mt={2}>
                        <Code p={2} borderRadius="md" fontSize="sm">
                          {isApiKeyVisible
                            ? user.komo_api_key
                            : formatPrivateDataObscured(user.komo_api_key)}
                        </Code>
                      </HStack>
                    </>
                  ) : (
                    <Center>
                      <LoadingSpinner />
                    </Center>
                  )}
                </Box>
              </VStack>
            </SectionBody>
          </Section>
          <Section>
            <SectionHeader
              title="Public SSH Key"
              description="A public part of your Komodo SSH key"
            />
            <SectionBody>
              <VStack spacing={4} align="stretch">
                <Text>
                  This SSH key is used to authenticate with your jobs and dev
                  machines. Add this key to your GitHub Account to be able to
                  push/pull your repos from your dev machines.
                </Text>
                <Box overflowY="auto" overflowX="hidden" maxW="100%">
                  {!isFetchingPublicSshKey ? (
                    <VStack align="start" spacing={2}>
                      {publicSshKey.length > 0 ? (
                        <>
                          <HStack>
                            <Button
                              onClick={() =>
                                setIsSshKeyVisible(!isSshKeyVisible)
                              }
                              leftIcon={
                                isSshKeyVisible ? <ViewOffIcon /> : <ViewIcon />
                              }
                              isDisabled={!user!.access_approved}
                            >
                              {isSshKeyVisible
                                ? "Hide SSH Key"
                                : "Show SSH Key"}
                            </Button>
                            <Button onClick={onCopySshKey} size="sm" isDisabled={!user!.access_approved}>
                              <Icon as={CopyIcon} mr={1} />{" "}
                              {hasCopiedSshKey ? "Copied" : "Copy"}
                            </Button>
                          </HStack>
                          <Code
                            p={2}
                            whiteSpace="pre-wrap"
                            wordBreak="break-all"
                            borderRadius="md"
                            fontSize="sm"
                          >
                            {isSshKeyVisible
                              ? publicSshKey
                              : formatPrivateDataPreview(publicSshKey, 40)}
                          </Code>
                        </>
                      ) : (
                        <Text>
                          No SSH key found. It will be generated when you create
                          a job or a machine.
                        </Text>
                      )}
                    </VStack>
                  ) : (
                    <Center>
                      <LoadingSpinner />
                    </Center>
                  )}
                </Box>
              </VStack>
            </SectionBody>
          </Section>
          {/* <Section>
            <SectionHeader
              title="Danger Zone"
              description="Delete your account"
            />
            <SectionBody>
              <VStack align={"left"}>
                <Text>
                  Once you delete your account, there is no going back. Contact <Code>kote@komodo.io</Code> if you want to delete your account.
                </Text>
                <Button colorScheme="red" variant={"secondary"}>
                  Delete Account
                </Button>
              </VStack>
            </SectionBody>
          </Section> */}
        </VStack>
      </PageBody>
    </Page>
  );
};

export default Settings;
