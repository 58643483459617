import {
  Box,
  Heading,
  HStack,
  IconButton,
  StackDivider,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  Text,
} from "@chakra-ui/react";
import axios from "axios";

import { Page, PageBody, PageHeader } from "@saas-ui-pro/react";
import { LoadingOverlay, LoadingSpinner, LoadingText } from "@saas-ui/react";
import "card-validator";
import React, { useEffect, useState } from "react";
import { FiRefreshCw } from "react-icons/fi";
import { GetUsageResponse } from "../providers/MetadataContext";
import { useCognito } from "../providers/CognitoContext";

function formatDuration(duration: number): string {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = duration % 60;

  if (hours > 0) {
    return `${hours}h ${minutes}m ${seconds}s`;
  } else if (minutes > 0) {
    return `${minutes}m ${seconds}s`;
  } else {
    return `${seconds}s`;
  }
}

function calculateTotalCost(response: GetUsageResponse): number {
  return response.data.reduce((total, item) => total + item.cost, 0);
}

const UsageComponent = ({ usage }: { usage: GetUsageResponse | null }) => {
  return (
    <Box overflowX="auto">
      <Table variant="simple" size="md">
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Num Nodes</Th>
            <Th>Duration</Th>
            <Th>Instance Type</Th>
            <Th>Cloud</Th>
            <Th>Cost ($)</Th>
          </Tr>
        </Thead>
        <Tbody>
          {usage &&
            usage.data.map((item) => (
              <Tr key={item.name}>
                <Td>{item.name}</Td>
                <Td>{item.num_nodes}</Td>
                <Td>{formatDuration(item.duration)}</Td>
                <Td>{item.instance_type}</Td>
                <Td>{item.cloud}</Td>
                <Td>{item.cost.toFixed(2)}</Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </Box>
  );
};

const Usage: React.FC = () => {
  const [loadingUsage, setLoadingUsage] = useState<boolean>(true);
  const [usage, setUsage] = useState<GetUsageResponse | undefined>(undefined);
  const [totalSpend, setTotalSpend] = useState<number>(0);
  const { user } = useCognito();

  const fetchUsage = async (): Promise<GetUsageResponse | undefined> => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_CORE_URL}/api/v1/usage`,
        {
          headers: {
            Authorization: `Bearer ${user?.komo_jwt_token}`,
          },
        }
      );
      if (response.status === 200) {
        return response.data;
      } else {
        return undefined;
      }
    } catch (error: any) {
      console.error("Error fetching usage", error);
      return undefined;
    }
  };

  const fetchData = async () => {
    setLoadingUsage(true);
    const response = await fetchUsage();
    if (!response) {
      return;
    }
    setUsage(response);
    setTotalSpend(calculateTotalCost(response));
    setLoadingUsage(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Page variant={"settings"}>
      <PageHeader
        title={
          <HStack spacing={2} maxW={"100%"}>
            <Heading size="lg" fontSize={"2xl"}>
              Usage
            </Heading>
            <Box>
              <IconButton
                icon={<FiRefreshCw />}
                onClick={fetchData}
                aria-label="Refresh data"
                fontSize={"md"}
                sx={{ "background-color": "transparent" }}
                isLoading={loadingUsage}
              />
            </Box>
          </HStack>
        }
      />
      <PageBody>
        <VStack
          divider={<StackDivider />}
          align={"stretch"}
          spacing={8}
          pb={"16"}
          fontSize={"md"}
          width="100%"
        >
          {loadingUsage ? (
            <LoadingOverlay>
              <LoadingSpinner size="lg" color="primary.500" />
              <LoadingText>Loading usage data...</LoadingText>
            </LoadingOverlay>
          ) : (
            usage && (
              <>
                <Text>Total Spend: ${totalSpend.toFixed(2)}</Text>
                <UsageComponent usage={usage} />
              </>
            )
          )}
        </VStack>
      </PageBody>
    </Page>
  );
};

export default Usage;
