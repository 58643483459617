import { extendTheme } from "@chakra-ui/react";
import { theme as proTheme } from "@saas-ui-pro/react";

const theme = extendTheme(
  {
    colors: {
      default: {
        "50": "#F8E6FF",
        "100": "#EBB9FE",
        "200": "#DD8CFD",
        "300": "#D05FFC",
        "400": "#C332FB",
        "500": "#9204C8",
        "600": "#9204C8",
        "700": "#6D0396",
        "800": "#490264",
        "900": "#240132",
      },
      _dark: {
        primary: {
          "50": "#E0D0FF",
          "100": "#C79AFE",
          "200": "#AF65FD",
          "300": "#9730FC",
          "400": "#7E00FB",
          "500": "#6600C8",
          "600": "#5400A4",
          "700": "#420080",
          "800": "#31005B",
          "900": "#1F0037",
        },
      },
    },
    config: {
      initalColorMode: "system",
      useSystemColorMode: true,
    },
    styles: {
      global: {
        body: {
          default: "#fff",
          _dark: "#0a0a0a",
        },
      },
    },
    semanticTokens: {
      colors: {
        "app-background": {
          default: "#fff",
          _dark: "#0a0a0a",
        },
        "navbar-bg": {
          default: "#fff",
          _dark: "#0a0a0a",
        },
        "chakra-body-bg": {
          default: "#fff",
          _dark: "#0a0a0a",
        },
        "sidebar-background": {
          default: "#fff",
          _dark: "#0a0a0a",
        },
      },
    },
  },
  proTheme
);

export default theme;

const LogBoxBgColor = { light: "gray.50", dark: "gray.700" };
const LogBorderColor = { light: "gray.200", dark: "gray.600" };
const LogTextColor = { light: "black", dark: "white" };
const LogTimestampColor = { light: "orange.500", dark: "orange.300" };
const LogTextBackgroundColor: Record<number, any> = {
  0: { light: "gray.100", dark: "gray.700" },
  1: { light: "white", dark: "gray.600" },
};

export {
  LogBorderColor, LogBoxBgColor, LogTextBackgroundColor, LogTextColor, LogTimestampColor
};
