import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Icon,
  SimpleGrid,
  Text,
  VStack,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import {
  AuthFormContainer,
  AuthFormDivider,
  AuthFormTitle,
  PasswordForm,
  ProviderButton,
} from "@saas-ui/auth";
import {
  AppShell,
  LoadingOverlay,
  LoadingSpinner,
  LoadingText,
} from "@saas-ui/react";
import posthog from "posthog-js";
import "posthog-js/dist/recorder";
import React from "react";
import { FaGoogle } from "react-icons/fa";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import AppNavbar from "./components/AppNavbar";
import AppSidebar from "./components/AppSidebar";
import Footer from "./components/Footer";
import SignUp from "./components/SignUp";
import { useCognito } from "./providers/CognitoContext";
import { TeamProvider } from "./providers/TeamContext";
import AccountNotApprovedBanner from "./components/AccountNotApprovedBanner";
import PaymentValidationFailedBanner from "./components/PaymentValidationFailed";
import { useTeam } from "./providers/TeamContext";
import { PaymentValidationStatus } from "./types/types";

if (
  !window.location.host.includes("127.0.0.1") &&
  !window.location.host.includes("localhost")
) {
  posthog.init("phc_Hgrd8HvETx3yFnQoA9IGH2LmChf4YDOzlcoSFh8wvhB", {
    api_host: "https://d2ie3zhf9mboct.cloudfront.net",
    ui_host: "https://us.posthog.com",
    person_profiles: "identified_only",
  });
}

const App: React.FC = () => {
  const { colorMode } = useColorMode();
  const {
    cognitoLoading,
    signedIn,
    signInGoogle,
    signOut,
    user,
    signingUp,
    setSigningUp,
    signInCognitoEmailPassword,
    isFetchingUserFromDb,
  } = useCognito();
  const { team } = useTeam();
  const navigate = useNavigate();

  if (signingUp) {
    return <SignUp />;
  }

  const displayLoadingScreen = cognitoLoading || isFetchingUserFromDb;
  if (displayLoadingScreen) {
    return (
      <Flex height="100vh" alignItems="center" justifyContent="center">
        <LoadingOverlay>
          <LoadingSpinner size="lg" color="primary.500" />
          {cognitoLoading || isFetchingUserFromDb ? (
            <LoadingText>Logging in...</LoadingText>
          ) : (
            <LoadingText>Loading metadata...</LoadingText>
          )}
        </LoadingOverlay>
      </Flex>
    );
  }

  if (!signedIn) {
    return (
      <Flex
        height="100vh"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex
          height="100vh"
          alignItems="center"
          justifyContent="center"
          padding={{ base: "2rem", md: "0" }}
        >
          <Card flex="1" maxW="400px">
            <CardHeader
              display="flex"
              alignItems="center"
              justifyContent="center"
              paddingBottom={0}
            >
              <VStack margin={0}>
                <img
                  src={colorMode === "light" ? "/light.svg" : "/dark.svg"}
                  style={{ width: "80%" }}
                />
                <Text fontSize={"lg"}>
                  Get up to 10 hours of GPU time for free
                </Text>
                <Text>No credit card required.</Text>
              </VStack>
            </CardHeader>
            <CardBody paddingTop={0}>
              <AuthFormContainer>
                <AuthFormTitle>
                  <></>
                </AuthFormTitle>
                <SimpleGrid spacing={4}>
                  <ProviderButton
                    name="Google"
                    leftIcon={<Icon as={FaGoogle} />}
                    onClick={() => {
                      signInGoogle();
                    }}
                  >
                    Continue with Google
                  </ProviderButton>
                </SimpleGrid>
                <AuthFormDivider />
                <PasswordForm
                  onSubmit={async (data) => {
                    await signInCognitoEmailPassword(data.email, data.password);
                  }}
                ></PasswordForm>
                <AuthFormDivider></AuthFormDivider>
                <Button
                  variant="outline"
                  onClick={() => {
                    setSigningUp(true);
                    navigate("/sign-up");
                  }}
                >
                  Register with email
                </Button>
              </AuthFormContainer>
            </CardBody>
          </Card>
        </Flex>
        <Footer />
      </Flex>
    );
  }

  if (signedIn && user) {
    posthog.identify(user.id, {
      email: user.email,
      name: user.given_name + " " + user.family_name,
    });
  }

  return (
    <AppShell
      variant={"fullscreen"}
      height="$100vh"
      sidebar={
        <TeamProvider>
          <AppSidebar />
        </TeamProvider>
      }
      navbar={<AppNavbar />}
      footer={<Footer />}
    >
      {user && !user.access_approved && <AccountNotApprovedBanner />}
      {team &&
        team.payment_validation_status === PaymentValidationStatus.FAILED && (
          <PaymentValidationFailedBanner team={team} />
        )}
      <Outlet />
    </AppShell>
  );
};

export default App;
