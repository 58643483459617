import {
  Badge,
  Box,
  Button,
  Code,
  Container,
  HStack,
  IconButton,
  Kbd,
  SimpleGrid,
  Text,
  VStack,
  useToast
} from "@chakra-ui/react";
import { BackButton, Page, PageHeader, Toolbar } from "@saas-ui-pro/react";
import { EmptyState, LoadingSpinner, useModals } from "@saas-ui/react";
import React, { useEffect, useState } from "react";
import { FiRefreshCw } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { useCognito } from "../providers/CognitoContext";
import { CancelJob, GetJobById } from "../providers/JobsContext";
import {
  Job,
  KomodoStatusColorScheme,
  KomodoStatusToString,
} from "../types/types";
import LogBox from "./LogBox";
import { Networking, RequestedResources, ScriptModalBody, ScriptModalContentProps } from "./common";

const JobDetails: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = useCognito();
  const modals = useModals();
  const toast = useToast();

  const job_id = location.pathname.split("/").pop();
  const [job, setJob] = useState<Job | undefined>(undefined);
  const [isLoadingJob, setIsLoadingJob] = useState(false);
  const [jobNotFound, setJobNotFound] = useState(false);

  useEffect(() => {
    const fetchJobDetails = async () => {
      if (!job_id) return;

      setIsLoadingJob(true); // Start loading

      try {
        const fetchedJob = await GetJobById(job_id, user);
        if (fetchedJob) {
          setJob(fetchedJob);
          setJobNotFound(false);
        } else {
          // Optionally handle not found state separately
          console.log(`Job ${job_id} not found in API`);
          setJobNotFound(true);
        }
      } catch (error) {
        console.error("Error fetching job details", error);
      } finally {
        setIsLoadingJob(false); // End loading
      }
    };

    fetchJobDetails();
  }, [job_id, user]);

  const refreshJobDetails = async () => {
    setIsLoadingJob(true);
    const updatedJob = await GetJobById(job_id!, user);
    if (updatedJob) {
      setJob(updatedJob); // This will trigger a re-render
      setJobNotFound(false);
    } else {
      console.log(`Job ${job_id} not found in API`);
      setJobNotFound(true);
    }
    setIsLoadingJob(false);
  };

  const handleCancelJob = async () => {
    // if (job?.status !== KomodoStatus.Running) {
    //   toast({
    //     title: "Job cannot be terminated.",
    //     description: "The job is not in a terminable state.",
    //     status: "error",
    //     duration: 9000,
    //     isClosable: true,
    //   });
    //   return;
    // }
    const response = await CancelJob(job_id!, user);
    if (response && response.status === 200) {
      // Assuming a 200 status code for success
      toast({
        title: "Job terminated.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      navigate("/jobs");
    } else {
      // Handle error case
      toast({
        title: "Error terminating job.",
        description: response
          ? response.data
          : "There was an error terminating the job. Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    // if job is undefined show 404
    // if job is defined show the job details
    <Page overflow={"scroll"}>
      {isLoadingJob ? (
        <VStack align="center" spacing={4} p={10}>
          <LoadingSpinner size="lg" color="primary.500"/>
          <Text>Loading job details...</Text>
        </VStack>
      ) : jobNotFound ? (
        <Container maxW="container.xl" p={5}>
          <EmptyState
            colorScheme="red"
            title="404: Job Not Found"
            description="The job you are looking for does not exist or may have been removed."
            actions={
              <Button variant={"primary"} onClick={() => navigate("/jobs")}>
                Go Back
              </Button>
            }
          />
        </Container>
      ) : !isLoadingJob && !jobNotFound && job ? (
        <>
          <PageHeader
            title={
              <>
                <HStack>
                  <Text>{job.name}</Text>
                  <Badge colorScheme={KomodoStatusColorScheme[job.status]}>
                    {KomodoStatusToString[job.status]}
                  </Badge>
                </HStack>
              </>
            }
            nav={
              <BackButton
                onClick={() => {
                  navigate("/jobs");
                }}
              />
            }
            toolbar={
              <Toolbar>
                <IconButton
                  icon={<FiRefreshCw />}
                  onClick={refreshJobDetails}
                  aria-label="Refresh job"
                />
                <Button
                  variant="secondary"
                  onClick={() => {
                    modals.confirm({
                      title: "Terminate Job",
                      body: (
                        <VStack align="start" width={"full"} p={5}>
                          <Text>
                            Job <b>{job_id}</b>
                          </Text>
                          <Text>
                            Are you sure you want to terminate this job? This
                            cannot be undone.
                          </Text>
                        </VStack>
                      ),
                      confirmProps: {
                        colorScheme: "red",
                      },
                      onConfirm: () => {
                        handleCancelJob();
                      },
                    });
                  }}
                >
                  Terminate
                </Button>
              </Toolbar>
            }
          />
          <Container
            maxWidth={"container.xl"}
            height={"100vh"}
            width={"100%"}
            p={5}
            fontSize={"md"}
            overflowY={"auto"}
          >
            {/* Top-level job details */}
            <VStack align="stretch" spacing={4} height={"calc(100vh)"}>
              {/* Adjusted top-level job details */}
              <Box p={5} shadow="sm" borderWidth="1px">
                <SimpleGrid columns={[1, null, 2]} spacing={10}>
                  <VStack align="start" spacing={4}>
                    {/* Job ID */}
                    <Text fontWeight="bold">
                      Job ID: <Code>{job.id}</Code>
                    </Text>
                    {/* Status Message */}
                    {job.statusMessage && (
                      <Box
                        p={4}
                        borderWidth="1px"
                        borderRadius="lg"
                        borderColor={`${
                          KomodoStatusColorScheme[job.status]
                        }.500`}
                      >
                        <Badge
                          colorScheme={KomodoStatusColorScheme[job.status]}
                          borderRadius="full"
                          px={2}
                          py={1}
                          mr={2}
                          fontSize="0.8em" // Adjust font size as needed
                        >
                          {KomodoStatusToString[job.status]}
                        </Badge>
                        <Text
                          as="span" // Using Text as span allows for inline display with wrapping
                          fontSize="md" // Adjust font size as needed
                        >
                          {job.statusMessage}
                        </Text>
                      </Box>
                    )}
                    <VStack align="left" spacing={0}>
                      <Text>
                        Created:{" "}
                        {new Date(job.createdTimestamp * 1000).toLocaleString()}
                      </Text>
                      <Text>
                        Updated:{" "}
                        {new Date(job.updatedTimestamp * 1000).toLocaleString()}
                      </Text>
                    </VStack>
                    {/* Resources Section with aligned labels */}
                    <Box>
                      <Text fontWeight="bold">Resources</Text>
                      <VStack align="start" spacing={1}>
                        <HStack justify="start" w="100%">
                          <Text minW="82px">Nodes:</Text>{" "}
                          {/* Adjust the minW as needed */}
                          <Badge>{job.numNodes}</Badge>
                        </HStack>
                        <HStack justify="start" w="100%">
                          <Text minW="82px">Accelerators:</Text>{" "}
                          {/* Adjust the minW as needed */}
                          <Code>{job.accelerators ? job.accelerators : "n/a"}</Code>
                        </HStack>
                        {job.requestedResources?.image_id && (
                          <HStack justify="start" w="100%">
                            <Text minW="82px">Image:</Text>{" "}
                            <Code>{job.requestedResources?.image_id}</Code>
                          </HStack>
                        )}
                        <HStack justify="start" w="100%">
                          <Text minW="82px">{`Disk Size:`}</Text>{" "}
                          {/* Adjust the minW as needed */}
                          <Code>{job.diskSize ? job.diskSize : "n/a"}</Code>
                        </HStack>
                      </VStack>
                    </Box>
                    <Networking komodoResource={job} />
                    <Box>
                      <Text fontWeight={"bold"}>File Mounts</Text>
                      <HStack justify="start" w="100%" align={"top"}>
                        <VStack spacing={1} align="start" marginLeft={"20px"}>
                          {Object.entries(job.fileMounts).map(
                            ([key, value]) => (
                              <VStack key={key} align="start" spacing={1}>
                                <Code fontWeight="bold">{key}:</Code>
                                <HStack>
                                  <Box minW={"45px"}>
                                    <Text fontSize="sm" as="span">
                                      Source:
                                    </Text>
                                  </Box>
                                  <Code>{value.source}</Code>
                                </HStack>
                                <HStack>
                                  <Box minW={"45px"}>
                                    <Text fontSize="sm" as="span">
                                      Mode:
                                    </Text>
                                  </Box>
                                  <Code>{value.mode}</Code>
                                </HStack>
                              </VStack>
                            )
                          )}
                        </VStack>
                      </HStack>
                    </Box>
                  </VStack>
                  <VStack align="start" spacing={4}>
                    {/* Cloud Details Section with aligned labels */}
                    <Box>
                      <Text fontWeight="bold">Cloud Details</Text>
                      <VStack align="start" spacing={1}>
                        <HStack justify="start" w="100%">
                          <Text minW="92px">Cloud:</Text>{" "}
                          {/* Adjust the minW as needed */}
                          <Code>{job.cloud ? job.cloud : "n/a"}</Code>
                        </HStack>
                        <HStack justify="start" w="100%">
                          <Text minW="92px">Instance Type:</Text>{" "}
                          {/* Adjust the minW as needed */}
                          <HStack wrap={"wrap"}>
                            <Code>
                              {job.instanceType ? job.instanceType : "n/a"}
                            </Code>
                            <Badge>{job.spot ? "Spot" : "On-Demand"}</Badge>
                          </HStack>
                        </HStack>
                        <HStack justify="start" w="100%">
                          <Text minW="92px">Region:</Text>{" "}
                          <Code>{job.region ? job.region : "n/a"}</Code>
                        </HStack>
                        <HStack justify="start" w="100%">
                          <Text minW="92px">Zone:</Text>{" "}
                          <Code>{job.zone ? job.zone : "n/a"}</Code>
                        </HStack>
                      </VStack>
                    </Box>
                    <Text fontWeight="bold">Task</Text>
                    <Box>
                      <HStack justify="start" w="100%" wrap={"wrap"}>
                        <Button
                          onClick={() =>
                            modals.open({
                              title: "Run Script",
                              body: <ScriptModalBody script={job.run} />,
                              contentProps: ScriptModalContentProps,
                            })
                          }
                        >
                          Run Script
                        </Button>
                        <Button
                          onClick={() =>
                            modals.open({
                              title: "Setup Script",
                              body: <ScriptModalBody script={job.setup} />,
                              contentProps: ScriptModalContentProps,
                            })
                          }
                        >
                          Setup Script
                        </Button>
                        <Button
                          onClick={() =>
                            modals.open({
                              title: "Environment Variables",
                              body: (
                                <VStack align="start" width={"full"}>
                                  {Object.entries(job.envs).map(
                                    ([key, value]) => (
                                      <Text key={key}>
                                        <Kbd>{key}</Kbd> ={" "}
                                        <Code>{value.toString()}</Code>
                                      </Text>
                                    )
                                  )}
                                </VStack>
                              ),
                            })
                          }
                        >
                          Env Vars
                        </Button>
                        <Button
                          onClick={() =>
                            modals.open({
                              title: "Requested Resources",
                              body: (
                                <RequestedResources
                                  requestedResources={job.requestedResources}
                                />
                              ),
                            })
                          }
                        >
                          Requested Resources
                        </Button>
                      </HStack>
                    </Box>
                  </VStack>
                </SimpleGrid>
              </Box>
              <LogBox
                logBoxType="job"
                targetId={job_id!}
                targetObj={job}
                subtargetIds={Array.from({length: job.numNodes}, (_, i) => i)}
              />
            </VStack>
          </Container>
        </>
      ) : null}
    </Page>
  );
};

export default JobDetails;
