import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Button,
  Code,
  Container,
  HStack,
  IconButton,
  Kbd,
  Link,
  SimpleGrid,
  Spacer,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { BackButton, Page, PageHeader, Toolbar } from "@saas-ui-pro/react";
import { EmptyState, LoadingSpinner, useModals } from "@saas-ui/react";
import React, { useEffect, useState } from "react";
import { FiRefreshCw } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { useCognito } from "../providers/CognitoContext";
import {
  GetServiceById,
  GetServiceReplicas,
  TerminateService,
} from "../providers/ServicesContext";
import {
  KomodoService,
  KomodoServiceReplicaList,
  KomodoServiceReplicaStatus,
  KomodoServiceReplicaStatusColorScheme,
  KomodoServiceReplicaStatusToString,
  KomodoServiceStatus,
  KomodoServiceStatusColorScheme,
  KomodoServiceStatusToString,
  StringToKomodoServiceReplicaStatus,
} from "../types/types";
import KomodoChat from "./ChatLLM";
import LogBox from "./LogBox";
import {
  RequestedResources,
  ScriptModalBody,
  ScriptModalContentProps,
} from "./common";

const ServiceDetails: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = useCognito();
  const modals = useModals();
  const toast = useToast();

  const service_id = location.pathname.split("/").pop();
  const [service, setService] = useState<KomodoService | null>(null);
  const [replicas, setReplicas] = useState<KomodoServiceReplicaList | null>(
    null
  );
  const [isLoadingService, setIsLoadingService] = useState<boolean>(true);
  const [serviceNotFound, setServiceNotFound] = useState<boolean>(false);
  const [serviceReplicasNotFound, setServiceReplicasNotFound] =
    useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const fetchServiceDetails = async () => {
    if (!service_id) {
      setServiceNotFound(true);
      return;
    }

    setIsLoadingService(true);
    try {
      const service = await GetServiceById(service_id, user);
      if (service) {
        setService(service);
        setServiceNotFound(false);
        const serviceReplicas = await GetServiceReplicas(service_id, user);
        if (serviceReplicas) {
          setReplicas(serviceReplicas);
        } else {
          console.log(`Service replicas for ${service_id} not found`);
          setServiceReplicasNotFound(true);
        }
      } else {
        console.log(`Service ${service_id} not found`);
        setServiceNotFound(true);
      }
    } catch (error) {
      setServiceNotFound(true);
    } finally {
      setIsLoadingService(false);
    }
  };

  useEffect(() => {
    fetchServiceDetails();
  }, []);

  const refreshServiceDetails = async () => {
    fetchServiceDetails();
  };

  const handleTerminateService = async () => {
    // if (service?.status !== KomodoServiceStatus.READY) {
    //   toast({
    //     title: "Service must be in READY state to terminate",
    //     status: "error",
    //     duration: 5000,
    //     isClosable: true,
    //   });
    //   return;
    // }

    const response = await TerminateService(service_id!, user);
    if (response && response.status === 200) {
      toast({
        title: "Service terminated",
        description: `Service ${service_id} has been scheduled for termination`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/services");
    } else {
      toast({
        title: "Error terminating service",
        description: response
          ? response.data
          : "There was an error terminating the service. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Page overflow={"scroll"} overflowY={"scroll"}>
      {isLoadingService ? (
        <VStack align="center" spacing={4} p={10}>
          <LoadingSpinner size="lg" color="primary.500" />
          <Text>Loading service details...</Text>
        </VStack>
      ) : serviceNotFound ? (
        <Container maxW="container.xl" p={5} overflowY={"scroll"}>
          <EmptyState
            colorScheme="red"
            title="404: Service Not Found"
            description="The service you are looking for does not exist or may have been removed."
            actions={
              <Button variant={"primary"} onClick={() => navigate("/services")}>
                Go Back
              </Button>
            }
          />
        </Container>
      ) : !isLoadingService && !serviceNotFound && service ? (
        <>
          <PageHeader
            title={
              <>
                <HStack>
                  <Text>{service.name}</Text>
                  <Badge
                    colorScheme={KomodoServiceStatusColorScheme[service.status]}
                  >
                    {KomodoServiceStatusToString[service.status]}
                  </Badge>
                </HStack>
              </>
            }
            nav={<BackButton onClick={() => navigate("/services")} />}
            toolbar={
              <Toolbar>
                <IconButton
                  aria-label="Refresh"
                  icon={<FiRefreshCw />}
                  onClick={refreshServiceDetails}
                />
                <Button
                  variant="secondary"
                  onClick={() => {
                    modals.confirm({
                      title: "Terminate Service",
                      body: (
                        <VStack align="start" width={"full"} p={5}>
                          <Text>
                            Service <b>{service_id}</b>
                          </Text>
                          <Text>
                            Are you sure you want to terminate this service?
                            This cannot be undone.
                          </Text>
                        </VStack>
                      ),
                      confirmProps: {
                        colorScheme: "red",
                      },
                      onConfirm: () => {
                        handleTerminateService();
                      },
                    });
                  }}
                >
                  Terminate
                </Button>
              </Toolbar>
            }
          />
          <Container
            maxWidth="container.xl"
            width={"100%"}
            p={5}
            fontSize={"md"}
            overflowY={"scroll"}
          >
            <VStack align="stretch" spacing={4}>
              <Box p={5} shadow="md" borderWidth="1px">
                <SimpleGrid columns={[1, null, 2]} spacing={10}>
                  <VStack align="start" spacing={4}>
                    <Text fontWeight="bold">
                      Service ID: <Code>{service.id}</Code>
                    </Text>
                    {/* Status Message */}
                    {service.statusMessage && (
                      <Box
                        p={4}
                        borderWidth="1px"
                        borderRadius="lg"
                        borderColor={`${
                          KomodoServiceStatusColorScheme[service.status]
                        }.500`}
                      >
                        <Badge
                          colorScheme={
                            KomodoServiceStatusColorScheme[service.status]
                          }
                          borderRadius="full"
                          px={2}
                          py={1}
                          mr={2}
                          fontSize="0.8em" // Adjust font size as needed
                        >
                          {KomodoServiceStatusToString[service.status]}
                        </Badge>
                        <Text
                          as="span" // Using Text as span allows for inline display with wrapping
                          fontSize="md" // Adjust font size as needed
                        >
                          {service.statusMessage}
                        </Text>
                      </Box>
                    )}

                    {service.createdTimestamp && (
                      <VStack align="start" spacing={0}>
                        <HStack>
                          <Text minWidth={"60px"}>Created:</Text>
                          <Code>
                            {" "}
                            {new Date(
                              service.createdTimestamp * 1000
                            ).toLocaleString()}
                          </Code>
                        </HStack>
                        <HStack>
                          <Text minWidth={"60px"}>Updated:</Text>
                          <Code>
                            {" "}
                            {new Date(
                              service.updatedTimestamp * 1000
                            ).toLocaleString()}
                          </Code>
                        </HStack>
                      </VStack>
                    )}
                    {/* Resources Section with aligned labels */}

                    <Box>
                      <Text fontWeight={"bold"}>File Mounts</Text>
                      <HStack justify="start" w="100%" align={"top"}>
                        <VStack spacing={1} align="start" marginLeft={"20px"}>
                          {Object.entries(service.fileMounts).map(
                            ([key, value]) => (
                              <VStack key={key} align="start" spacing={1}>
                                <Code fontWeight="bold">{key}:</Code>
                                <HStack>
                                  <Box minW={"45px"}>
                                    <Text fontSize="sm" as="span">
                                      Source:
                                    </Text>
                                  </Box>
                                  <Code>{value.source}</Code>
                                </HStack>
                                <HStack>
                                  <Box minW={"45px"}>
                                    <Text fontSize="sm" as="span">
                                      Mode:
                                    </Text>
                                  </Box>
                                  <Code>{value.mode}</Code>
                                </HStack>
                              </VStack>
                            )
                          )}
                        </VStack>
                      </HStack>
                    </Box>
                    <Box>
                      <VStack align={"start"} spacing={1}>
                        <Text fontWeight={"bold"}>Endpoint: </Text>
                        <Text maxWidth={"full"}>
                          <Code w="full" wordBreak="break-word">
                            <Link
                              href={`${service.url}`}
                              isExternal
                              fontWeight={"normal"}
                            >
                              {`${service.url}`}
                            </Link>
                          </Code>
                        </Text>
                      </VStack>
                    </Box>
                  </VStack>
                  <VStack align="start" spacing={4}>
                    <Box>
                      <HStack justify="start" w="100%" wrap="wrap">
                        <Button
                          onClick={() =>
                            modals.open({
                              title: "Run Script",
                              body: <ScriptModalBody script={service.run} />,
                              contentProps: ScriptModalContentProps,
                            })
                          }
                        >
                          Run Script
                        </Button>
                        <Button
                          onClick={() =>
                            modals.open({
                              title: "Setup Script",
                              body: <ScriptModalBody script={service.setup} />,
                              contentProps: ScriptModalContentProps,
                            })
                          }
                        >
                          Setup Script
                        </Button>
                        <Button
                          onClick={() =>
                            modals.open({
                              title: "Environment Variables",
                              body: (
                                <VStack align="start" width={"full"}>
                                  {Object.entries(service.envs).map(
                                    ([key, value]) => (
                                      <Text key={key}>
                                        <Kbd>{key}</Kbd> ={" "}
                                        <Code>{value.toString()}</Code>
                                      </Text>
                                    )
                                  )}
                                </VStack>
                              ),
                            })
                          }
                        >
                          Env Vars
                        </Button>
                        <Button
                          onClick={() =>
                            modals.open({
                              title: "Requested Resources",
                              body: (
                                <RequestedResources
                                  requestedResources={
                                    service.requestedResources
                                  }
                                />
                              ),
                            })
                          }
                        >
                          Requested Resources
                        </Button>
                      </HStack>
                    </Box>
                    <Box width={"100%"}>
                      <HStack width={"100%"} spacing={10} wrap={"wrap"}>
                        <Box>
                          <Text fontWeight="bold">Resources</Text>
                          <VStack align="start" spacing={1}>
                            <HStack justify="start" w="100%">
                              <Text minW="65px">Nodes:</Text>{" "}
                              {/* Adjust the minW as needed */}
                              <Badge>{service.numNodes}</Badge>
                            </HStack>
                          </VStack>
                        </Box>
                        <Box>
                          <Text fontWeight={"bold"}>Versions</Text>
                          <VStack align={"start"} spacing={1}>
                            <HStack>
                              {/* <VStack>
                                <Text minWidth={"50px"}>Current</Text>
                                <Code>{service.currentVersion}</Code>
                                </VStack>
                              */}
                              <Text minWidth={"50px"}>Active</Text>
                              <Code>{service.activeVersions}</Code>
                            </HStack>
                          </VStack>
                        </Box>
                      </HStack>
                    </Box>
                    <KomodoChat service={service} />
                  </VStack>
                </SimpleGrid>
              </Box>
              <Box p={5} shadow="md" borderWidth="1px">
                <Text fontSize="lg" fontWeight="bold">
                  Info
                </Text>
                <SimpleGrid
                  columns={[1, null, 2]}
                  spacing={10}
                  paddingBottom={"20px"}
                >
                  <VStack align="start" spacing={1}>
                    <Text fontWeight={"bold"}>Autoscaling</Text>
                    <SimpleGrid columns={2} spacing={1}>
                      <VStack align={"start"}>
                        <HStack justify="start" w="100%">
                          <Text minW="30px">Min:</Text>{" "}
                          <Code>
                            {service.service.replicaPolicy.minReplicas}
                          </Code>
                        </HStack>
                        <HStack justify="start" w="100%">
                          <Text minW="30px">Max:</Text>{" "}
                          <Code>
                            {service.service.replicaPolicy.maxReplicas}
                          </Code>
                        </HStack>
                        {/* <HStack justify="start" w="100%">
                          <Text minW="30px">Fallback:</Text>{" "}
                          <Code>{service.dynamicOnDemandFallback}</Code>
                        </HStack> */}
                      </VStack>
                      <VStack align="start">
                        <HStack justify="start" w="100%">
                          <Text minW="121px">Upscale Delay:</Text>{" "}
                          <Code>
                            {service.service.replicaPolicy.upscaleDelaySeconds}{" "}
                            sec
                          </Code>
                        </HStack>
                        <HStack justify="start" w="100%">
                          <Text minW="121px">Downscale Delay:</Text>{" "}
                          <Code>
                            {
                              service.service.replicaPolicy
                                .downscaleDelaySeconds
                            }{" "}
                            sec
                          </Code>
                        </HStack>
                        <HStack justify="start" w="100%">
                          <Text minW="121px">Target QPS/replica:</Text>{" "}
                          <Code>
                            {service.service.replicaPolicy.targetQpsPerReplica}
                          </Code>
                        </HStack>
                      </VStack>
                    </SimpleGrid>
                  </VStack>
                  <VStack align={"start"} spacing={1}>
                    <Text fontWeight={"bold"}>Readiness</Text>
                    <HStack>
                      <Text minWidth={"40px"}>Path: </Text>
                      <Code>{service.service.readinessProbe.path}</Code>
                    </HStack>
                    <HStack>
                      <Text minWidth={"40px"}>Delay: </Text>
                      <Code>
                        {service.service.readinessProbe.initialDelaySeconds} sec
                      </Code>
                    </HStack>
                    <HStack>
                      <Text minWidth={"40px"}>Data: </Text>
                      <Code>
                        {JSON.stringify(
                          service.service.readinessProbe.postData
                        )}
                      </Code>
                    </HStack>
                  </VStack>
                </SimpleGrid>
                <VStack align={"start"} spacing={1} width={"100%"}>
                  <HStack>
                    <Text fontWeight={"bold"}>Replicas</Text>
                    <Badge>{replicas ? replicas?.replicas.length : 0}</Badge>
                  </HStack>
                  <Accordion allowMultiple width="100%">
                    {replicas?.replicas.map((replica) => (
                      <>
                        <AccordionItem
                          id={`${replica.replicaId.toString()}-accordion-item`}
                          width={"100%"}
                          key={`${replica.replicaId.toString()}-accordion-item`}
                        >
                          <AccordionButton
                            key={`${replica.replicaId.toString()}-accordion-button`}
                          >
                            <HStack
                              key={`${replica.replicaId.toString()}-accordion-hstack`}
                            >
                              <Text>{replica.replicaId}</Text>
                              <Badge
                                colorScheme={
                                  replica.status
                                    ? KomodoServiceReplicaStatusColorScheme[
                                        StringToKomodoServiceReplicaStatus[
                                          replica.status
                                        ]
                                      ]
                                    : KomodoServiceReplicaStatusColorScheme[
                                        KomodoServiceReplicaStatus.NOT_READY
                                      ]
                                }
                              >
                                {replica.status
                                  ? KomodoServiceReplicaStatusToString[
                                      StringToKomodoServiceReplicaStatus[
                                        replica.status
                                      ]
                                    ]
                                  : KomodoServiceReplicaStatusToString[
                                      KomodoServiceReplicaStatus.NOT_READY
                                    ]}
                              </Badge>
                            </HStack>
                            <Spacer />
                            <AccordionIcon />
                          </AccordionButton>
                          <AccordionPanel
                            key={`${replica.replicaId.toString()}-accordion-panel`}
                          >
                            <SimpleGrid columns={[1, null, 2]} spacing={4}>
                              <VStack align="start" spacing={1}>
                                <HStack>
                                  <Text minWidth={"75px"}>Version:</Text>
                                  <Code> {replica.version}</Code>
                                </HStack>
                                <HStack>
                                  <Text minWidth={"75px"}>Created:</Text>
                                  <Code>
                                    {" "}
                                    {new Date(
                                      replica.createdTimestamp * 1000
                                    ).toLocaleString()}
                                  </Code>
                                </HStack>
                                <HStack>
                                  <Text minWidth={"75px"}>Updated:</Text>
                                  <Code>
                                    {" "}
                                    {new Date(
                                      replica.updatedTimestamp * 1000
                                    ).toLocaleString()}
                                  </Code>
                                </HStack>
                              </VStack>
                              <VStack align="start" spacing={1}>
                                <HStack justify="start" w="100%">
                                  <Text minW="92px">Cloud:</Text>{" "}
                                  {/* Adjust the minW as needed */}
                                  <Code>
                                    {replica.cloud ? replica.cloud : "n/a"}
                                  </Code>
                                </HStack>
                                <HStack>
                                  <Text minWidth={"90px"}>Instance type:</Text>
                                  <HStack wrap={"wrap"}>
                                    <Code>
                                      {replica.instanceType
                                        ? replica.instanceType
                                        : "n/a"}
                                    </Code>
                                    <Badge>
                                      {replica.instanceType &&
                                        (replica.spot ? "Spot" : "On-Demand")}
                                    </Badge>
                                  </HStack>
                                </HStack>
                                <HStack>
                                  <Text minWidth={"90px"}>Accelerators:</Text>
                                  <Code>
                                    {replica.accelerators
                                      ? replica.accelerators
                                      : "n/a"}
                                  </Code>
                                </HStack>
                                <HStack>
                                  <Text minWidth={"90px"}>Region:</Text>
                                  <Code>
                                    {replica.region ? replica.region : "n/a"}
                                  </Code>
                                </HStack>
                                <HStack>
                                  <Text minWidth={"90px"}>Zone:</Text>
                                  <Code>
                                    {replica.zone ? replica.zone : "n/a"}
                                  </Code>
                                </HStack>
                              </VStack>
                            </SimpleGrid>
                          </AccordionPanel>
                        </AccordionItem>
                      </>
                    ))}
                  </Accordion>
                </VStack>
              </Box>
              <LogBox
                logBoxType="service"
                targetId={service_id!}
                subtargetIds={
                  replicas?.replicas
                    .filter((replica) => replica)
                    .map((replica) => replica.replicaId) || []
                }
              />
            </VStack>
          </Container>
        </>
      ) : null}
    </Page>
  );
};

export default ServiceDetails;
