import { Badge, Skeleton } from "@chakra-ui/react";
import { ColumnDef } from "@saas-ui-pro/react";
import React, { useState, useEffect } from "react";
import { useMachines } from "../providers/MachinesContext"; // Assume you have a similar context for machines
import {
  DisplayMachine,
  KomodoStatusColorScheme,
  KomodoStatusToString,
  Machine,
} from "../types/types";
import DataGridPage from "./DataGridPage";
import { StyledPageWrapper } from "./common";
import { useCognito } from "../providers/CognitoContext";
import AccountNotApprovedBanner from "./AccountNotApprovedBanner";

const displayColumns: ColumnDef<DisplayMachine, any>[] = [
  // {
  //   id: "id",
  //   header: "ID",
  //   accessorKey: "id",
  //   // meta: {
  //   //   href: ({ id }: { id: string }) => `/machines/${id}`,
  //   // },
  //   size: 220,
  // },
  {
    id: "name",
    header: "Name",
    accessorKey: "name",
  },
  {
    id: "status",
    header: "Status",
    accessorKey: "status",
  },
  // { id: "created", header: "Created", accessorKey: "created" },
  { id: "cloud", header: "Cloud", accessorKey: "cloud" },
  { id: "region", header: "Region", accessorKey: "region" },
];

const Machines: React.FC = () => {
  const { machines, fetchMachines, fetchingMachines } = useMachines();
  const { user } = useCognito();
  const [initialLoad, setInitialLoad] = useState(true);
  // const [selections, setSelections] = React.useState<string[]>([]);

  const displayMachines: DisplayMachine[] = machines.map((machine: Machine) => {
    return {
      id: machine.id,
      name: machine.name,
      created: new Date(machine.createdTimestamp * 1000).toLocaleString(),
      status: (
        <Badge colorScheme={KomodoStatusColorScheme[machine.status]}>
          {KomodoStatusToString[machine.status]}
        </Badge>
      ),
      cloud: machine.cloud ? machine.cloud : "n/a",
      region: machine.region ? machine.region : "n/a",
    };
  });

  useEffect(() => {
    if (!fetchingMachines && initialLoad) {
      setInitialLoad(false);
    }
  }, [fetchingMachines]);

  const tableData = React.useMemo(
    () =>
      fetchingMachines || initialLoad ? Array(15).fill({}) : displayMachines,
    [fetchingMachines, initialLoad, displayMachines]
  );
  const tableColumns = React.useMemo(
    () =>
      fetchingMachines || initialLoad
        ? [
            {
              id: "Loading...",
              cell: () => (
                <Skeleton height="20px" width="100%" borderRadius={"md"} />
              ),
            },
          ]
        : displayColumns,
    [fetchingMachines, initialLoad]
  );

  return (
    <StyledPageWrapper>
      <DataGridPage
        title="Machines"
        description="Deploy and manage virtual machines for AI development."
        columns={tableColumns}
        data={tableData}
        fetching={fetchingMachines || initialLoad}
        fetchData={fetchMachines}
        emptyStateTitle="No machines yet"
        emptyStateDescription="You haven't created any machines yet."
        createLink="/machines/new"
        createButtonText="Launch a machine"
        detailsBasePath="machines"
      />
    </StyledPageWrapper>
  );
};

export default Machines;
