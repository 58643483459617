// import axios, { AxiosResponse } from "axios";
import { AxiosResponse } from "axios";
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Job, StringToKomodoStatus } from "../types/types";
import { useCognito } from "./CognitoContext";
import { axios } from "../utils";
import { GetJobLogsResponse } from "../types/types";

type JobsContextType = {
  jobs: Job[];
  fetchJobs: () => void;
  fetchingJobs: boolean;
};

const JobsContext = createContext<JobsContextType | undefined>(undefined);

export const useJobs = () => {
  const context = useContext(JobsContext);
  if (context === undefined) {
    throw new Error("useJobs must be used within a JobsProvider");
  }
  return context;
};

const apiResponseToKomodoJob = (jobData: any): Job => {
  return {
    id: jobData.id,
    status:
      StringToKomodoStatus[jobData.status as keyof typeof StringToKomodoStatus],
    statusMessage: jobData.status_message,
    cloud: jobData.cloud,
    region: jobData.region,
    zone: jobData.zone,
    instanceType: jobData.instance_type,
    accelerators: jobData.accelerators,
    ports: jobData.ports,
    diskSize: jobData.disk_size,
    spot: jobData.spot,
    skyPilotClusterId: jobData.sky_pilot_cluster_id,
    sshInfo: jobData.ssh_info,

    name: jobData.name,
    numNodes: jobData.num_nodes,
    requestedResources: jobData.requested_resources,
    envs: jobData.envs,
    fileMounts: jobData.file_mounts,
    setup: jobData.setup,
    run: jobData.run,

    createdTimestamp: jobData.created_timestamp,
    startedTimestamp: jobData.started_timestamp,
    updatedTimestamp: jobData.updated_timestamp,
    finishedTimestamp: jobData.finished_timestamp,
  };
};

export const JobsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [fetchingJobs, setFetchingJobs] = useState<boolean>(false);
  const { cognitoLoading, user, isFetchingUserFromDb } = useCognito();

  const fetchJobs = async () => {
    if (cognitoLoading || isFetchingUserFromDb) {
      setFetchingJobs(true);
      return;
    }
    if (!user) {
      return;
    }

    setFetchingJobs(true);
    try {
      const params = { fetch_all: true };
      const response = await axios.get(
        `${process.env.REACT_APP_API_CORE_URL}/api/v1/jobs`,
        {
          headers: {
            Authorization: `Bearer ${user?.komo_jwt_token}`,
          },
          params: params,
          id: "get-jobs",
        }
      );
      const responseBody = response.data;
      if (!responseBody) {
        setFetchingJobs(false);
        return;
      }
      const fetchedJobs: Job[] = responseBody.map((jobData: any) => {
        return apiResponseToKomodoJob(jobData);
      });
      if (!response.cached) {
        localStorage.setItem("jobs_fetched_at", new Date().toISOString());
      }
      setJobs(fetchedJobs);
    } catch (error) {
      console.error("Error fetching jobs", error);
    } finally {
      setFetchingJobs(false);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, [user, isFetchingUserFromDb]);

  // this is how you fetch jobs on schedule, but lets avoid loading the server by default rn.
  // useEffect(() => {
  //   // Call fetchJobs immediately upon component mount or when dependencies change
  //   fetchJobs();

  //   // Set up an interval to call fetchJobs every X milliseconds
  //   const intervalId = setInterval(fetchJobs, 5000); // 60000 ms = 1 minute

  //   // Clean up interval on component unmount or when dependencies change
  //   return () => clearInterval(intervalId);
  // }, [user, isFetchingUserFromDb]);

  return (
    <JobsContext.Provider value={{ jobs, fetchJobs, fetchingJobs }}>
      {children}
    </JobsContext.Provider>
  );
};

// function to fetch a single job by id from API
export const GetJobById = async (jobId: string, user: any) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_CORE_URL}/api/v1/jobs/${jobId}`,
      {
        headers: {
          Authorization: `Bearer ${user?.komo_jwt_token}`,
        },
        cache: false,
      }
    );
    const responseBody = response.data;
    const fetchedJob: Job = apiResponseToKomodoJob(responseBody);
    return fetchedJob;
  } catch (error) {
    console.error("Error fetching job", error);
    return null;
  }
};

interface GetJobLogsParams {
  jobId: string;
  nodeIds: number[];
  user: any;
  startTime: number | null;
  endTime: number | null;
  nextTokens: Record<number, string | null>; // key: nodeId, value: nextToken
}

export const GetJobLogs = async ({
  jobId,
  nodeIds,
  user,
  startTime,
  endTime,
  nextTokens,
}: GetJobLogsParams): Promise<GetJobLogsResponse | null> => {
  const params: { [key: string]: any } = {};
  params.node_ids = nodeIds.join(",");
  if (startTime) {
    params.start_time = Math.floor(startTime);
  }
  if (endTime) {
    params.end_time = endTime;
  }
  if (nextTokens) {
    params.next_tokens = JSON.stringify(nextTokens);
  }
  try {
    // TODO: don't throw on non 200
    const response: AxiosResponse = await axios.get(
      `${process.env.REACT_APP_API_CORE_URL}/api/v1/jobs/${jobId}/logs`,
      {
        headers: {
          Authorization: `Bearer ${user?.komo_jwt_token}`,
        },
        params: params,
        validateStatus: (status) => status >= 200 && status < 500, // Only consider 2xx status codes as successful
        cache: false,
      }
    );
    if (response.status !== 200) {
      console.log(`Error fetching job ${jobId} logs`, response);
      return null;
    }
    return response.data;
  } catch (error) {
    console.error("Error fetching job logs", error);
    return null;
  }
};

export const CancelJob = async (jobId: string, user: any) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_CORE_URL}/api/v1/jobs/${jobId}/cancel`,
      {},
      {
        headers: {
          Authorization: `Bearer ${user?.komo_jwt_token}`,
        },
        validateStatus: (status) => status >= 200 && status < 500, // Only consider 2xx status codes as successful
      }
    );
    return response;
  } catch (error) {
    console.error("Error cancelling job", error);
    return null;
  }
};

export const StartJob = async (user: any, payload: any) => {
  console.log("Starting job with payload", payload);
  const response = await axios.post(
    `${process.env.REACT_APP_API_CORE_URL}/api/v1/jobs`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${user?.komo_jwt_token}`,
      },
    }
  );
  return response;
};
