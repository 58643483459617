import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  GetServiceLogsResponse,
  KomodoService,
  KomodoServiceReplicaList,
  StringToKomodoServiceReplicaStatus,
  StringToKomodoServiceStatus,
  ServiceSection,
  CreateKomodoServicePayload,
} from "../types/types";
import { useCognito } from "./CognitoContext";
import { axios } from "../utils";

type ServicesContextType = {
  services: KomodoService[];
  fetchServices: () => void;
  fetchingServices: boolean;
  createService: (serviceConfig: CreateKomodoServicePayload) => Promise<KomodoService | null>;
};

const ServicesContext = createContext<ServicesContextType | undefined>(
  undefined
);

export const useServices = () => {
  const context = useContext(ServicesContext);
  if (!context) {
    throw new Error("useServices must be used within a ServicesProvider");
  }
  return context;
};

const apiResponseToKomodoService = (serviceData: any): KomodoService => {
  return {
    id: serviceData.id,
    status:
      StringToKomodoServiceStatus[
        serviceData.status as keyof typeof StringToKomodoServiceStatus
      ],
    statusMessage: serviceData.status_message,

    name: serviceData.name,
    numNodes: serviceData.num_nodes,
    requestedResources: serviceData.requested_resources,
    envs: serviceData.envs,
    fileMounts: serviceData.file_mounts,
    setup: serviceData.setup,
    run: serviceData.run,
    // service: serviceData.service,
    service: {
      readinessProbe: {
        path: serviceData.service.readiness_probe.path,
        initialDelaySeconds:
          serviceData.service.readiness_probe.initial_delay_seconds,
        postData: serviceData.service.readiness_probe.post_data,
      },
      replicaPolicy: {
        minReplicas: serviceData.service.replica_policy.min_replicas,
        maxReplicas: serviceData.service.replica_policy.max_replicas,
        targetQpsPerReplica:
          serviceData.service.replica_policy.target_qps_per_replica,
        upscaleDelaySeconds:
          serviceData.service.replica_policy.upscale_delay_seconds,
        downscaleDelaySeconds:
          serviceData.service.replica_policy.downscale_delay_seconds,
      },
    },

    uptime: serviceData.uptime,
    // currentVersion: serviceData.current_version,
    activeVersions: serviceData.active_versions,

    createdTimestamp: serviceData.created_timestamp,
    updatedTimestamp: serviceData.updated_timestamp,
    url: serviceData.url,
  };
};

const apiResponseToKomodoReplicas = (
  replicasData: any
): KomodoServiceReplicaList => {
  var replicas: KomodoServiceReplicaList = {
    replicas: replicasData.map((replicaData: any) => {
      return {
        replicaId: replicaData.replica_id,
        serviceId: replicaData.service_id,
        version: replicaData.version,
        status:
          StringToKomodoServiceReplicaStatus[
            replicaData.status as keyof typeof StringToKomodoServiceReplicaStatus
          ],
        cloud: replicaData.cloud,
        region: replicaData.region,
        zone: replicaData.zone,
        instanceType: replicaData.instance_type,
        accelerators: replicaData.accelerators,
        ports: replicaData.ports,
        diskSize: replicaData.disk_size,
        spot: replicaData.spot,
        skyPilotClusterId: replicaData.sky_pilot_cluster_id,
        sshInfo: replicaData.ssh_info,
        createdTimestamp: replicaData.created_timestamp,
        updatedTimestamp: replicaData.updated_timestamp,
      };
    }),
  };
  return replicas;
};

export const ServicesProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [services, setServices] = useState<KomodoService[]>([]);
  const [fetchingServices, setFetchingServices] = useState<boolean>(false);
  const { cognitoLoading, user, isFetchingUserFromDb } = useCognito();

  const fetchServices = async () => {
    if (cognitoLoading || isFetchingUserFromDb) {
      setFetchingServices(true);
      return;
    }
    if (!user) {
      setFetchingServices(false);
      return;
    }

    setFetchingServices(true);
    try {
      const params = { fetch_all: true };
      const response = await axios.get(
        `${process.env.REACT_APP_API_CORE_URL}/api/v1/services`,
        {
          headers: {
            Authorization: `Bearer ${user?.komo_jwt_token}`,
          },
          params,
          id: "get-services",
        }
      );
      const responseBody = response.data;
      if (!responseBody) {
        setFetchingServices(false);
        return;
      }

      const fetchedServices: KomodoService[] = responseBody.map(
        (serviceData: any) => {
          return apiResponseToKomodoService(serviceData);
        }
      );

      if (fetchedServices.length > 0) {
        localStorage.setItem("services", JSON.stringify(fetchedServices));
      }
      if (!response.cached) {
        localStorage.setItem("services_fetched_at", new Date().toISOString());
      }
      setServices(fetchedServices);
      setFetchingServices(false);
    } catch (error) {
      console.log("Error fetching services", error);
      setFetchingServices(false);
      // TODO: Handle error and show error message
    }
  };

  const createService = async (serviceConfig: CreateKomodoServicePayload): Promise<KomodoService | null> => {
    if (!user) {
      console.error("User not authenticated");
      return null;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_CORE_URL}/api/v1/services`,
        serviceConfig,
        {
          headers: {
            Authorization: `Bearer ${user.komo_jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        const newService = apiResponseToKomodoService(response.data);
        setServices(prevServices => [...prevServices, newService]);
        return newService;
      } else {
        console.error("Failed to create service:", response);
        return null;
      }
    } catch (error) {
      console.error("Error creating service:", error);
      return null;
    }
  };

  useEffect(() => {
    fetchServices();
  }, [user, isFetchingUserFromDb]);

  return (
    <ServicesContext.Provider
      value={{ services, fetchServices, fetchingServices, createService }}
    >
      {children}
    </ServicesContext.Provider>
  );
};

export const GetServiceById = async (
  serviceId: string,
  user: any
): Promise<KomodoService | null> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_CORE_URL}/api/v1/services/${serviceId}`,
      {
        headers: {
          Authorization: `Bearer ${user?.komo_jwt_token}`,
        },
        cache: false,
      }
    );
    const responseBody = response.data;
    if (!responseBody) {
      return null;
    }
    const fetchedService: KomodoService =
      apiResponseToKomodoService(responseBody);
    return fetchedService;
  } catch (error) {
    console.log("Error fetching service", error);
    return null;
  }
};

export const TerminateService = async (serviceId: string, user: any) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_CORE_URL}/api/v1/services/${serviceId}`,
      {
        headers: {
          Authorization: `Bearer ${user?.komo_jwt_token}`,
        },
        validateStatus: (status) => status >= 200 && status < 500, // Only consider 2xx status codes as successful
      }
    );
    return response;
  } catch (error) {
    console.log("Error terminating service", error);
    return null;
  }
};

export const GetServiceReplicas = async (
  serviceId: string,
  user: any
): Promise<KomodoServiceReplicaList | null> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_CORE_URL}/api/v1/services/${serviceId}/replicas`,
      {
        headers: {
          Authorization: `Bearer ${user?.komo_jwt_token}`,
        },
        cache: false,
      }
    );
    const responseBody = response.data;
    if (!responseBody) {
      return null;
    }
    const replicas: KomodoServiceReplicaList =
      apiResponseToKomodoReplicas(responseBody);
    return replicas;
  } catch (error) {
    console.log("Error fetching service replicas", error);
    return null;
  }
};

interface GetServiceLogsParams {
  serviceId: string;
  replicaIds: number[];
  startTime: number | null;
  endTime: number | null;
  nextTokens: Record<number, string | null>; // key: replicaId, value: nextToken
  user: any;
}

export const GetServiceLogs = async ({
  serviceId,
  replicaIds,
  startTime,
  endTime,
  nextTokens,
  user,
}: GetServiceLogsParams): Promise<GetServiceLogsResponse | null> => {
  const params: { [key: string]: any } = {};
  params.replica_ids = replicaIds.join(",");
  if (startTime) {
    params.start_time = Math.floor(startTime);
  }
  if (endTime) {
    params.end_time = endTime;
  }
  if (nextTokens) {
    params.next_tokens = JSON.stringify(nextTokens);
  }

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_CORE_URL}/api/v1/services/${serviceId}/logs`,
      {
        headers: {
          Authorization: `Bearer ${user?.komo_jwt_token}`,
        },
        params,
        cache: false,
      }
    );
    if (response.status !== 200) {
      console.log(`Error fetching service ${serviceId} logs: ${response}`);
      return null;
    }
    return response.data;
  } catch (error) {
    console.log("Error fetching service logs", error);
    return null;
  }
};
